import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Modal } from 'react-bootstrap';
import Block from '../../components/Block/Block';
import { Image, Icon, Media, Select } from '../../components';
import { toInitials } from "../../utilities";
import { blockUserApi, searchUserApi, getUserApi, updateUserApi } from "../../services/UserService";
import { deptOptions, roleOptions } from "../../utilities";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../components/Loader/Loading";

function UserEditPage() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [reportsToOptions, setReportsToOptions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [actionType, setActionType] = useState("");

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone_number: "",
        role: null,
        designation: "",
        personnel_number: "",
        extension_number: "",
        department: null,
        reports_to: null,
        file_permission: { create: false, approve: false, delete: false },
    });

    const handleAction = async () => {
        try {
            setIsProcessing(true);
            const newStatus = actionType === "block" ? "blocked" : "active";
            await blockUserApi(id, { status: newStatus });
            toast.success(
                `User has been successfully ${actionType === "block" ? "blocked" : "unblocked"
                }!`
            );
            navigate("/users");
            setShowModal(false);
        } catch (error) {
            console.error("Error updating user status:", error);
            toast.danger(
                `Failed to ${actionType === "block" ? "block" : "unblock"
                } the user.`
            );
        } finally {
            setIsProcessing(false);
        }
    };

    const openModal = (type) => {
        setActionType(type);
        setShowModal(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await searchUserApi();
                const { data } = response.data;
                setReportsToOptions(data);
                setLoading(false)

            } catch (error) {
                console.error("Error fetching data", error);
                setLoading(false)
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchUser = async () => {
            setErrors(null);
            try {
                setLoading(true);
                const response = await getUserApi(id);
                const userData = response?.data?.results;
                const { reports_to, extension_number, name, email, phone_number, role, designation, department, personnel_number, file_permission, profile_photo } = userData;
                const ext_num = extension_number || ""
                setFormData({extension_number:ext_num, reports_to: reports_to?.id || "", profile_photo, name, email, phone_number, role, designation, department, personnel_number, file_permission, id });
            } catch (err) {
                setErrors({"name":err.message || "An error occurred while fetching user data."});
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    }, [id]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    // Handle permission changes
    const handlePermissionChange = (field, action, checked) => {
        setFormData((prev) => ({
            ...prev,
            [field]: { ...prev[field], [action]: checked },
        }));
    };
    // handle Select All Permission Change
    const handleSelectAllPermissionChange = (field, checked) => {
        setFormData((prev) => ({
            ...prev,
            [field]: {
                create: checked,
                approve: checked,
                delete: checked,
            },
        }));
    };

    // Validate form fields
    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = "Name is required";
        if (!formData.email) newErrors.email = "Email is required";
        if (!formData.phone_number) newErrors.phone_number = "Phone Number is required";
        if (!formData.role) newErrors.role = "Role is required";
        if (!formData.department) newErrors.department = "Department is required";
        if (!formData.designation) newErrors.designation = "Designation is required";
        if (!formData.personnel_number) newErrors.personnel_number = "Employment Id is required";
        if (!formData.reports_to) newErrors.reports_to = "Reports To is required";
        if (!formData.extension_number) newErrors.extension_number = "Extension number To is required";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    //handleSubmit
    const handleSubmit = async () => {
        if (validateForm()) {
            try {
                setIsSubmitLoading(true);
                const payload = {
                    ...formData,
                    reports_to: formData.reports_to?.id || null,
                };
                const response = await updateUserApi(id, formData);
                setErrors({});
                toast.success(response?.data?.message);
                navigate(`/user/${id}`);
            } catch (error) {
                toast.error(error?.response?.data?.message || "An error occurred");
            } finally {
                setIsSubmitLoading(false);
            }
        }
    };

    // Handle cancel
    const handleCancel = () => {
        setFormData({
            name: "",
            email: "",
            phone_number: "",
            role: null,
            designation: "",
            personnel_number: "",
            extension_number:"",
            department: null,
            reports_to: null,
            file_permission: { create: false, approve: false, delete: false },
        });
        setErrors({});
        navigate(`/user/${id}`);
    };

    if (loading) return <div><Loading /></div>;
    return (
        <div>
            <Block.Head>
                <Block.HeadBetween className="align-items-start">

                    <Block.HeadContent >
                        <div className="d-flex flex-column flex-md-row align-items-md-center">
                            <Media size="huge" shape="circle" variant="dark-soft">
                                {formData?.profile_photo ? (
                                    <Image
                                        src={`${process.env.REACT_APP_BASE_URL}${formData?.profile_photo}`}
                                        staticImage
                                        alt="user"
                                    />
                                ) : (
                                    <span className="fw-medium">{toInitials(formData?.name)}</span>
                                )}
                            </Media>

                            <div className="mt-1  ms-md-3">
                                <h3 className="title text-uppercase mb-1">{formData?.name}</h3>
                                <h5 className="text-uppercase">{formData?.designation}</h5>
                                <ul className="nk-list-option ">
                                    <li>
                                        <Icon name="building" />:
                                        &nbsp;
                                        <span className="small">
                                            {deptOptions.find((option) => option.value === formData?.department)?.label || "NA"}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Block.HeadContent>

                    <Block.HeadContent>
                        <div className="d-flex gap g-3">
                            <div className="gap-col">
                                <ul className="d-flex gap g-2">
                                    <li className="d-none d-md-block">
                                        <Link to={`/user/${id}`}
                                            className="btn btn-soft btn-primary">
                                            <Icon name="user"></Icon>
                                            <span>View Profile</span>
                                        </Link>
                                    </li>
                                    <li className="d-md-none">
                                        <Link to={`/user/${id}`} className="btn btn-soft btn-primary btn-icon">
                                            <Icon name="user"></Icon>
                                        </Link>
                                    </li>


                                </ul>
                            </div>
                        </div>
                    </Block.HeadContent>
                </Block.HeadBetween>
            </Block.Head>
            <Block>
                <Form action="#" onSubmit={handleSubmit}>
                    <Row className="g-gs">
                        <Col xxl="12">
                            <div className="gap gy-4">
                                <div className="gap-col">
                                    <Card className="card-gutter-md">
                                        <Card.Body>
                                            <div
                                                className="d-flex align-items-center mb-4"
                                                style={{ justifyContent: "space-between" }}
                                            >
                                                <h4 className="bio-block-title">Edit Profile</h4>
                                                {formData.status !== "blocked" ? (
                                                    <Button
                                                        className=" text-bg-danger"
                                                        style={{
                                                            color: "white",
                                                            border: "none",
                                                            fontWeight: "bold",
                                                        }}

                                                        onClick={() => openModal("block")}
                                                    >
                                                        Block User
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        className=" text-bg-success"
                                                        style={{
                                                            color: "white",
                                                            border: "none",
                                                            fontWeight: "bold",
                                                        }}

                                                        onClick={() => openModal("unblock")}
                                                    >
                                                        Unblock User
                                                    </Button>
                                                )}
                                            </div>

                                            <Row className="g-gs">

                                                {/* Name */}
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Name <span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="name"
                                                            placeholder="Enter Name"
                                                            value={formData.name}
                                                            onChange={handleChange}
                                                        />
                                                        {errors?.name && <div className="text-danger">{errors?.name}</div>}
                                                    </Form.Group>
                                                </Col>

                                                {/*Email */}
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Email <span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            name="email"
                                                            placeholder="Enter email"
                                                            value={formData.email}
                                                            onChange={handleChange}
                                                        />
                                                        {errors?.email && <div className="text-danger">{errors?.email}</div>}
                                                    </Form.Group>
                                                </Col>
                                                {/* Phone Number */}
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Phone Number<span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="phone_number"
                                                            placeholder="Enter Phone Number"
                                                            value={formData.phone_number}
                                                            onChange={handleChange}
                                                        />
                                                        {errors?.email && <div className="text-danger">{errors?.email}</div>}
                                                    </Form.Group>
                                                </Col>

                                                {/* Extension Number */}
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Extension Number{" "}
                                                            <span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            name="extension_number"
                                                            placeholder="Enter Extension Number"
                                                            value={formData.extension_number}
                                                            onChange={handleChange}
                                                            //minLength={5}
                                                        />
                                                        {errors?.extension_number && (
                                                            <div className="text-danger">
                                                                {errors?.extension_number}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                </Col>

                                                {/* Role */}
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Role<span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Select
                                                            id="role"
                                                            name="role"
                                                            value={formData.role}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="">
                                                                Choose Role
                                                            </option>
                                                            {roleOptions?.map((option) => (
                                                                <option
                                                                    key={option.value}
                                                                    value={option.value}
                                                                >
                                                                    {option.label}
                                                                </option>
                                                            ))}
                                                        </Select>
                                                        {errors?.role && <div className="text-danger">{errors?.role}</div>}
                                                    </Form.Group>
                                                </Col>

                                                {/* Designation */}
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Designation <span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="designation"
                                                            placeholder="Enter Designation"
                                                            value={formData.designation}
                                                            onChange={handleChange}
                                                        />
                                                        {errors?.designation && <div className="text-danger">{errors?.designation}</div>}
                                                    </Form.Group>
                                                </Col>

                                                {/* Personnel Number */}
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Personnel Number <span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="personnel_number"
                                                            placeholder="Enter Personnel Number"
                                                            value={formData.personnel_number}
                                                            onChange={handleChange}
                                                        />
                                                        {errors?.personnel_number && <div className="text-danger">{errors?.personnel_number}</div>}
                                                    </Form.Group>
                                                </Col>

                                                {/* Department */}
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Department <span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Select
                                                            id="department"
                                                            name="department"
                                                            value={formData.department}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="">
                                                                Choose Department
                                                            </option>
                                                            {deptOptions?.map((option) => (
                                                                <option
                                                                    key={option.value}
                                                                    value={option.value}
                                                                >
                                                                    {option.label}
                                                                </option>
                                                            ))}
                                                        </Select>
                                                        {errors?.department && <div className="text-danger">{errors?.department}</div>}
                                                    </Form.Group>
                                                </Col>

                                                {/* Reports To*/}
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Reports To <span className="text-danger">*</span>
                                                        </Form.Label>
                                                        {loading ? (
                                                            <div>Loading...</div>
                                                        ) : (
                                                            <select
                                                                id="reports_to"
                                                                name="reports_to"
                                                                value={formData.reports_to}
                                                                onChange={handleChange}
                                                                className="form-select"
                                                            >
                                                                <option value="">Choose Reports To</option>
                                                                {reportsToOptions?.map((option) => (
                                                                    <option key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        )}
                                                        {errors?.reports_to && <div className="text-danger">{errors?.reports_to}</div>}
                                                    </Form.Group>
                                                </Col>


                                                {/* Permissions Table */}
                                                {(formData.role !== "daak" && formData.role !== "admin") && (
                                                    <Form.Group>
                                                        <Form.Label>Permissions</Form.Label>
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>Permission</th>
                                                                    <th>Create</th>
                                                                    <th>Approve</th>
                                                                    <th>Delete</th>
                                                                    <th>Select All</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>File Permission</td>
                                                                    {["create", "approve", "delete"].map((action) => (
                                                                        <td key={action}>
                                                                            <div className="form-check custom-checkbox">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="form-check-input"
                                                                                    value={action}
                                                                                    checked={formData.file_permission[action]}
                                                                                    onChange={(e) => handlePermissionChange("file_permission", action, e.target.checked)}
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                    ))}
                                                                    <td>
                                                                        <div className="form-check custom-checkbox">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                checked={Object.values(formData["file_permission"]).every((val) => val)}
                                                                                onChange={(e) => handleSelectAllPermissionChange("file_permission", e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Form.Group>
                                                )}

                                                {/* Submit and Cancel Buttons */}
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <Button variant="primary" onClick={handleSubmit} disabled={isSubmitLoading}>
                                                        {isSubmitLoading ? "Updatting..." : "Update"}
                                                    </Button>
                                                    <Button variant="secondary" onClick={handleCancel} className="ms-3">
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </div>

                                {/* Confirmation Modal */}
                                <Modal show={showModal} onHide={() => setShowModal(false)}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            {actionType === "block" ? "Block User" : "Unblock User"}
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        Are you sure you want to{" "}
                                        {actionType === "block" ? "block" : "unblock"} this user?
                                        This action cannot be undone.
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button
                                            variant="secondary"
                                            onClick={() => setShowModal(false)}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant={actionType === "block" ? "danger" : "success"}
                                            onClick={handleAction}
                                            disabled={isProcessing}
                                        >
                                            {isProcessing
                                                ? actionType === "block"
                                                    ? "Blocking..."
                                                    : "Unblocking..."
                                                : `Yes, ${actionType === "block" ? "Block" : "Unblock"}`}
                                        </Button>
                                    </Modal.Footer>
                                </Modal>


                            </div>
                        </Col>

                    </Row>
                </Form>
            </Block>
        </div>
    )
}

export default UserEditPage;