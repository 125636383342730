import classNames from 'classnames';
import { Link } from 'react-router-dom';

function Media({
  className,
  size,
  shape,
  border,
  sharp,
  to,
  variant = "primary",
  ...props
}) {

  const compClass = classNames({
    'media media-middle': true,
    [`media-${size}`]: size,
    [`media-${shape}`]: shape,
    [`media-border`]: border,
    [`media-sharp`]: sharp,
    [`text-bg-${variant}`]: variant,
    [className]: className,
  });

  const shapeStyle = shape === 'circle' ? { borderRadius: '50%' } : {};



  const isCustomColor = /^#|rgb|hsl/.test(variant); 
  const combinedStyles = {
    ...shapeStyle,
    overflow: 'hidden',
    backgroundColor: isCustomColor ? variant : undefined, 
  };

  return (
    <>
      {to ? (
        <Link className={compClass} style={combinedStyles} to={to}>
          {props.children}
        </Link>
      ) : (
        <div className={compClass} style={combinedStyles}>
          {props.children}
        </div>
      )}
    </>
  );
}

export default Media;
