import React, { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import { Card, Spinner } from "react-bootstrap";
import { getExternalvsInternal } from "../../../services/DashboardService";
import FilterDropdown from "../Filter";

const ExternalVsInternalChart = () => {
  const [chartData, setChartData] = useState(null); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); 
  const [filter, setFilter] = useState(""); 

  useEffect(() => {
    async function fetchData(filterOption) {
      try {
        const response = await getExternalvsInternal(filterOption);
        if (response.data.success) {
          const { external_files_percentage, internal_files_percentage } = response.data.data;

          setChartData({
            labels: ["External Files", "Internal Files"],
            datasets: [
              {
                data: [external_files_percentage, internal_files_percentage],
                backgroundColor: ["#1E90FF", "#2E8B57"],
                hoverBackgroundColor: ["#1C86EE", "#2E8B57"],
                borderWidth: 2,
                borderColor: "#fff",
                cutout: "0%",
              },
            ],
          });
        } else {
          throw new Error("Failed to fetch data.");
        }
      } catch (error) {
        console.error("Error fetching file distribution status:", error);
        setError("Failed to load chart data.");
      } finally {
        setLoading(false);
      }
    }

    fetchData(filter);
  }, [filter]);

  const handleFilterChange = (selectedFilter) => {
    setFilter(selectedFilter);
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            const value = tooltipItem.raw;
            return `${value}%`; 
          },
        },
      },
    },
  };

  return (
    <Card className="h-100 shadow-sm">
      <Card.Body>
      <div className="d-flex justify-content-between align-items-center mb-3">
          <h4>
          External vs Internal
          </h4>
          <FilterDropdown filter={filter} onFilterChange={handleFilterChange} />
        </div> 
        {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
            <Spinner animation="border" variant="primary" />
          </div>
        ) : error ? (
          <div className="text-danger text-center">{error}</div>
        ) : chartData ? (
          <Pie data={chartData} options={options} />
        ) : (
          <div className="text-center">No data available</div>
        )}
      </Card.Body>
    </Card>
  );
};

export default ExternalVsInternalChart;
