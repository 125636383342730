import { connect } from "react-redux";
import FileStatusDistribution from "./Management/FileStatusDistribution";
import AverageFileClearanceTime from "./Management/AverageFileClearanceTime";
import FileDelayedTime from "./Management/FileDelayedTime";
import EscalationTrendsChart from "./Management/EscalationTrends";
import KPIData from "./Management/KPIData";
import { Row, Col } from "react-bootstrap";
import ReceiveFileCount from "./DaakUser/ReceiveFileCount";
import DispatchFileCount from "./DaakUser/DispatchFileCount";
import ReceiveFileList from "./DaakUser/ReceiveFileList";
import DispatchFileList from "./DaakUser/DispatchFileList";
import FileStatus from "./User/FileStatus";
import WeeklyFilesLineChart from "./User/WeeklyCompletedFile";
import FileActivityChart from "./Admin/FileActivity";
import FileCategoryChart from "./Admin/FileCatagoryOverTime";
import MonthlyOverdueFilesChart from "./Admin/MonthlyOverdueFile";
import ExternalVsInternalChart from "./Admin/ExternalvsInternal";
import TopEscalatingDepartmentsChart from "./Admin/TopEscalatingDepartment";
import TopDelayedFiles from "./Admin/TopDelayedFile";
import FileVolumeTrendsChart from "./Management/FileVolumeTrends";
import FileToCollect from "./User/FileToCollect";
import FileToOut from "./User/FileToOut";

function Home({ userRole }) {
  return (
    <div className="container">
      {userRole === "management" ? (
        <Row className="g-gs">
           <Col xxl="6" md="6" sm="12">
            <FileToCollect />
          </Col>
          <Col xxl="6" md="6" sm="12">
            <FileToOut />
          </Col>
          <Col xxl="4" md="6">
            <FileStatusDistribution />
          </Col>

          <Col xxl="5" md="6">
            <AverageFileClearanceTime />
          </Col>
          <Col xxl="3" md="12">
            <KPIData />
          </Col>
          <Col xxl="6">
            <FileDelayedTime />
          </Col>
          <Col xxl="6">
            <EscalationTrendsChart />
          </Col>
          <Col xxl="6">
            <FileVolumeTrendsChart />
          </Col>
        </Row>
      ) : userRole === "daak" ? (
        <Row className="g-gs">
          <Col xxl="6">
            <ReceiveFileCount />
          </Col>
          <Col xxl="6">
            <DispatchFileCount />
          </Col>
          <Col xxl="6">
            <ReceiveFileList />
          </Col>
          <Col xxl="6">
            <DispatchFileList />
          </Col>
        </Row>
      ) : userRole === "user" ? (
        <Row className="g-gs">
          <Col xxl="6" md="6" sm="12">
            <FileToCollect />
          </Col>
          <Col xxl="6" md="6" sm="12">
            <FileToOut />
          </Col>
          <Col xxl="6">
            <FileStatus />
          </Col>

          <Col xxl="6">
            <WeeklyFilesLineChart />
          </Col> 
        </Row>
      ) : (
        <Row className="g-gs">
          <Col xxl="4" md="6">
            <ExternalVsInternalChart />
          </Col>
          <Col xxl="8" md="6">
            <FileCategoryChart />
          </Col>
          <Col xxl="6">
            <MonthlyOverdueFilesChart />
          </Col>

          <Col xxl="6">
            <TopEscalatingDepartmentsChart />
          </Col>
          {/* <Col xxl="6">
            <DepartmentChart />
          </Col>
          <Col xxl="6">
            <ResourceChart />
          </Col> */}
          <Col xxl="5">
            <FileActivityChart />
          </Col>
          <Col xxl="7">
            <TopDelayedFiles />
          </Col>
        </Row>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  userRole: state.auth.user?.role || "user",
});
export default connect(mapStateToProps)(Home);
