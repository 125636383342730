import React, { useState, useEffect, useCallback } from "react";
import { Card, Table } from "react-bootstrap";
import {
  Media,
  MediaGroup,
  MediaText,
  OverlineTitle,
  Icon,
} from "../../../components";
import { connect, useDispatch } from "react-redux";
import { getReceivefileList } from "../../../services/DashboardService";
import {
  toggleReceiveModalAction,
  handleSetBarcodeNumberAction,
  handleSetReceiveListAction,
} from "../../../store/actions/FileActions";
import Barcode from "react-barcode";

const ReceiveFileList = ({ data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getReceivefileList()
      .then((resp) => {
        const { data, total } = resp.data;
        dispatch(handleSetReceiveListAction({ data, total }));
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleOpenReceiveModal = (barcode) => {
    dispatch(toggleReceiveModalAction());
    dispatch(handleSetBarcodeNumberAction(barcode));
  };

  return (
    <>
      <Card className="h-100">
        <Card.Body className="py-2 flex-grow-0">
          <div className="card-title-group">
            <div className="card-title">
              <h5 className="title">Receive File List</h5>
            </div>
          </div>
        </Card.Body>
        <Table responsive className="table-middle mb-0">
          <thead className="table-light table-head-sm">
            <tr>
              <th className="tb-col">
                <OverlineTitle tag="span">Files</OverlineTitle>
              </th>
              <th className="tb-col tb-col-end tb-col-sm">
                <OverlineTitle tag="span">Barcode</OverlineTitle>
              </th>
              <th className="tb-col tb-col-end">
                <OverlineTitle tag="span">Receive From</OverlineTitle>
              </th>
              <th className="tb-col tb-col-end">
                <OverlineTitle tag="span">Created Date</OverlineTitle>
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="4" className="text-center py-4">
                  <div>Loading...</div>
                </td>
              </tr>
            ) : data.length === 0 ? (
              <tr>
                <td colSpan="4" className="text-center py-4">
                  <div className="text-muted">
                    <Icon name="inbox" size="lg" className="mb-2" />
                    <p className="mb-0">No Receive files available</p>
                  </div>
                </td>
              </tr>
            ) : (
              data.map((item, index) => (
                <tr key={index}>
                  <td className="tb-col">
                    <MediaGroup>
                      <MediaText className="fw-bold truncate-text">
                        {item?.name}
                        <span className="text smaller">
                          {item?.file_number}
                        </span>
                      </MediaText>
                    </MediaGroup>
                  </td>
                  <td className="tb-col tb-col-end tb-col-sm">
                    <div
                      className="barcode cursor-pointer"
                      onClick={() =>
                        handleOpenReceiveModal(item?.barcode_number)
                      }
                    >
                      <Barcode
                        value={item?.barcode_number || "000000"}
                        height={20}
                        width={1.2}
                      />
                    </div>
                  </td>
                  <td className="tb-col tb-col-end">
                    <div className="title fs-6">
                      <strong>{item?.receive_from?.name}</strong>{" "}
                    </div>
                    <span className="fs-6 text-mute">
                      <Icon name="building" className="me-2" />
                      {item?.receive_from?.department}
                    </span>
                  </td>
                  <td className="tb-col tb-col-end">
                    <span className="small">{item?.created_at}</span>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.file.receiveFileList || [],
  total: state.file.receiveTotal || 0,
});
export default connect(mapStateToProps)(ReceiveFileList);
