import React from 'react'
import { Routes, Route, Navigate } from "react-router-dom"

import { ScrollToTop } from '../components'
import { connect } from 'react-redux'
import DefaultLayout from '../layout/default'
import BlankLayout from "../layout/fullpage"

// Pages
import Home from '../pages/Dashboard/Home';

// Auth pages
import AuthRegister from '../pages/auths/AuthRegister';
import AuthLogin from '../pages/auths/AuthLogin';
import AuthReset from '../pages/auths/AuthReset';

// Error pages
import NotFound from '../pages/error/NotFound';
import PermissionDenied from '../pages/error/PermissionDenied'

// files pages
import FilePage from '../pages/File/File';
import AddFilePage from '../pages/File/AddFile';
import FileDetails from '../pages/File/FileDetails';

// User Manage pages
import UserListPage from '../pages/user-manage/UserList'
import UserAddPage from '../pages/user-manage/AddUser'
import UserEditPage from '../pages/user-manage/UserEdit'
import UserProfilePage from '../pages/user-manage/UserProfile'


// profile PAges
import ProfileSettingsPage from '../pages/Profile/ProfileSettings'

import EditFilePage from '../pages/File/EditFile'
import Loading from '../components/Loader/Loading'
import FileAction from '../pages/FileAction/FileAction'
import FileOverview from '../pages/FileOverview/FileOverview'


const FlashScreen = () => {
  return (
    <BlankLayout title="Loading..." mask="2" centered>
      <Loading />
    </BlankLayout>
  );
};

function Router({ isAuthenticated, userRole, isCheckingAuth }) {
  const allRoutes = [
    // Public Routes
    {
      url: "/",
      element: <AuthLogin />,
      isProtected: false,
      routeTitle: "Login",
      accessRole: []
    },
    {
      url: "/register",
      element: <AuthRegister />,
      isProtected: false,
      routeTitle: "Register",
      accessRole: []
    },
    {
      url: "/reset-password",
      element: <AuthReset />,
      isProtected: false,
      routeTitle: "Forgot Password",
      accessRole: []
    },

    // Protected Routes
    {
      url: "/dashboard",
      element: <Home />,
      isProtected: true,
      routeTitle: "Dashboard",
      accessRole: ["admin", "management", "user", "daak"]
    },
    {
      url: "/files",
      element: <FilePage />,
      isProtected: true,
      routeTitle: "Files",
      accessRole: ["admin", "management", "user"]
    },
    {
      url: "/add-file",
      element: <AddFilePage />,
      isProtected: true,
      routeTitle: "Add File",
      accessRole: ["management", "user"]
    },
    {
      url: "/edit-file/:id",
      element: <EditFilePage />,
      isProtected: true,
      routeTitle: "Add File",
      accessRole: ["management", "user", "admin"]
    },
    {
      url: "/file/:id",
      element: <FileDetails />,
      isProtected: true,
      routeTitle: "File Details",
      accessRole: ["admin", "management", "user"]
    },
    {
      url: "/users",
      element: <UserListPage />,
      isProtected: true,
      routeTitle: "Users",
      accessRole: ["admin", "management", "user"]
    },
    {
      url: "/add-user",
      element: <UserAddPage />,
      isProtected: true,
      routeTitle: "Add user",
      accessRole: ["admin"]
    },
    {
      url: "/edit-user/:id",
      element: <UserEditPage />,
      isProtected: true,
      routeTitle: "Edit user",
      accessRole: ["admin"]
    },
    {
      url: "/user/:id",
      element: <UserProfilePage />,
      isProtected: true,
      routeTitle: "User Profile",
      accessRole: ["admin", "user", "management"]
    },

    {
      url: "/profile",
      element: <ProfileSettingsPage />,
      isProtected: true,
      routeTitle: "Profile",
      accessRole: ["admin", "management", "user", "daak"]
    },
    {
      url: "/account-settings",
      element: <ProfileSettingsPage />,
      isProtected: true,
      routeTitle: "Account Settings",
      accessRole: ["admin", "management", "user", "daak"]
    },
    {
      url: "/file-action",
      element: <FileAction />,
      isProtected: true,
      routeTitle: "File Action",
      accessRole: ["management", "user"]
    },{
      url: "/file-overview",
      element: <FileOverview />,
      isProtected: true,
      routeTitle: "File Overview",
      accessRole: ["admin"]
    },
  ];

  const handleProtectedRoute = (route) => {
    if (!isAuthenticated) {
      return <Navigate to="/" />;
    }
    if (!route.accessRole.includes(userRole)) {
      return <PermissionDenied />;
    }
    return route.element;
  };

  const handlePublicRoute = (route) => {
    if (isAuthenticated) {
      // Redirect authenticated users trying to access public routes
      return <Navigate to="/dashboard" />;
    }
    return route.element;
  };

  return isCheckingAuth ? (
    <FlashScreen />
  ) : (
    <ScrollToTop>
      <Routes>
        {allRoutes.map((item) => (
          <Route
            key={item.url}
            path={item.url}
            element={
              item.isProtected ? (
                <DefaultLayout title={item.routeTitle}>
                  {handleProtectedRoute(item)}
                </DefaultLayout>
              ) : (
                <BlankLayout title={item.routeTitle} centered>
                  {handlePublicRoute(item)}
                </BlankLayout>
              )
            }
          />
        ))}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ScrollToTop>
  );
}

const mapStateToProps = (state) => ({
  userRole: state.auth.user?.role || "user",
  isAuthenticated: state.auth.user !== null,
  isCheckingAuth: state.auth.isCheckingAuth,
});

export default connect(mapStateToProps)(Router);
