import {
  HANDLE_UPDATE_NEW_FILE_LOG,
  GET_FILE_LOG_LIST,
  TOGGLE_RECEIVE_MODAL,
  TOGGLE_DISPATCH_MODAL,
  HANDLE_SET_BARCODE,
  HANDLE_SET_DISPATCH_BARCODE,
  HANDLE_SET_RECEIVE_LIST,
  HANDLE_COMPLETE_RECEIVE_FILE,
  HANDLE_ADD_NEW_RECEIVE_FILE,
  HANDLE_SET_DISPATCH_LIST,
  HANDLE_COMPLETE_DISPATCH_FILE,
  HANDLE_RECEIVE_COUNT,
  HANDLE_DISPATCH_COUNT,
  CLEAR_FILE_LOG_LIST,
} from "../actions/FileActions";

const initialState = {
  fileLogs: [],
  totals: 0,
  //daak related file
  showReceiveModal: false,
  showDispatchModal: false,
  receiveFileList: [],
  receiveTotal: 0,
  receiveCount: 0,
  dispatchCount: 0,
  dispatchFileList: [],
  dispatchTotal: 0,
  barcodeNumber: "",
  barcode_number: "",
};

function FileReducer(state = initialState, actions) {
  if (actions.type === HANDLE_UPDATE_NEW_FILE_LOG) {
    return {
      ...state,
      fileLogs: [actions.payload, ...state.fileLogs],
    };
  } else if (actions.type === GET_FILE_LOG_LIST) {
    if (actions.payload) {
      return {
        ...state,
        fileLogs: [...state.fileLogs, ...actions.payload.data],
        totals: actions.payload.total,
      };
    } else {
      return { ...state };
    }
  } else if (actions.type === CLEAR_FILE_LOG_LIST) {
    return {
      ...state,
      fileLogs: [],
      totals: 0,
    };
  } else if (actions.type === TOGGLE_RECEIVE_MODAL) {
    return { ...state, showReceiveModal: !state.showReceiveModal };
  } else if (actions.type === TOGGLE_DISPATCH_MODAL) {
    return { ...state, showDispatchModal: !state.showDispatchModal };
  } else if (actions.type === HANDLE_SET_BARCODE) {
    return { ...state, barcodeNumber: actions.payload };
  } else if (actions.type === HANDLE_SET_DISPATCH_BARCODE) {
    return { ...state, barcode_number: actions.payload };
  } else if (actions.type === HANDLE_SET_RECEIVE_LIST) {
    if (actions.payload) {
      return {
        ...state,
        receiveFileList: actions.payload.data,
        receiveTotal: actions.payload.total,
      };
    } else {
      return { ...state };
    }
  } else if (actions.type === HANDLE_COMPLETE_RECEIVE_FILE) {
    const file = { ...actions.payload };
    const newReceiveFiles = state.receiveFileList.filter(
      (item) => item.id !== file?.id
    );
    const receiveCount = state.receiveCount - 1;
    const dispatchCount = state.dispatchCount + 1;
    return {
      ...state,
      receiveFileList: [...newReceiveFiles],
      dispatchFileList: [file, ...state.dispatchFileList],
      receiveCount,
      dispatchCount,
    };
  } else if (actions.type === HANDLE_RECEIVE_COUNT) {
    if (actions.payload) {
      return {
        ...state,
        receiveCount: actions.payload.receiveCount,
      };
    } else {
      return { ...state };
    }
  } else if (actions.type === HANDLE_ADD_NEW_RECEIVE_FILE) {
    const notficationCount = state.notficationCount + 1;
    return {
      ...state,
      notficationList: [actions.payload, ...state.notficationList],
      notficationCount,
    };
  } else if (actions.type === HANDLE_SET_DISPATCH_LIST) {
    if (actions.payload) {
      return {
        ...state,
        dispatchFileList: actions.payload.data,
        dispatchTotal: actions.payload.total,
      };
    } else {
      return { ...state };
    }
  } else if (actions.type === HANDLE_DISPATCH_COUNT) {
    if (actions.payload) {
      return {
        ...state,
        dispatchCount: actions.payload.dispatchCount,
      };
    } else {
      return { ...state };
    }
  } else if (actions.type === HANDLE_COMPLETE_DISPATCH_FILE) {
    const file = { ...actions.payload };
    const newDispatchFiles = state.dispatchFileList.filter(
      (item) => item.id !== file?.id
    );
    const dispatchCount = state.dispatchCount - 1;
    return {
      ...state,
      dispatchFileList: [...newDispatchFiles],
      dispatchCount,
    };
  }

  return state;
}

export default FileReducer;
