import axiosInstance from './AxiosInstance';

export function getFileToCollect() {
    return axiosInstance.get("api/management_user_file_to_collect");
}

export function getFileToOut() {
    return axiosInstance.get("api/management_user_file_to_out");
}

export function getFileHistory(page, page_size) {
    return axiosInstance.get(`api/file_history?page=${page}&page_size=${page_size}`);
}