import axiosInstance from "./AxiosInstance";

//Management User Dashboard
export function getFileDistributionStatus(time_period) {
  return axiosInstance.get(`api/file_distribution_status?time_period=${time_period}`);
}
export function getAverageFileClearanceTime(time_period) {
  return axiosInstance.get(`api/average_clearance_time?time_period=${time_period}`);
}

export function getDelayedFilesByDept() {
  return axiosInstance.get("api/delayed_files_by_dept");
}
export function getFileVolumeTrends() {
  return axiosInstance.get("api/file_volume_trends");
}
export function getPendingApprovalFileCount() {
  return axiosInstance.get("api/pending_approval_files_count");
}
export function getEscalationTrends() {
  return axiosInstance.get("api/escalation_trends");
}

//Daak User Dashboard
export function getReceivefileList() {
  return axiosInstance.get("api/file_to_receive");
}

export function getDispatchfileList() {
  return axiosInstance.get("api/file_to_dispatch");
}

export function getReceiveDispatchCount() {
  return axiosInstance.get("api/receive_dispatch_count");
}

//User Dashboard
export function getUserAlertFileStatus(time_period) {
  return axiosInstance.get(`api/user_file_status?time_period=${time_period}`);
}

export function getWeeklyCompletedFilesCount() {
  return axiosInstance.get(`api/weekly_completed_files_count`);
}

export function getFileCollectOutCount() {
  return axiosInstance.get("api/collect_out_count");
}

//admin Dashboard
export function getFileCategoryOverTime() {
  return axiosInstance.get("api/file_categories_over_time");
}
export function getFileActivityDistribution(time_period) {
  return axiosInstance.get(`api/activity_distribution?time_period=${time_period}`);
}

//common Dashboard
export function getExternalvsInternal(time_period) {
  return axiosInstance.get(`api/external_vs_internal?time_period=${time_period}`);
}

export function getTopFiveDelayedFiles() {
  return axiosInstance.get("api/top_five_delayed_files");
}

export function getMonthlyOverdueFiles() {
  return axiosInstance.get("api/monthly_overdue_files");
}

export function getTopEscalatingDepartment(time_period) {
  return axiosInstance.get(`api/top_escalating_dept?time_period=${time_period}`);
}