import { useEffect, useRef } from 'react';
import { DateRangePicker } from 'vanillajs-datepicker';

// Utility function to reformat date
const formatDateToISO = (dateStr) => {
  return dateStr.replace(/\//g, '-');
};

function DatePicker({
  placeholderStart = 'yyyy/mm/dd',
  placeholderEnd = 'yyyy/mm/dd',
  onChange,
  className = '',
  ...options
}) {
  const containerRef = useRef(null);

  useEffect(() => {
    // Check if the containerRef exists before initializing
    if (!containerRef.current) return;

    // Initialize DateRangePicker
    const dateRangePicker = new DateRangePicker(containerRef.current, {
      autohide: options.autoHide ?? true,
      clearBtn: options.clearBtn ?? false,
      todayBtn: options.todayBtn ?? false,
      format: options.format || 'yyyy/mm/dd',
      maxView: options.maxView || 3,
      pickLevel: options.pickLevel || 0,
      startView: options.startView || 0,
      weekStart: options.weekStart || 0,
      buttonClass: 'btn btn-md',
    });

    // DateRangePicker emits 'changeDate' event
    const handleDateChange = () => {
      const inputs = containerRef.current.querySelectorAll('input');
      const startDate = inputs[0]?.value || '';
      const endDate = inputs[1]?.value || '';

      // Reformat dates before passing to onChange
      const formattedStartDate = formatDateToISO(startDate);
      const formattedEndDate = formatDateToISO(endDate);

      if (onChange) onChange({ startDate: formattedStartDate, endDate: formattedEndDate });
    };

    // Listen for 'changeDate' event
    containerRef.current.addEventListener('changeDate', handleDateChange);

    // Cleanup
    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('changeDate', handleDateChange);
      }
      dateRangePicker.destroy();
    };
  }, [options, onChange]);

  return (
    <div className="form-group">
      <div className={`input-group ${className}`} ref={containerRef}>
        <input
          type="text"
          name="start"
          placeholder={placeholderStart}
          className="form-control"
          autoComplete="off"
        />
        <span className="input-group-text">to</span>
        <input
          type="text"
          name="end"
          placeholder={placeholderEnd}
          className="form-control"
          autoComplete="off"
        />
      </div>
    </div>
  );
}

export default DatePicker;
