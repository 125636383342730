import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Card } from "react-bootstrap";
import { getWeeklyCompletedFilesCount } from "../../../services/DashboardService";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const WeeklyFilesLineChart = () => {
  const [chartData, setChartData] = useState(null);

  // Fetch API Data
  const fetchData = async () => {
    try {
      const response = await getWeeklyCompletedFilesCount()
      const dailyCounts = response.data.data.daily_counts;

      const labels = Object.keys(dailyCounts); // Days of the week
      const data = Object.values(dailyCounts); // Counts

      setChartData({
        labels,
        datasets: [
          {
            label: "Files Processed",
            data: data,
            borderColor: "green",
            backgroundColor: "green",
            tension: 0.4, 
            pointBackgroundColor: "green",
            pointRadius: 5,
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      title: {
        display: true,
        text: "",
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: "Number of Files Processed",
        },
        beginAtZero: true,
      },
      x: {
        title: {
          display: true,
          text: "Days of the Week",
        },
      },
    },
  };

  return (
    <Card className="h-100 shadow-sm">
      <Card.Body>
        <div>
          <h4>
           Weekly Completed File Count
          </h4>
        </div>
          {chartData ? (
            <Line data={chartData} options={options} />
          ) : (
            "Loading..."
          )}
      </Card.Body>
    </Card>
  );
};

export default WeeklyFilesLineChart;
