import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { CancelRequest } from "../../services/AxiosInstance";
import { formatUnderscoreString, getDepartmentLabel } from "../../utilities";
import SimpleBar from "simplebar-react";
import { Alert } from "react-bootstrap";
import { Icon, Schedule } from "../../components";
import { getFileLogApi } from "../../services/FileService";
import DatePicker from "../../components/Form/DateRangePicker";
import { Spinner } from "react-bootstrap";
import {
  handleClearFileLogList,
  handleGetFileLogListAction,
} from "../../store/actions/FileActions";
import { useNavigate } from "react-router-dom";
import DebounceSearchInput from "../Constant/DebounceSearchInput";

const FileLogList = ({ fileLog, user }) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const containerRef = useRef(null);
  const [filter, setFilter] = useState({
    query: "",
    startDate: "",
    endDate: "",
  });
  const dispatch = useDispatch();
  const recordsPerPage = 5;

  const fetchFileLog = useCallback(() => {
    console.log("ssss start", hasMore, fileLog.length);
    if (currentPage > 1 && !hasMore) return;
    console.log("ssss end");
    setIsLoading(true);

    getFileLogApi(id, currentPage, recordsPerPage, filter)
      .then((resp) => {
        const { data, total } = resp.data;
        const totalData = data.length + fileLog.length;
        dispatch(handleGetFileLogListAction({ data, total }));
        if (total === 0) {
          setHasMore(true);
        } else if (total <= recordsPerPage || totalData === total) {
          console.log("wssss", totalData, total,data.length)
          setHasMore(false);
        }
      })
      .catch((error) => {
        console.error(
          "Error fetching file log:",
          error.response?.data?.message || error.message
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id, currentPage, filter ]);

  const handleDateChange = ({ startDate, endDate }) => {
    setFilter((prev) => ({
      ...prev,
      startDate: startDate,
      endDate: endDate,
    }));
  };

  const handleSearchChange = (value) => {
    if (value.length > 0) CancelRequest();
    setFilter((prev) => ({
      ...prev,
      query: value,
    }));
    setCurrentPage(1);
  };

  const handleScroll = () => {
    const container = containerRef.current?.getScrollElement();
    if (!container || isLoading || !hasMore) return;

    const { scrollTop, scrollHeight, clientHeight } = container;
    if (scrollHeight - scrollTop <= clientHeight + 100) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    if (currentPage === 1) {
      dispatch(handleClearFileLogList());
      console.log("data cleared", fileLog.length)
    }
    fetchFileLog(currentPage);
  }, [currentPage, filter]);

  useEffect(() => {
    const container = containerRef.current?.getScrollElement();
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isLoading, hasMore]);

  return (
    <div className="p-3">
      <div className="row py-2">
        <span className="col-3 d-flex align-items-center">
          <h4>File Log</h4>
        </span>
        <div className="col-md-9 col-sm-12 row">
          <div className="col-md-5 col-sm-12">
            <DebounceSearchInput handleSearchChange={handleSearchChange} />
          </div>
          <div className="col-md-7 col-sm-12">
            <DatePicker
              onChange={handleDateChange}
              autoHide={true}
              todayBtn={true}
              clearBtn={true}
              format="yyyy/mm/dd"
            />
          </div>
        </div>
      </div>
      <SimpleBar
        style={{ height: "650px" }}
        className="mb-2"
        ref={containerRef}
      >
        <Schedule>
          {fileLog.map((file, index) => (
            <Schedule.Item
              symbol="active"
              contentClass="nk-schedule-content-no-border flex-grow-1"
              key={index}
            >
              <RenderTrackingItem file={file} user={user} />
            </Schedule.Item>
          ))}
          {isLoading && (
            <div className="text-center my-4">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
          {!isLoading && fileLog.length === 0 && (
            <div className="text-muted text-center py-5">
              <Icon name="file" size="lg" className="mb-2" />
              <p className="mb-0">Tracking info not available</p>
            </div>
          )}
        </Schedule>
      </SimpleBar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  fileLog: state.file.fileLogs || [],
  total: state.file.totals || 0,
  user: state.auth.user || null,
});
export default connect(mapStateToProps)(FileLogList);

const RenderTrackingItem = ({ file, user }) => {
  const status = file.status;
  const statusComponentMap = {
    out: <OutLogRecord file={file} user={user} />,
    received: <ReceiveLogRecord file={file} user={user} />,
    dispatched: <DispatchLogRecord file={file} user={user} />,
    in: <InLogRecord file={file} user={user} />,
  };

  return statusComponentMap[status] || null;
};

const ActionStatus = ({ status }) => {
  const statusBadgeMap = {
    move: "danger-soft",
    escalation: "warning-soft",
    approval: "primary-soft",
    clarification: "info-soft",
    completed: "success-soft",
  };

  const badgeClass = statusBadgeMap[status];
  return badgeClass ? (
    <span className={`badge text-bg-${badgeClass}`}>
      {formatUnderscoreString(status)}
    </span>
  ) : null;
};

const ConvertToReadableTime = ({ hours }) => {
  const days = Math.floor(hours / 24);
  const remainingHours = Math.floor(hours % 24);

  const formatToTwoDigits = (value) => (value < 10 ? `${value}` : value);

  let borderColor = "success";
  if (hours <= 19) {
    borderColor = "danger";
  }

  return (
    <div
      className={`clock-container p-2 d-flex align-items-center justify-content-center border border-${borderColor} time`}
    >
      {days > 0 ? (
        <div className="text-center">
          <strong>
            {formatToTwoDigits(days)} Day{days > 1 ? "s" : ""}
          </strong>
        </div>
      ) : (
        <strong className="text-center fs-6">
          {formatToTwoDigits(remainingHours)} Hour
          {remainingHours > 1 ? "s" : ""}
        </strong>
      )}
    </div>
  );
};

const LogRecord = ({ file, status, variant, badgeText, user }) => {
  const navigation = useNavigate();
  const viewUser = (checkUser) => {
    if (user?.role === "admin") {
      navigation(`/user/${checkUser.id}`);
    } else if (user?.department === checkUser?.department) {
      navigation(`/user/${checkUser.id}`);
    }
  };

  return (
    <Alert variant={variant} className="mt-2">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div>
          <span className="smaller me-2">
            <Icon name="clock" className="me-1" /> {file?.created_at}
          </span>
          <ActionStatus status={file?.action} />
        </div>
        <span className="smaller me-2 text-muted">
          <Icon name="calender-date-fill" className="me-1 ms-1" />
          <span className="me-1">
            Due Date: <strong>{file?.due_date}</strong>
          </span>
          <span className={`badge text-bg-${variant} ms-2`}>{badgeText}</span>
        </span>
      </div>

      <div className="d-flex justify-content-around">
        <div className="d-flex flex-wrap flex-grow-1 justify-content-between">
          {[
            ...(["received", "dispatched"].includes(file.status)
              ? ["daak_user"]
              : []),
            "sender",
            "receiver",
          ].map((role) => (
            <div className="me-3" key={role}>
              <h6 className="mb-1 d-flex align-items-center">
                {formatUnderscoreString(role)}
              </h6>

              <p
                className="text-muted mb-1"
                onClick={() => viewUser(file[role])}
              >
                <Icon name="user-fill" className="me-2 text-secondary" />
                {file[role]?.name}
              </p>

              <p className="text-muted mb-1">
                <Icon name="call-fill" className="me-2 text-secondary" />
                {file[role]?.phone_number}{" "}
                {file[role]?.extension_number && (
                  <span>| {file[role]?.extension_number}</span>
                )}
              </p>

              <p className="text-muted mb-1">
                <Icon name="building" className="me-2 text-secondary" />
                {getDepartmentLabel(file[role]?.department)}
              </p>
            </div>
          ))}

          <div>
            {file.time_taken > 0 && (
              <ConvertToReadableTime hours={file.time_taken} />
            )}
          </div>
        </div>
      </div>

      {file.clarification_details && file.action === "clarification" && (
        <div className="d-flex align-items-center mt-2">
          <Icon name="help" />{" "}
          <span className="fs-6 ms-2">
            {" "}
            <strong>Clarification Details: </strong>
            {file.clarification_details}
          </span>
        </div>
      )}
      {file.comments && (
        <div className="d-flex align-items-center mt-2">
          <Icon name="comments" />{" "}
          <span className="fs-6 ms-2">{file.comments}</span>
        </div>
      )}
    </Alert>
  );
};

const OutLogRecord = ({ file, user }) => {
  return (
    <LogRecord
      file={file}
      user={user}
      status="out"
      variant="danger"
      badgeText="OUT"
    />
  );
};

const ReceiveLogRecord = ({ file, user }) => {
  return (
    <LogRecord
      user={user}
      file={file}
      status="received"
      variant="info"
      badgeText="Received"
    />
  );
};

const DispatchLogRecord = ({ file, user }) => {
  return (
    <LogRecord
      user={user}
      file={file}
      status="dispatched"
      variant="success"
      badgeText="Dispatched"
    />
  );
};

const InLogRecord = ({ file, user }) => {
  return (
    <LogRecord
      file={file}
      user={user}
      status="in"
      variant="warning"
      badgeText="IN"
    />
  );
};
