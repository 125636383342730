import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import SimpleBar from "simplebar-react";
import { Icon, Schedule } from "../../components";
import { getOverdueFilesApi } from "../../services/FileService";
import { Alert, Spinner } from "react-bootstrap";
import Pagination from "../Constant/Pagination";
import { getDepartmentLabel } from "../../utilities";

const OverdueFiles = ({ userRole }) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPage = 5;
  const navigate = useNavigate();

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getOverdueFilesApi(id, currentPage, recordsPage)
      .then((resp) => {
        setData(resp.data.data);
        setTotal(resp.data.count);
      })
      .catch((error) => {
        console.error(
          "Error fetching file data:",
          error.response?.data?.message || error.message
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id, currentPage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);


  const calculateTimeDifference = (start, end) => {
    // Convert DD-MM-YYYY HH:mm AM/PM to a parseable format
    const parseDate = (dateStr) => {
      const [date, time, meridian] = dateStr.split(' ');
      const [day, month, year] = date.split('-');
      const [hours, minutes] = time.split(':');
      let adjustedHours = parseInt(hours, 10);
      
      if (meridian === "PM" && adjustedHours !== 12) {
        adjustedHours += 12;
      } else if (meridian === "AM" && adjustedHours === 12) {
        adjustedHours = 0;
      }
      
      return new Date(`${year}-${month}-${day}T${adjustedHours.toString().padStart(2, '0')}:${minutes}`);
    };
  
    const startTime = parseDate(start);
    const endTime = parseDate(end);
  
    // Calculate time difference
    const differenceMs = endTime - startTime;
  
    if (differenceMs < 0) {
      return "Invalid time range";
    }
  
    const totalMinutes = Math.floor(differenceMs / (1000 * 60));
    const totalHours = Math.floor(totalMinutes / 60);
  
    if (totalHours < 1) {
      return `${totalMinutes} minute${totalMinutes > 1 ? 's' : ''}`; // Less than 1 hour
    }
  
    const days = Math.floor(totalHours / 24);
    const hours = totalHours % 24;
  
    if (days > 0 && hours > 0) {
      return `${days} day${days > 1 ? 's' : ''} and ${hours} hour${hours > 1 ? 's' : ''}`;
    } else if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''}`;
    } else {
      return `${hours} hour${hours > 1 ? 's' : ''}`;
    }
  };
  

  
  const handlePagination = (page) => {
    setCurrentPage(page.selected + 1);
  };

  console.log("role", userRole)

  return (
    <div className="p-3">
      <h4 className=" fw-bold">Overdue Files</h4>
      <SimpleBar style={{ height: "650px" }} className="mb-3 mt-4">
        <Schedule>
          {isLoading ? (
            <div className="text-center my-4">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : data.length > 0 ? (
            data.map((item, index) => (
              <Schedule.Item
                symbol="active"
                contentClass="nk-schedule-content-no-border flex-grow-1"
                key={index}
              >
                <div className="list-group-dotted border rounded shadow-sm p-3 mb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h5 className="mb-2">Responsible User</h5>
                      <div className="d-flex align-items-center mb-1 mt-2">
                        <Icon
                          name="user-fill"
                          className="me-2 text-secondary fs-5"
                        />
                        {userRole === "admin" ? (
                          <p
                            className="text-primary fw-bold mb-0"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate(`/user/${item?.user_responsible?.id}`)
                            }
                          >
                            {item?.user_responsible?.name}
                          </p>
                        ) : (
                          <p className="fw-bold mb-0">
                            {item?.user_responsible?.name}
                          </p>
                        )}
                      </div>
                      <div className="d-flex align-items-start">
                        <Icon
                          name="building"
                          className="me-2 text-secondary fs-5"
                        />
                        <p className="text-dark mb-0">
                          {getDepartmentLabel(
                            item?.user_responsible?.department
                          )}
                        </p>
                      </div>
                    </div>
                    <div>
                      {item?.overdue_start_time && (
                        <div className="mb-3">
                          <Icon
                            name="clock"
                            className="me-2 text-primary fs-5"
                          />
                          <span className="text-muted">Start Time</span>
                          <p className="fs-6 text-dark fw-bold">
                            {item?.overdue_start_time}
                          </p>
                        </div>
                      )}
                      {item?.overdue_end_time && (
                        <div>
                          <Icon
                            name="clock"
                            className="me-2 text-warning fs-5"
                          />
                          <span className="text-muted">End Time</span>
                          <p className="fs-6 text-dark fw-bold">
                            {item?.overdue_end_time}
                          </p>
                        </div>
                      )}
                    </div>
                    {item?.overdue_end_time && item?.overdue_start_time  && (
                      <Alert
                        variant={calculateTimeDifference(
                          item?.overdue_start_time,
                          item?.overdue_end_time
                        ).includes("day") ? "danger" : "success"}
                        className="text-center px-3 py-2 shadow-sm"
                      >
                        <p className="text-secondary mb-1">Duration</p>
                        <p className="fs-5 fw-bold">
                          {calculateTimeDifference(
                            item?.overdue_start_time,
                            item?.overdue_end_time
                          )}
                        </p>
                      </Alert>
                    )}
                  </div>
                </div>
              </Schedule.Item>
            ))
          ) : (
            <div className="text-muted text-center py-5">
              <Icon name="file" size="lg" className="mb-2" />
              <p className="mb-0">Overdue Files Not Available</p>
            </div>
          )}
        </Schedule>
      </SimpleBar>

      <div className="mt-3">
        <Pagination
          currentPage={currentPage}
          recordsPage={recordsPage}
          dataLength={total}
          handlePagination={handlePagination}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userRole: state.auth.user?.role || "user",
});

export default connect(mapStateToProps)(OverdueFiles);
