import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Tab, Nav, Card, Button, Alert, Row, Col, Form, OverlayTrigger, Tooltip, ListGroup } from 'react-bootstrap';
import Swal from 'sweetalert2/src/sweetalert2.js';
import Block from '../../components/Block/Block';
import { Image, Icon, Media, MediaGroup, MediaText, MediaAction, Select } from '../../components';
import { useCallback, useEffect, useState } from 'react';
import { changePasswordApi, getProfileApi } from '../../services/ProfileService';
import { parse } from 'url';
import { deptOptions, roleOptions } from '../../utilities';
import ProfilePhoto from './ProfilePhoto';
import { toast } from "react-toastify";
import Loading from '../../components/Loader/Loading';
import FileHistory from './FileHistory';

function ProfileSettingsPage() {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState("tab-1");

    useEffect(() => {
        if (location.pathname === "/profile") {
            setActiveTab("tab-1");
        } else if (location.pathname === "/account-settings") {
            setActiveTab("tab-2");
        }
    }, [location.pathname]);
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setDropdownVisible(false); // Close dropdown when switching tabs
    };

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    // (tab-1)
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [isEditing, setIsEditing] = useState(false);
    {/*
     const [reportsToOptions, setReportsToOptions] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await searchUserApi();
                const { data } = response.data;
                setReportsToOptions(data);
                setIsLoading(false)

            } catch (error) {
                console.error("Error fetching data", error);
                setIsLoading(false)
            }
        };

        fetchData();
    }, []);

    const handleSelectChange = (e) => {
        const selectedOption = reportsToOptions.find(
            (option) => option.value === e.target.value
        );
        setUser((prevData) => ({
            ...prevData,
            reports_to: selectedOption
                ? { id: selectedOption.value, name: selectedOption.label }
                : null,
        }));
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUser((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };

    const validateProfileForm = () => {
        const newErrors = {};
        if (!user.name) newErrors.name = "Name is required";
        if (!user.email) newErrors.email = "Email is required";
        if (!user.phone_number) newErrors.phone_number = "Phone number is required";
        if (!user.address) newErrors.address = "Address is required";
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateProfileForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        const formData = {
            name: user.name,
            email: user.email,
            phone_number: user.phone_number,
            address: user.address,
            reports_to: user.reports_to?.id, 
            description: user.description,
        };

        try {
            const response = await updateProfileApi(formData);
            toast.success(response?.data?.message);
            navigate("/profile");
        } catch (error) {
            toast.error(error.response?.data?.message || "An error occurred");
        }
    };
*/}


    const fetchData = useCallback(() => {
        setIsLoading(true);
        setError(null);
        getProfileApi()
            .then((resp) => {
                setUser(resp.data.data);
            })
            .catch((error) => {
                const errorMessage = error.response?.data?.message || "Failed to fetch profile data.";
                setError(errorMessage);
                console.error(errorMessage);
            })
            .finally(() => {
                setIsLoading(false);
            });

    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);


    // delete account alert
    const alertConfirm = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                Swal.fire('Deleted', 'Your account has been deleted.', 'success')
            } else {
                Swal.fire('Cancelled', 'Your account is still intact', 'info')
            }
        })
    };


    // for Password chhange(tab-2)
    const [current_password, setcurrent_password] = useState("");
    const [new_password, setnew_password] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");


    const [errors, setErrors] = useState({
        current_password: "",
        new_password: "",
        confirmPassword: "",
    });
    // Toggle password visibility
    const [passwordVisibility, setPasswordVisibility] = useState({
        current_password: false,
        new_password: false,
        confirmPassword: false,
    });
    const handlePasswordToggle = (field) => {
        setPasswordVisibility((prevState) => ({
            ...prevState,
            [field]: !prevState[field],
        }));
    };



    const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%?&])[A-Za-z\d@$!%?&]{8,}$/;

    const validateForm = () => {
        let isValid = true;
        const newErrors = { current_password: "", new_password: "", confirmPassword: "" };

        if (!current_password) {
            newErrors.current_password = "Old password is required.";
            isValid = false;
        }
        if (!new_password) {
            newErrors.new_password = "New password is required.";
            isValid = false;
        } else if (!passwordRegex.test(new_password)) {
            newErrors.new_password =
                "Password must be at least 8 characters, include an uppercase letter, a number, and a special character.";
            isValid = false;
        }
        if (!confirmPassword) {
            newErrors.confirmPassword = "Please confirm your new password.";
            isValid = false;
        } else if (new_password !== confirmPassword) {
            newErrors.confirmPassword = "Passwords do not match.";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleChangePassword = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }
        const payload = {
            current_password,
            new_password,
        };
        try {
            const response = await changePasswordApi(payload);
            toast.success(response?.data?.message);
            setcurrent_password("");
            setnew_password("");
            setConfirmPassword("");
            setErrors({ current_password: "", new_password: "", confirmPassword: "" });
        } catch (error) {
            toast.error(error.response?.data?.message || "An error occurred");
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <>
            <Block.Head>
                <Block.HeadBetween className="align-items-start">
                    <Block.HeadContent>
                        <div className="d-flex flex-column flex-md-row align-items-md-center">
                            <ProfilePhoto user={user} />

                            <div className="mt-3 mt-md-0 ms-md-3">
                                <h3 className="title text-uppercase mb-1">{user?.name}</h3>
                                <h5 className="text-uppercase">{user?.designation}</h5>
                                <ul className="nk-list-option">
                                    {user?.department && (
                                    <li>
                                        <Icon name="building" />:
                                        &nbsp;
                                        <span className="small">
                                            {deptOptions.find((option) => option?.value === user?.department)?.label}
                                        </span>
                                    </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </Block.HeadContent>
                    <Block.HeadContent>
                        {/*  <div className="d-flex gap g-3">
                            <div className="gap-col">
                                <ul className="d-flex gap g-2">
                                    {isEditing ? (
                                        <>
                                            <li className="d-none d-md-block">
                                                <button
                                                    className="btn btn-soft btn-primary"
                                                    onClick={() => {
                                                        setIsEditing(false);

                                                    }}
                                                >
                                                    <Icon name="user"></Icon>
                                                    <span>My Profile</span>
                                                </button>
                                            </li>
                                            <li className="d-md-none">
                                                <button
                                                    className="btn btn-soft btn-primary btn-icon"
                                                    onClick={() => {
                                                        setIsEditing(false);

                                                    }}
                                                >
                                                    <Icon name="user"></Icon>
                                                </button>
                                            </li>
                                        </>
                                    ) : (
                                        <> <li className="d-none d-md-block">
                                            <button
                                                className="btn btn-soft btn-primary"
                                                onClick={() => {
                                                    setIsEditing(true);

                                                }}
                                            >
                                                <Icon name="edit"></Icon>
                                                <span>Edit Profile</span>
                                            </button>
                                        </li>
                                            <li className="d-md-none">
                                                <button
                                                    className="btn btn-soft btn-primary btn-icon"
                                                    onClick={() => {
                                                        setIsEditing(true);

                                                    }}
                                                >
                                                    <Icon name="edit"></Icon>
                                                </button>
                                            </li>
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>*/}
                    </Block.HeadContent>
                </Block.HeadBetween>
            </Block.Head>

            <Tab.Container activeKey={activeTab}>
                <Block.HeadBetween>
                    <div className="gap-col">
                        <Nav variant="pills" className="nav-pills-border gap g-3">
                            {/* My Profile Tab */}
                            <Nav.Item>
                                <Nav.Link
                                    eventKey="tab-1"
                                    onClick={() => handleTabClick("tab-1")}
                                    active={activeTab === "tab-1"}
                                >
                                    <Icon name="user"></Icon> My Profile
                                </Nav.Link>
                            </Nav.Item>

                            {/* Account Setting Dropdown */}
                            <Nav.Item style={{ position: "relative" }}>
                                <Nav.Link
                                    eventKey="tab-2"
                                    className="dropdown-toggle"
                                    onClick={toggleDropdown}
                                    active={activeTab === "tab-2"}
                                >
                                    <Icon name="setting-alt"></Icon> Account Settings
                                </Nav.Link>

                                {dropdownVisible && (
                                    <div
                                        className="dropdown-menu crazy-style"
                                        style={{
                                            position: "absolute",
                                            top: "100%",
                                            left: 0,
                                            zIndex: 10,
                                            backgroundColor: "rgba(0, 0, 0, 0.9)",
                                            color: "#fff",
                                            borderRadius: "10px",
                                            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)",
                                            animation: "crazyDropdown 0.5s ease-in-out",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <Nav className="flex-column">
                                            <Nav.Item>
                                                <Nav.Link
                                                    eventKey="tab-2"
                                                    onClick={() => handleTabClick("tab-2")}
                                                    className="crazy-link"
                                                >
                                                    <em class="icon ni ni-shield-alert-fill"></em> Security
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link
                                                    eventKey="tab-3"
                                                    onClick={() => handleTabClick("tab-3")}
                                                    className="crazy-link"
                                                >
                                                    <em class="icon ni ni-bell-fill"></em> Notifications
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link
                                                    eventKey="tab-4"
                                                    onClick={() => handleTabClick("tab-4")}
                                                    className="crazy-link"
                                                >
                                                    <em className="icon ni ni-account-setting-fill"></em> Connections
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                )}
                            </Nav.Item>
                        </Nav>
                    </div>
                </Block.HeadBetween>

                <Block className="mt-4">
                    <Tab.Content>

                        <Tab.Pane eventKey="tab-1" transition={false}>
                            {isEditing ? (
                                {/* <Card className="card-gutter-md">
                                    <Card.Body>
                                        <div className="bio-block">
                                            <h4 className="bio-block-title mb-4">Edit Profile</h4>
                                            <Form onSubmit={handleSubmit}>
                                                <Row className="g-3">
                                                    <Col lg="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="name">Name</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="name"
                                                                id="name"
                                                                value={user.name}
                                                                placeholder="Full Name"
                                                                onChange={handleInputChange}
                                                            />
                                                            {errors.name && <div className="text-danger">{errors.name}</div>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="email">Email</Form.Label>
                                                            <Form.Control
                                                                type="email"
                                                                name="email"
                                                                id="email"
                                                                value={user.email}
                                                                placeholder="Email Id"
                                                                onChange={handleInputChange}
                                                            />
                                                            {errors.email && <div className="text-danger">{errors.email}</div>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="phone_number">Phone Number</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="phone_number"
                                                                id="phone_number"
                                                                value={user.phone_number}
                                                                placeholder="Phone Number"
                                                                onChange={handleInputChange}
                                                            />
                                                            {errors.phone_number && (
                                                                <div className="text-danger">{errors.phone_number}</div>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group>
                                                            <Form.Label>Reports To</Form.Label>
                                                            {isLoading ? (
                                                                <div>Loading...</div>
                                                            ) : (
                                                                <select
                                                                    id="reports_to"
                                                                    name="reports_to"
                                                                    value={user.reports_to?.id || ""}
                                                                    onChange={handleSelectChange}
                                                                    className="form-select"
                                                                >
                                                                    <option value="">Choose Reports To</option>
                                                                    {reportsToOptions.map((option) => (
                                                                        <option key={option.value} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg="12">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="address">Address</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="address"
                                                                id="address"
                                                                value={user.address}
                                                                placeholder="e.g. California, United States"
                                                                onChange={handleInputChange}
                                                            />
                                                            {errors.address && <div className="text-danger">{errors.address}</div>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg="12">
                                                        <Form.Group className="form-group">
                                                            <Form.Label>About Me</Form.Label>
                                                            <Form.Control
                                                                as="textarea"
                                                                rows="3"
                                                                name="description"
                                                                value={user.description}
                                                                onChange={handleInputChange}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <div className="d-flex justify-content-center">
                                                        <Col lg="2" className="d-flex justify-content-center">
                                                            <Button variant="primary" type="submit">
                                                                Update Profile
                                                            </Button>
                                                        </Col>
                                                        <Col lg="1" className="d-flex justify-content-center">
                                                            <Button variant="secondary" onClick={() => setIsEditing(false)}>
                                                                Cancel
                                                            </Button>
                                                        </Col>
                                                    </div>
                                                </Row>
                                            </Form>
                                        </div>
                                    </Card.Body>
                                </Card> */}
                            ) : (
                                <Card className="card-gutter-md">
                                    <div className="card-row card-row-lg col-sep col-sep-lg">
                                        <div className="card-aside">
                                            <Card.Body>
                                                <div className="bio-block">
                                                    <div className="d-flex align-items-center">
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-primary btn-sm btn-circle"
                                                            onClick={() => navigate(-1)}
                                                        >
                                                            <Icon name="arrow-left"></Icon>
                                                        </button>
                                                        <h4 className="bio-block-title mx-3">Details</h4>
                                                    </div>

                                                    <ul className="list-group list-group-borderless small">
                                                        <li className="list-group-item">
                                                            <span className="title fw-medium w-40 d-inline-block">Personnel Number:</span>
                                                            <span className="text">{user?.personnel_number}</span>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <span className="title fw-medium w-40 d-inline-block">Full Name:</span>
                                                            <span className="text">{user?.name}</span>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <span className="title fw-medium w-40 d-inline-block">Email:</span>
                                                            <span className="text">{user?.email}</span>
                                                        </li>

                                                        <li className="list-group-item">
                                                            <span className="title fw-medium w-40 d-inline-block">Phone No.:</span>
                                                            <span className="text">{user?.phone_number}</span>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <span className="title fw-medium w-40 d-inline-block">Role:</span>
                                                            <span className={`badge small text-bg-dark-soft`}>
                                                                {roleOptions.find((option) => option.value === user?.role)?.label || "NA"}
                                                            </span>
                                                        </li>

                                                        <li className="list-group-item">
                                                            <span className="title fw-medium w-40 d-inline-block">Date Of Joining:</span>
                                                            <span className="text">{user?.created_at || "NA"}</span>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <span className="title fw-medium w-40 d-inline-block">Status:</span>
                                                            <span className={`badge text-bg-${user?.status === "active" ? "success-soft"
                                                                : user?.status === "blocked" ? "danger-soft"
                                                                    : user?.status === "inactive" ? "secondary-soft"
                                                                        : "primary-soft"}`}
                                                            >
                                                                {user?.status
                                                                    ? user?.status.charAt(0).toUpperCase() + user?.status.slice(1).toLowerCase()
                                                                    : 'General'}
                                                            </span>

                                                        </li>
                                                        <li className="list-group-item">
                                                            <span className="title fw-medium w-40 d-inline-block">Reports To:</span>
                                                            <span className="text">
                                                                <strong>{user?.reports_to?.name}</strong>
                                                            </span>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <span className="title fw-medium w-40 d-inline-block">Last Login:</span>
                                                            <span className="text">
                                                                {user?.last_login}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="bio-block">
                                                    <h4 className="bio-block-title mb-2">File Permission</h4>
                                                    <ul className="d-flex flex-wrap gap gx-1" style={{ listStyle: "none", padding: 0, margin: 0 }}>
                                                        {Object.entries(user?.file_permission || {}).map(([permission, isEnabled]) => (
                                                            <li
                                                                key={permission}
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    padding: "0.25rem 0.4rem",
                                                                    margin: "0.25rem",
                                                                    fontSize: "0.75rem",
                                                                }}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    checked={isEnabled}
                                                                    disabled
                                                                    style={{
                                                                        cursor: "not-allowed",
                                                                        marginRight: "0.2rem",
                                                                    }}
                                                                />
                                                                <label style={{ margin: 0, fontSize: "0.85rem", color: "#495057" }}>
                                                                    {permission.charAt(0).toUpperCase() + permission.slice(1)}
                                                                </label>
                                                            </li>
                                                        ))}
                                                    </ul>

                                                </div>



                                            </Card.Body>
                                        </div>
                                        <div className="card-content col-sep flex-fill">
                                            <Card.Body >
                                                <FileHistory />
                                            </Card.Body>
                                        </div>
                                    </div>
                                </Card>
                            )}


                        </Tab.Pane>
                        <Tab.Pane eventKey="tab-2" transition={false}>
                            <Card className="col-sep card-gutter-md">

                                <Card.Body>
                                    <div className="bio-block">
                                        <h4 className="bio-block-title mb-4">Change Password</h4>
                                        <Form onSubmit={handleChangePassword}>
                                            <Row className="g-3">
                                                <Col lg="4">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="current_password">Old Password</Form.Label>
                                                        <div className="form-control-wrap" style={{ position: "relative" }}>
                                                            <Form.Control
                                                                type={passwordVisibility.current_password ? "text" : "password"}
                                                                placeholder="Old password"
                                                                value={current_password}
                                                                onChange={(e) => setcurrent_password(e.target.value)}
                                                            />
                                                            <span
                                                                onClick={() => handlePasswordToggle("current_password")}
                                                                style={{
                                                                    position: "absolute",
                                                                    right: "10px",
                                                                    top: "50%",
                                                                    transform: "translateY(-50%)",
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                {passwordVisibility.current_password ? (
                                                                    <Icon name="eye-alt-fill" />
                                                                ) : (
                                                                    <Icon name="eye-off-fill" />
                                                                )}
                                                            </span>
                                                        </div>
                                                        {errors.current_password && (
                                                            <div className="text-danger small">{errors.current_password}</div>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                                <Col lg="4">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="new_password">New Password</Form.Label>
                                                        <div className="form-control-wrap" style={{ position: "relative" }}>
                                                            <Form.Control
                                                                type={passwordVisibility.new_password ? "text" : "password"}
                                                                placeholder="New password"
                                                                value={new_password}
                                                                onChange={(e) => setnew_password(e.target.value)}
                                                            />
                                                            <span
                                                                onClick={() => handlePasswordToggle("new_password")}
                                                                style={{
                                                                    position: "absolute",
                                                                    right: "10px",
                                                                    top: "50%",
                                                                    transform: "translateY(-50%)",
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                {passwordVisibility.new_password ? (
                                                                    <Icon name="eye-alt-fill" />
                                                                ) : (
                                                                    <Icon name="eye-off-fill" />
                                                                )}
                                                            </span>
                                                        </div>
                                                        {errors.new_password && (
                                                            <div className="text-danger small">{errors.new_password}</div>
                                                        )}

                                                    </Form.Group>
                                                </Col>
                                                <Col lg="4">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="confirmPassword">Confirm New Password</Form.Label>
                                                        <div className="form-control-wrap" style={{ position: "relative" }}>
                                                            <Form.Control
                                                                type={passwordVisibility.confirmPassword ? "text" : "password"}
                                                                placeholder="Confirm new password"
                                                                value={confirmPassword}
                                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                            />
                                                            <span
                                                                onClick={() => handlePasswordToggle("confirmPassword")}
                                                                style={{
                                                                    position: "absolute",
                                                                    right: "10px",
                                                                    top: "50%",
                                                                    transform: "translateY(-50%)",
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                {passwordVisibility.confirmPassword ? (
                                                                    <Icon name="eye-alt-fill" />
                                                                ) : (
                                                                    <Icon name="eye-off-fill" />
                                                                )}
                                                            </span>
                                                        </div>
                                                        {errors.confirmPassword && (
                                                            <div className="text-danger small">{errors.confirmPassword}</div>
                                                        )}

                                                    </Form.Group>
                                                </Col>
                                                <Col lg="12">
                                                    <div className="d-flex flex-wrap align-items-center gap g-3">
                                                        <div className="gap-col">
                                                            <Button variant="primary" type="submit">
                                                                Change Password
                                                            </Button>
                                                        </div>

                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </Card.Body>
                                <Card.Body>
                                    <div className="bio-block">
                                        <div className="d-flex flex-wrap align-items-center justify-content-between pb-4">
                                            <h4 className="bio-block-title">Login History</h4>
                                            <a href="#logout" className="text-light small">All Logout</a>
                                        </div>
                                        <ul className="gap gy-3">
                                            <li>
                                                <MediaGroup>
                                                    <Media size="md" variant="secondary-soft" className="flex-shrink-0">
                                                        <Icon name="mobile"></Icon>
                                                    </Media>
                                                    <MediaText>
                                                        <div className="lead-text">iPhone 12 Pro</div>
                                                        <span className="sub-text">Los Angeles, United States - April 18 at 2:47 PM</span>
                                                    </MediaText>
                                                    <MediaAction end>
                                                        <OverlayTrigger placement="top" overlay={
                                                            <Tooltip>Logout</Tooltip>
                                                        }>
                                                            <a href="#logout" className="media media-xs media-circle media-middle text-bg-danger-soft">
                                                                <em className="icon ni ni-cross"></em>
                                                            </a>
                                                        </OverlayTrigger>
                                                    </MediaAction>
                                                </MediaGroup>
                                            </li>
                                            <li>
                                                <MediaGroup>
                                                    <Media size="md" variant="secondary-soft" className="flex-shrink-0">
                                                        <Icon name="mobile"></Icon>
                                                    </Media>
                                                    <MediaText>
                                                        <div className="lead-text">Apple iPad Pro</div>
                                                        <span className="sub-text">Washington, United States - November 06 at 10:43AM</span>
                                                    </MediaText>
                                                    <MediaAction end>
                                                        <OverlayTrigger placement="top" overlay={
                                                            <Tooltip>Logout</Tooltip>
                                                        }>
                                                            <a href="#logout" className="media media-xs media-circle media-middle text-bg-danger-soft">
                                                                <em className="icon ni ni-cross"></em>
                                                            </a>
                                                        </OverlayTrigger>
                                                    </MediaAction>
                                                </MediaGroup>
                                            </li>
                                            <li>
                                                <MediaGroup>
                                                    <Media size="md" variant="secondary-soft" className="flex-shrink-0">
                                                        <Icon name="mobile"></Icon>
                                                    </Media>
                                                    <MediaText>
                                                        <div className="lead-text">Galaxy S21 Ultra 5G</div>
                                                        <span className="sub-text">Washington, United States - November 08 at 10:43AM</span>
                                                    </MediaText>
                                                    <MediaAction end>
                                                        <OverlayTrigger placement="top" overlay={
                                                            <Tooltip>Logout</Tooltip>
                                                        }>
                                                            <a href="#logout" className="media media-xs media-circle media-middle text-bg-danger-soft">
                                                                <em className="icon ni ni-cross"></em>
                                                            </a>
                                                        </OverlayTrigger>
                                                    </MediaAction>
                                                </MediaGroup>
                                            </li>
                                            <li>
                                                <MediaGroup>
                                                    <Media size="md" variant="secondary-soft" className="flex-shrink-0">
                                                        <Icon name="laptop"></Icon>
                                                    </Media>
                                                    <MediaText>
                                                        <div className="lead-text">Dell Inspiron 14</div>
                                                        <span className="sub-text">Washington, United States - November 08 at 10:43AM</span>
                                                    </MediaText>
                                                    <MediaAction end>
                                                        <OverlayTrigger placement="top" overlay={
                                                            <Tooltip>Logout</Tooltip>
                                                        }>
                                                            <a href="#logout" className="media media-xs media-circle media-middle text-bg-danger-soft">
                                                                <em className="icon ni ni-cross"></em>
                                                            </a>
                                                        </OverlayTrigger>
                                                    </MediaAction>
                                                </MediaGroup>
                                            </li>
                                        </ul>
                                    </div>
                                </Card.Body>
                                <Card.Body>
                                    <div className="bio-block">
                                        <h4 className="bio-block-title mb-4">Delete Account</h4>
                                        <Alert variant="warning" className="mt-2">
                                            <div className="d-flex">
                                                <Icon size="lg" name="question-alt" className="opacity-75"></Icon>
                                                <div className="ms-2">
                                                    <h6 className="alert-heading mb-1">You Are Deleting Your Account!</h6>
                                                    <span className="smaller d-block">For extra security, this requires you to confirm your email or phone number when you reset yousignr password.</span>
                                                    <a className="smaller" href="#learn-more" target="_blank">Learn more</a>
                                                </div>
                                            </div>
                                        </Alert>
                                        <Form.Group controlId="confirmAccountDeteting">
                                            <Form.Check className="form-check-sm" type="checkbox" label="I confirm my account deleting!" />
                                        </Form.Group>
                                        <div className="mt-3">
                                            <Button variant="danger" onClick={alertConfirm}>Delete Account</Button>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tab-3" transition={false}>
                            <Card className="card-gutter-md">
                                <Card.Body>
                                    <div className="bio-block">
                                        <h4 className="bio-block-title">Notifications</h4>
                                        <p className="mb-4">You will get only notification what have enabled.</p>
                                        <ListGroup className="list-group-borderless">
                                            <ListGroup.Item className="d-flex align-items-md-center">
                                                <div className="flex-grow-1">
                                                    <label htmlFor="directMessage" className="form-check-label ps-0 fw-medium">Direct messages</label>
                                                    <p className="text-light">Messages from people you follow</p>
                                                </div>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch" id="directMessage" defaultChecked />
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="d-flex align-items-md-center">
                                                <div className="flex-grow-1">
                                                    <label className="form-check-label ps-0 fw-medium" htmlFor="desktopNotification">
                                                        Show desktop notifications
                                                    </label>
                                                    <p className="text-light">Choose the option you want as your default setting. Block a site: Next to "Not allowed to send notifications," click Add.</p>
                                                </div>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch" id="desktopNotification" defaultChecked />
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="d-flex align-items-md-center">
                                                <div className="flex-grow-1">
                                                    <label className="form-check-label ps-0 fw-medium" htmlFor="emailNotification">
                                                        Show email notifications
                                                    </label>
                                                    <p className="text-light">Under Settings, choose Notifications. Under Select an account, choose the account to enable notifications for.</p>
                                                </div>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch" id="emailNotification" defaultChecked />
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="d-flex align-items-md-center">
                                                <div className="flex-grow-1">
                                                    <label className="form-check-label ps-0 fw-medium" htmlFor="chatNotification">
                                                        Show chat notifications
                                                    </label>
                                                    <p className="text-light">To prevent duplicate mobile notifications from the Gmail and Chat apps, in settings, turn off Chat notifications.</p>
                                                </div>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch" id="chatNotification" />
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="d-flex align-items-md-center">
                                                <div className="flex-grow-1">
                                                    <label className="form-check-label ps-0 fw-medium" htmlFor="purchaseNotification">
                                                        Show purchase notifications
                                                    </label>
                                                    <p className="text-light">Get real-time purchase alerts to protect yourself from fraudulent charges.</p>
                                                </div>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch" id="purchaseNotification" />
                                                </div>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tab-4" transition={false}>
                            <Card className="col-sep card-gutter-md">
                                <Card.Body>
                                    <div className="bio-block">
                                        <h4 className="bio-block-title">Connected accounts</h4>
                                        <p className="mb-4">Display content from your connected accounts on your site.</p>
                                        <ListGroup className="list-group-borderless">
                                            <ListGroup.Item className="d-flex justify-content-between align-items-center">
                                                <MediaGroup>
                                                    <Media variant="secondary-soft" className="flex-shrink-0">
                                                        <Image src="/images/icon/d.png" alt="icon" />
                                                    </Media>
                                                    <MediaText>
                                                        <span className="title">Google</span>
                                                        <span className="text smaller">Calendar and contacts</span>
                                                    </MediaText>
                                                </MediaGroup>
                                                <Form.Check type="switch" id="checkboxGoogle" defaultChecked />
                                            </ListGroup.Item>
                                            <ListGroup.Item className="d-flex justify-content-between align-items-center">
                                                <MediaGroup>
                                                    <Media variant="secondary-soft" className="flex-shrink-0">
                                                        <Image src="/images/icon/f.png" alt="icon" />
                                                    </Media>
                                                    <MediaText>
                                                        <span className="title">Slack</span>
                                                        <span className="text smaller">Communication</span>
                                                    </MediaText>
                                                </MediaGroup>
                                                <Form.Check type="switch" id="checkboxSlack" />
                                            </ListGroup.Item>
                                            <ListGroup.Item className="d-flex justify-content-between align-items-center">
                                                <MediaGroup>
                                                    <Media variant="secondary-soft" className="flex-shrink-0">
                                                        <Image src="/images/icon/g.png" alt="icon" />
                                                    </Media>
                                                    <MediaText>
                                                        <span className="title">Github</span>
                                                        <span className="text smaller">Manage your Git repositories</span>
                                                    </MediaText>
                                                </MediaGroup>
                                                <Form.Check type="switch" id="checkboxGithub" defaultChecked />
                                            </ListGroup.Item>
                                            <ListGroup.Item className="d-flex justify-content-between align-items-center">
                                                <MediaGroup>
                                                    <Media variant="secondary-soft" className="flex-shrink-0">
                                                        <Image src="/images/icon/h.png" alt="icon" />
                                                    </Media>
                                                    <MediaText>
                                                        <span className="title">Mailchimp</span>
                                                        <span className="text smaller">Email marketing service</span>
                                                    </MediaText>
                                                </MediaGroup>
                                                <Form.Check type="switch" id="checkboxMailchimp" defaultChecked />
                                            </ListGroup.Item>
                                            <ListGroup.Item className="d-flex justify-content-between align-items-center">
                                                <MediaGroup>
                                                    <Media variant="secondary-soft" className="flex-shrink-0">
                                                        <Image src="/images/icon/i.png" alt="icon" />
                                                    </Media>
                                                    <MediaText>
                                                        <span className="title">Asana</span>
                                                        <span className="text smaller">Communication</span>
                                                    </MediaText>
                                                </MediaGroup>
                                                <Form.Check type="switch" id="checkboxAsana" defaultChecked />
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </div>
                                </Card.Body>
                                <Card.Body>
                                    <div className="bio-block">
                                        <h4 className="bio-block-title">Social accounts</h4>
                                        <p className="mb-4">Display content from social accounts on your site.</p>
                                        <ListGroup className="list-group-borderless">
                                            <ListGroup.Item>
                                                <MediaGroup>
                                                    <Media variant="secondary-soft" className="flex-shrink-0">
                                                        <Image src="/images/icon/b.png" alt="icon" />
                                                    </Media>
                                                    <MediaText>
                                                        <span className="title">Facebook</span>
                                                        <span className="text smaller text-success">Connected</span>
                                                    </MediaText>
                                                    <MediaAction end>
                                                        <OverlayTrigger placement="top" overlay={
                                                            <Tooltip>Disconnect</Tooltip>
                                                        }>
                                                            <a href="#disconnect" className="media media-xs media-circle media-middle text-bg-danger-soft">
                                                                <em className="icon ni ni-cross"></em>
                                                            </a>
                                                        </OverlayTrigger>
                                                    </MediaAction>
                                                </MediaGroup>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <MediaGroup>
                                                    <Media variant="secondary-soft" className="flex-shrink-0">
                                                        <Image src="/images/icon/e.png" alt="icon" />
                                                    </Media>
                                                    <MediaText>
                                                        <span className="title">Instagram</span>
                                                        <span className="text smaller">Not Connected</span>
                                                    </MediaText>
                                                    <MediaAction end>
                                                        <OverlayTrigger placement="top" overlay={
                                                            <Tooltip>Connect</Tooltip>
                                                        }>
                                                            <a href="#connect" className="media media-xs media-circle media-middle text-bg-secondary-soft">
                                                                <em className="icon ni ni-link-alt"></em>
                                                            </a>
                                                        </OverlayTrigger>
                                                    </MediaAction>
                                                </MediaGroup>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <MediaGroup>
                                                    <Media variant="secondary-soft" className="flex-shrink-0">
                                                        <Image src="/images/icon/j.png" alt="icon" />
                                                    </Media>
                                                    <MediaText>
                                                        <span className="title">Behance</span>
                                                        <span className="text smaller">Not Connected</span>
                                                    </MediaText>
                                                    <MediaAction end>
                                                        <OverlayTrigger placement="top" overlay={
                                                            <Tooltip>Connect</Tooltip>
                                                        }>
                                                            <a href="#connect" className="media media-xs media-circle media-middle text-bg-secondary-soft">
                                                                <em className="icon ni ni-link-alt"></em>
                                                            </a>
                                                        </OverlayTrigger>
                                                    </MediaAction>
                                                </MediaGroup>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <MediaGroup>
                                                    <Media variant="secondary-soft" className="flex-shrink-0">
                                                        <Image src="/images/icon/k.png" alt="icon" />
                                                    </Media>
                                                    <MediaText>
                                                        <span className="title">Behance</span>
                                                        <span className="text smaller">Not Connected</span>
                                                    </MediaText>
                                                    <MediaAction end>
                                                        <OverlayTrigger placement="top" overlay={
                                                            <Tooltip>Connect</Tooltip>
                                                        }>
                                                            <a href="#connect" className="media media-xs media-circle media-middle text-bg-secondary-soft">
                                                                <em className="icon ni ni-link-alt"></em>
                                                            </a>
                                                        </OverlayTrigger>
                                                    </MediaAction>
                                                </MediaGroup>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Tab.Pane>
                    </Tab.Content>
                </Block>
            </Tab.Container>

        </>

    )
}

export default ProfileSettingsPage;