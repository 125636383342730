import React, { useState, useEffect, useCallback } from "react";
import { CancelRequest } from "../../services/AxiosInstance";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Card, Modal, Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import Block from "../../components/Block/Block";
import { Icon, MediaGroup, MediaText } from "../../components";
import { getFileListApi } from "../../services/FileService";
import DataTable from "react-data-table-component";
import DataTablePagination from "../../components/Pagination/DataTablePagination";
import {
  deptOptions,
  fileSourceOptions,
  priorityOptions,
  statusOptions,
} from "../../utilities";
import DatePicker from "../../components/Form/DateRangePicker";
import DebounceSearchInput from "../Constant/DebounceSearchInput";
import Pagination from "../Constant/Pagination";

const headers = [
  {
    key: "status",
    label: "Status",
    options: statusOptions,
  },
  {
    key: "priorityLevel",
    label: "Priority Level",
    options: priorityOptions,
  },
  {
    key: "fileSources",
    label: "File Sources",
    options: fileSourceOptions,
  },
  {
    key: "department",
    label: "Department",
    options: deptOptions,
  },
];

function FilePage({ userRole }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState();
  const [showItemPerPage, setShowItemPerPage] = useState(25);
  const [mobileView, setMobileView] = useState(false);
  const navigate = useNavigate();
  const [filter, setFilter] = useState({
    query: "",
    status: "",
    priorityLevel: "",
    fileSources: "",
    department: "",
    startDate: "",
    endDate: "",
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [tempfilter, setTempfilter] = useState({ ...filter });

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getFileListApi(currentPage, showItemPerPage, filter)
      .then((resp) => {
        setData(resp.data.results);
        setTotal(resp.data.count);
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentPage, showItemPerPage, filter]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePagination = (page) => {
    setCurrentPage(page.selected + 1);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setShowItemPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  const handleDateChange = ({ startDate, endDate }) => {
    setFilter((prev) => ({
      ...prev,
      startDate: startDate,
      endDate: endDate,
    }));
  };

  const handleSearchChange = (value) => {
    if (value.length > 0) CancelRequest();
    setFilter((prev) => ({
      ...prev,
      query: value,
    }));
    setCurrentPage(1);
  };

  const handleToggleModal = () => {
    if (modalVisible) {
      setTempfilter({ ...filter });
    }
    setModalVisible((prev) => !prev);
  };

  const handleSelectChange = (filterKey, value) => {
    setTempfilter((prev) => ({
      ...prev,
      [filterKey]: value,
    }));
  };

  const handleApplyfilter = () => {
    setFilter(tempfilter);
    setModalVisible(false);
  };

  const handleCancelfilter = () => {
    const defaultfilter = {
      status: "",
      priorityLevel: "",
      fileSources: "",
      department: "",
    };
    setTempfilter(defaultfilter);
    setFilter(defaultfilter);
    setModalVisible(false);
  };

  const removeBadge = (filterKey) => {
    setTempfilter((prev) => ({
      ...prev,
      [filterKey]: "",
    }));
  };

  const columns = [
    {
      name: "File Details",
      selector: (row) => {
        return (
          <MediaGroup>
            <MediaText>
              <Link to={`/file/${row?.id}`} className="title">
                <strong>{row?.name}</strong>{" "}
              </Link>
              <span
                className="text-primary fw-bold"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/file/${row?.id}`)}
              >
                {row?.file_number}
              </span>
            </MediaText>
          </MediaGroup>
        );
      },
    },
    {
      name: "Department",
      selector: (row) => {
        return (
          <span>
            {row?.department ? (
              <span>
                {" "}
                {deptOptions.find((option) => option.value === row?.department)
                  ?.label || "NA"}
              </span>
            ) : (
              <span> General</span>
            )}
          </span>
        );
      },
    },
    {
      name: "Created",
      selector: (row) => {
        return (
          <div className="d-flex flex-column text-muted">
            <span
              className=" fw-bold"
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (userRole === "admin") {
                  navigate(`/user/${row?.created_by?.id}`);
                }
              }}
            >
              <strong>By:</strong>{" "}
              <span className="text-primary fw-bold">
                {row?.created_by?.name}{" "}
              </span>
            </span>

            <span>
              <strong>At:</strong> {row?.created_at}
            </span>
          </div>
        );
      },
    },
    {
      name: "File Source",
      selector: (row) => {
        return row?.is_outside ? (
          <span className="badge text-bg-danger-soft">External</span>
        ) : (
          <span className="badge text-bg-info-soft">Internal</span>
        );
      },
    },
    {
      name: "Priority Level",
      selector: (row) => {
        return row?.priority_level === "high" ? (
          <span className="badge text-bg-danger-soft">High</span>
        ) : row?.priority_level === "medium" ? (
          <span className="badge text-bg-warning-soft">Medium</span>
        ) : (
          <span className="badge text-bg-info-soft">Low</span>
        );
      },
    },
    {
      name: "Status",
      selector: (row) => {
        return row?.status === "created" ? (
          <span className={`badge bg-info`}>Created</span>
        ) : row?.status === "in_progress" ? (
          <span className={`badge bg-warning`}>In Progress</span>
        ) : row?.status === "approved" ? (
          <span className={`badge text-bg-success`}>Approved</span>
        ) : row?.status === "completed" ? (
          <span className={`badge text-bg-success-soft`}>Completed</span>
        ) : row?.status === "overdue" ? (
          <span className={`badge text-bg-danger`}>OverDue</span>
        ) : (
          <span className={`badge bg-primary`}>Reopened</span>
        );
      },
    },
  ];

  const viewChange = () => {
    if (window.innerWidth < 960) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  useEffect(() => {
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
    };
  }, []);

  return (
    <div>
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Files</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Files
                </li>
              </ol>
            </nav>
          </Block.HeadContent>

          {(userRole === "management" || userRole === "user") && (
            <Block.HeadContent>
              <ul className="d-flex">
                <li>
                  <Link
                    to="/add-file"
                    className="btn btn-primary btn-md d-md-none"
                  >
                    <Icon name="plus" />
                    <span>Add</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/add-file"
                    className="btn btn-primary d-none d-md-inline-flex"
                  >
                    <Icon name="plus" />
                    <span>Add File</span>
                  </Link>
                </li>
              </ul>
            </Block.HeadContent>
          )}
        </Block.HeadBetween>
      </Block.Head>
      <Block>
        <Card>
          <div className="data-table-wrapper">
            <div className="data-table-top">
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <DebounceSearchInput handleSearchChange={handleSearchChange} />
                <div className="mx-2">
                  <div className="d-flex align-items-center">
                    <button
                      className="btn btn-outline-light d-flex align-items-center mx-1 px-1"
                      onClick={handleToggleModal}
                    >
                      <Icon name="filter" className="fs-3 text-light"></Icon>
                      <span className="fw-semibold text-light mx-1">
                        Filters
                      </span>
                      {Object.keys(tempfilter).some(
                        (key) => tempfilter[key]
                      ) && (
                          <span className="badge bg-info-soft text-dark mx-2">
                            {
                              Object.keys(tempfilter).filter(
                                (key) => tempfilter[key]
                              ).length
                            }
                          </span>
                        )}
                    </button>

                    {Object.keys(tempfilter).some((key) => tempfilter[key]) && (
                      <button
                        className="btn btn-outline-danger btn-sm ms-1"
                        style={{ fontSize: '12px', padding: '2px 6px' }}
                        onClick={handleCancelfilter}
                      >
                        Clear
                      </button>

                    )}
                  </div>

                  <Modal
                    show={modalVisible}
                    onHide={handleToggleModal}
                    centered
                    className="custom-modal"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Filter Options</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="py-4">
                      <div className="filter-sections">
                        {headers.map(({ key, label, options }) => (
                          <div key={key} className="mb-1">
                            <h5 className="text-uppercase text-dark mb-1">
                              {label}
                            </h5>
                            <div className="row">
                              {options.map((option) => (
                                <div
                                  key={option.value}
                                  className="col-6 col-md-5 mb-1"
                                >
                                  <div
                                    className="form-check form-check-sm"
                                    style={{ fontSize: "0.8rem" }}
                                  >
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name={key}
                                      id={`${key}-${option.value}`}
                                      value={option.value}
                                      checked={tempfilter[key] === option.value}
                                      onChange={() =>
                                        handleSelectChange(key, option.value)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`${key}-${option.value}`}
                                      style={{ fontSize: "0.8rem" }}
                                    >
                                      {option.label}
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>

                      {/* Display Applied Filters */}
                      {Object.keys(tempfilter).some(
                        (key) => tempfilter[key]
                      ) && (
                          <div className="applied-filter mt-1">
                            <span className="fw-bold">
                              <hr />
                              Applied filter:{" "}
                              {Object.keys(tempfilter).some(
                                (key) => tempfilter[key]
                              ) && (
                                  <span className="badge bg-info-soft text-dark mx-2">
                                    {
                                      Object.keys(tempfilter).filter(
                                        (key) => tempfilter[key]
                                      ).length
                                    }
                                  </span>
                                )}
                              <div className="d-flex flex-wrap mt-2">
                                {headers.map(({ key, options }) =>
                                  tempfilter[key] ? (
                                    <span
                                      key={key}
                                      className="badge badge-pill badge-info mr-2 mb-2 d-inline-flex align-items-center"
                                      style={{
                                        marginRight: "8px",
                                        marginBottom: "8px",
                                      }}
                                    >
                                      <span className="text-dark">
                                        {
                                          options.find(
                                            (option) =>
                                              option.value === tempfilter[key]
                                          )?.label
                                        }
                                      </span>

                                      <button
                                        type="button"
                                        className="close text-danger bg-transparent border-0 rounded-circle"
                                        aria-label="Close"
                                        onClick={() => removeBadge(key)}
                                        style={{
                                          fontSize: "16px",
                                        }}
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </span>
                                  ) : null
                                )}
                              </div>
                            </span>
                          </div>
                        )}
                    </Modal.Body>

                    <Modal.Footer>
                      <Button
                        variant={
                          Object.values(tempfilter).some((value) => value)
                            ? "danger" 
                            : "secondary"
                        }
                        onClick={handleCancelfilter}
                      >
                        {Object.values(tempfilter).some((value) => value) ? "Clear All" : "Cancel"}
                      </Button>

                      <Button variant="primary" onClick={handleApplyfilter}>
                        Apply filter
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>

                {/* //handleDateRange] */}
                <div className="container ">
                  <DatePicker
                    onChange={handleDateChange}
                    autoHide={true}
                    todayBtn={true}
                    clearBtn={true}
                    format="yyyy/mm/dd"
                  />
                </div>
              </div>

              <div className="data-table-action-wrap">
                <div className="data-table-select">
                  <select
                    className="form-select"
                    onChange={(e) =>
                      handleRowsPerPageChange(Number(e.target.value))
                    }
                    value={showItemPerPage}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </select>
                  <span className="text">Per page</span>
                </div>
              </div>
            </div>

            <DataTable
              columns={columns}
              data={data}
              noDataComponent={
                <div className="p-2">There are no records found.</div>
              }
              sortIcon={<div className="data-table-sorter"></div>}
              pagination
              paginationPerPage={showItemPerPage}
              progressPending={isLoading}
              progressComponent={
                <div className="text-center my-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              }
              paginationComponent={(props) => (
                <div className="data-table-bottom">
                  <Pagination
                    currentPage={currentPage}
                    recordsPage={showItemPerPage}
                    dataLength={total}
                    handlePagination={handlePagination}
                  />
                </div>
              )}
            />
          </div>
        </Card>
      </Block>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userRole: state.auth.user?.role || "user",
});

export default connect(mapStateToProps)(FilePage);
