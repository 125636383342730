import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Card, Spinner } from "react-bootstrap";
import { Icon } from "../../../components";
import { getFileCollectOutCount } from "../../../services/DashboardService";
import Buttons from "../../Constant/Buttons";
import { Pie } from "react-chartjs-2";

const FileToCollect = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getFileCollectOutCount()
      .then((resp) => {
        const { to_collect } = resp.data || {};
        setData({
          to_collect: to_collect || { total: 0, by_priority: { high: 0, medium: 0, low: 0 } },
        });
      })
      .catch((error) => {
        console.error(error?.response?.data?.message || "Error fetching data");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const renderPieChart = (priorityData) => {
    const chartData = {
      labels: ["High", "Medium", "Low"],
      datasets: [
        {
          data: [priorityData?.high, priorityData?.medium, priorityData?.low],
          backgroundColor: ["#1E90FF", "#2E8B57", "#FFA500"],
          hoverBackgroundColor: ["#1E90FF", "#2E8B57", "#FFA500"],
        },
      ],
    };

    const options = {
      plugins: {
        legend: {
          display: true,
          position: "bottom",
          labels: {
            boxWidth: 15,
            boxHeight: 15,
            padding: 10,
            font: {
              size: 10,
            },
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
    };

    return <Pie data={chartData} options={options} />;
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" variant="primary" role="status" />
        <span className="ms-2">Loading...</span>
      </div>
    );
  }

  return (
        <Card>
          <Card.Body>
            <div className="card-title mb-4 align-items-start">
              <div className="card-title">
                <h4 className="title mb-0">Files To Collect</h4>
              </div>
            </div>
            <Row className="g-gs">
              <Col sm="6" md="4" lg="6" xl="6">
                <div className="box-dotted h-100">
                  <Icon size="lg" variant="primary" name="inbox-in-fill" />
                  <h5 className="title mt-2 mb-3">Files To Collect</h5>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="amount h3">{data?.to_collect?.total}</div>
                    <Buttons icon="arrow-right" url={"/file-action"} />
                  </div>
                  <div className="mt-3">
                    <p className="text-muted mb-1">High Priority: {data?.to_collect?.by_priority?.high}</p>
                    <p className="text-muted mb-1">Medium Priority: {data?.to_collect?.by_priority?.medium}</p>
                    <p className="text-muted">Low Priority: {data?.to_collect?.by_priority?.low}</p>
                  </div>
                </div>
              </Col>
              <Col sm="6" md="6" lg="6" xl="6">
                <div className="box-dotted h-100">{renderPieChart(data?.to_collect?.by_priority)}</div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
  );
};

export default FileToCollect;
