import React, { useState, useEffect, useCallback } from "react";
import { Card, Spinner } from "react-bootstrap";
import { getTopFiveDelayedFiles } from "../../../services/DashboardService";
import FilterDropdown from "../Filter";

const TopDelayedFiles = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState(""); 

  const fetchData = useCallback((filterOption) => {
    setIsLoading(true);
    getTopFiveDelayedFiles(filterOption)
      .then((resp) => {
        setData(resp.data.data);
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchData(filter);
  }, [filter]);

  const handleFilterChange = (selectedFilter) => {
    setFilter(selectedFilter);
  };

  return (
    <Card className="h-100 shadow-sm">
      <Card.Body>
      <div className="d-flex justify-content-between align-items-center mb-3">
          <h4>
          Top 5 Delayed Files
          </h4>
          <FilterDropdown filter={filter} onFilterChange={handleFilterChange} />
        </div>
        {isLoading ? (
          <div className="text-center my-4">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : data.length > 0 ? (
          <div className="table-responsive">
            <table className="table table-bordered text-center align-middle">
              <thead className="table-light">
                <tr>
                  <th>File No.</th>
                  <th>Current Handler</th>
                  <th>Pending Duration</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.file_no}</td>
                    <td>{item.current_handler}</td>
                    <td>{item.pending_duration}</td>
                    <td>
                      {item?.status === "created" ? (
                        <span className={`badge bg-info`}>Created</span>
                      ) : item?.status === "in_progress" ? (
                        <span className={`badge bg-warning`}>In Progress</span>
                      ) : item?.status === "approved" ? (
                        <span className={`badge text-bg-success`}>
                          Approved
                        </span>
                      ) : item?.status === "completed" ? (
                        <span className={`badge text-bg-success-soft`}>
                          Completed
                        </span>
                      ) : (
                        <span className={`badge bg-primary`}>Reopened</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="text-center text-muted">No delayed files found.</div>
        )}
      </Card.Body>
    </Card>
  );
};

export default TopDelayedFiles;
