import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Card } from "react-bootstrap";
import { getFileCategoryOverTime } from "../../../services/DashboardService";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const FileCategoryChart = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  const fetchData = async () => {
    try {
      const response = await getFileCategoryOverTime(); 
      const { data } = response.data;

      const labels = Object.keys(data);
      const newFiles = labels.map((month) => data[month].new_files);
      const completedFiles = labels.map((month) => data[month].completed_files);
      const escalatedFiles = labels.map((month) => data[month].escalated_files);

      setChartData({
        labels: labels,
        datasets: [
          {
            label: "New Files",
            data: newFiles,
            borderColor: "blue",
            backgroundColor: "rgba(0, 0, 255, 0.1)",
            tension: 0.3,
            pointRadius: 5,
            pointBackgroundColor: "blue",
            fill: false,
          },
          {
            label: "Completed Files",
            data: completedFiles,
            borderColor: "green",
            backgroundColor: "rgba(0, 255, 0, 0.1)",
            tension: 0.3,
            pointRadius: 5,
            pointBackgroundColor: "green",
            fill: false,
          },
          {
            label: "Escalated Files",
            data: escalatedFiles,
            borderColor: "red",
            backgroundColor: "rgba(255, 0, 0, 0.1)",
            tension: 0.3,
            pointRadius: 5,
            pointBackgroundColor: "red",
            fill: false,
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "File Categories Over Time",
        font: { size: 18 },
        padding: { top: 10, bottom: 30 },
      },
      legend: {
        display: true,
        position: "top",
        labels: { font: { size: 14 }, color: "black" },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: { display: true, text: "Number of Files" },
        ticks: { stepSize: 20 },
      },
      x: {
        title: { display: true, text: "Months" },
      },
    },
  };

  return (
    <Card className="h-100 shadow-sm">
      <Card.Body>
        {chartData.labels.length > 0 ? (
          <Line data={chartData} options={options} />
        ) : (
          <p>Loading chart data...</p>
        )}
      </Card.Body>
    </Card>
  );
};

export default FileCategoryChart;
