import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2/src/sweetalert2.js";
import { Form, Row, Col, Button } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { StaticSelectInput } from "../../../../Constant/SelectInput";
import {
  receiveFileDataApi,
  searchDarkUserNextOffficerApi,
} from "../../../../../services/FileService";
import { handleCompleteRecieveFileAction } from "../../../../../store/actions/FileActions";

function ReceiveFileInForm({data,barcodeNumber,onResetStep,toggleModal})
 {
  const [formData, setFormData] = useState({
    next_officer: "",
    comments: "",
  });
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [approveUserList, setApproveUserList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchApproveUserList = async () => {
      try {
        const response = await searchDarkUserNextOffficerApi(barcodeNumber);
        setApproveUserList(response.data?.data || []);
      } catch (error) {
        console.error("Error fetching user list:", error);
        toast.error("Failed to fetch next officer data.");
      }
    };

    if (barcodeNumber) {
      fetchApproveUserList();
    }
  }, [barcodeNumber]);

  useEffect(() => {
    if (data.status === "out") {
      setFormData((prev) => ({
        ...prev,
        next_officer: data.file?.next_officer?.id || "",
      }));
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleNextOfficerChange = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const alertSuccess = () => {
    Swal.fire({
      icon: "success",
      text: "The file has been successfully received!",
      timer: 3000,
      timerProgressBar: true,
    });
    toggleModal();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...formData,
      barcode_number: barcodeNumber,
    };

    try {
      setIsSubmitLoading(true);
      const response = await receiveFileDataApi(payload);
      setErrors({});
      alertSuccess();
      dispatch(handleCompleteRecieveFileAction(response.data?.data));
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to receive file.");
    } finally {
      setIsSubmitLoading(false);
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row className="g-gs mb-4">
          <Col lg="12">
            <Form.Group className="position-relative">
              <Form.Label htmlFor="barcode_number">Barcode Number</Form.Label>
              <Form.Control
                type="text"
                name="barcode_number"
                className="mb-1"
                id="barcode_number"
                value={barcodeNumber}
                disabled
              />
            </Form.Group>
            {data?.status === "dispatched" ? (
              <p className="text-bg-warning-soft text-dark rounded p-2 text-center mt-2">
                <strong>NOTE: </strong>This File neither IN nor OUT from the
                Department! <br />
                Please fill the information below to receive the file.
              </p>
            ) : data?.status === "in" ? (
              <p className="text-bg-warning-soft rounded p-2 text-dark text-center">
                This File hasn't left the Department! <br />
                Please fill the information below to receive the file.
              </p>
            ) : null}
          </Col>

          <Col lg="12">
            <StaticSelectInput
              setParentInputValue={handleNextOfficerChange}
              optionsList={approveUserList.map((item) => ({
                value: item.value,
                label: item.label,
              }))}
              name="next_officer"
              id="next_officer"
              placeholder="Select Next Officer"
              label="Next Officer"
              isRequired
              isDisabled={data.status === "out" && data.next_officer}
              defaultValue={
                data.status === "out"
                  ? {
                      label: data?.next_officer?.name,
                      value: data?.next_officer?.id,
                    }
                  : null
              }
            />
          </Col>

          <Col lg="12">
            <Form.Group>
              <Form.Label htmlFor="comments">
                Comments <span className="text-light">(Optional)</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                name="comments"
                placeholder="Leave your comments"
                value={formData.comments}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <div className="d-flex gap-3">
          <Button
            type="submit"
            variant="info"
            size="sm"
            disabled={isSubmitLoading}
          >
            {isSubmitLoading ? "Receiving..." : "Receive"}
          </Button>

          <Button
            type="button"
            variant="secondary"
            size="sm"
            onClick={onResetStep}
          >
            Reset
          </Button>
        </div>
      </Form>
    </>
  );
}

const mapStateToProps = (state) => ({
  userDepartment: state.auth.user?.department,
});

export default connect(mapStateToProps)(ReceiveFileInForm);
