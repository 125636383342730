import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { searchFileTypeApi, updateFileApi } from "../../services/FileService";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Block from "../../components/Block/Block";
import DynamicSelectInput, { StaticSelectInput } from "../Constant/SelectInput";
import { capitalizeWord } from "../../utilities";
function EditFilePage() {
  const [formData, setFormData] = useState({
    name: "",
    description:"",
    file_type_id: "",
    expected_complete_date: "",
    priority_level: "",
  });
  const navigate = useNavigate();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [fileOptionList, setFileOptionList] = useState([]);
  const location = useLocation();
  const data = location.state?.data || {};
  const { id } = useParams();

  const formatDate = (date) => {
    const [day, month, year] = date.split("-");
    return `${year}-${month}-${day}`;
  };  

  useEffect(() => {
    if (data) {
      setFormData({
        id: data.id,
        name: data.name,
        file_type_id: data.file_type?.id,
        description: data.description,
        priority_level: data.priority_level,
        expected_complete_date: formatDate(data.expected_complete_date) || "",
      });
    }
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const fileResponse = await searchFileTypeApi();
        setFileOptionList(fileResponse.data.results);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to fetch data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSelectChange = (name, value, fullData) => {
    if (name === "file_type_id") {
      const isExistingItem = fileOptionList.some(
        (option) => option.value === value
      );

      setFormData((prev) => ({
        ...prev,
        file_type_id: isExistingItem ? value : null,
        file_type: isExistingItem ? "" : value,
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = "File Name is required";
    }
    if (!formData.priority_level) {
      newErrors.priority_level = "Priority is required";
    }
    if (!formData.description) {
      newErrors.description = "Description is required";
    }
    if (!formData.file_type_id && !formData.file_type) {
      newErrors.file_type = "File Type is required";
    }
    if (!formData.expected_complete_date) {
      newErrors.expected_complete_date = "Expected Complete Date is required";
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = validateForm();
    if (!isValid) return;

    const payload = {
      ...formData,
      file_type_id: formData.file_type_id || "",
      file_type: formData.file_type || "",
    };

    try {
      setIsSubmitLoading(true);
      const response = await updateFileApi(payload);
      setErrors({});
      toast.success(response.data.message);
      navigate(`/file/${id}`);
    } catch (error) {
      toast.error(error.response?.data?.message);
    } finally {
      setIsSubmitLoading(false);
    }
  };

  return (
    <div>
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Edit File</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashbaord</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={`/file/${id}`}>File Details</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Edit File
                </li>
              </ol>
            </nav>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Form action="#" onSubmit={handleSubmit}>
          <Row className="g-gs">
            <Col xxl="12">
              <Card className="card-gutter-md">
                <Card.Body>
                  <Row className="g-gs mb-5">
                    <Col lg="6">
                      <Form.Group>
                        <Form.Label htmlFor="name">File Name</Form.Label>
                        <Form.Control
                          type="text"
                          id="name"
                          name="name"
                          placeholder="File Name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                        {errors.name && (
                            <div className="text-danger fs-6">
                              {errors.name}
                            </div>
                          )}
                      </Form.Group>
                    </Col>

                    <Col lg="6">
                    <Form.Group className="form-group">
                        <DynamicSelectInput
                          endpoint_name="search_file_type"
                          setParentInputValue={handleSelectChange}
                          name="file_type_id"
                          id="file_type_id"
                          isDisabled={!formData.is_new_file}
                          placeholder="Select File Type"
                          label="File Type"
                          labelHint={false}
                          setClickValue={{
                            label:data?.file_type.name,
                            value:data?.file_type.id
                          }}
                        />
                        {errors.file_type_id && (
                          <div className="text-danger fs-6">
                            {errors.file_type_id}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg="6">
                      <Form.Group>
                        <Form.Label htmlFor="file_number">File Number</Form.Label>
                        <Form.Control
                          type="text"
                          id="file_number"
                          name="file_number"
                          placeholder="File Number"
                          disabled
                          value={data.file_number}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg="6">
                      <Form.Group>
                        <Form.Label htmlFor="name">Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          id="description"
                          rows={1}
                          name="description"
                          placeholder="Enter your description"
                          value={formData.description}
                          onChange={handleChange}
                        />
                        {errors.description && (
                            <div className="text-danger fs-6">
                              {errors.description}
                            </div>
                          )}
                      </Form.Group>
                    </Col>

                    <Col lg="6">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="discount-price">
                          Expected Complete Date
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="date"
                            name="expected_complete_date"
                            value={formData.expected_complete_date}
                            onChange={handleChange}
                          />
                          {errors.expected_complete_date && (
                            <div className="text-danger fs-6">
                              {errors.expected_complete_date}
                            </div>
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="6">
                      <Form.Group className="form-group">
                        <StaticSelectInput
                          setParentInputValue={handleSelectChange}
                          optionsList={[
                            { label: "High", value: "high" },
                            { label: "Medium", value: "medium" },
                            { label: "Low", value: "low" },
                          ]}
                          name="priority_level"
                          id="priority_level"
                          isClearable
                          placeholder="Select Priority Level"
                          label="Priority Level"
                          isRequired={true}
                          setClickValue={{
                            label:capitalizeWord(data?.priority_level),
                            value:data?.priority_level
                          }}
                        />
                        {errors.priority_level && (
                            <div className="text-danger fs-6">
                              {errors.priority_level}
                            </div>
                          )}
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
                <Col xxl="12" className="d-flex gap-3 py-2 px-4">
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={isSubmitLoading}
                  >
                    {isSubmitLoading ? "Updating..." : "Update"}
                  </Button>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => navigate(`/file/${id}`)}
                  >
                    Cancel
                  </Button>
                </Col>
              </Card>
            </Col>
          </Row>
        </Form>
      </Block>
    </div>
  );
}

export default EditFilePage;

