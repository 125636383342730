import axiosInstance from './AxiosInstance';
const fileUrl = "api/file"

// file
export function getFileListApi(page, page_size, filter) {
    const params = new URLSearchParams({
        page,
        page_size,
        ...filter
    });
    return axiosInstance.get(`${fileUrl}?${params.toString()}`);
}

export function getFileApi(id) {
    return axiosInstance.get(`${fileUrl}/${id}`);
}

export function createFileApi(data) {
    return axiosInstance.post(fileUrl, data);
}

export function updateFileApi(data) {
    return axiosInstance.put(`${fileUrl}`, data);
}

export function sendFileApi(data) {
    return axiosInstance.post(`api/file_out`, data);
}

export function clarificationFileApi(data) {
    return axiosInstance.post(`api/file_clarification`, data);
}

export function escalationFileApi(data) {
    return axiosInstance.post(`api/file_escalation`, data);
}

export function collectFileApi(data) {
    return axiosInstance.post(`api/file_collect`, data);
}

export function approveFileApi(data) {
    return axiosInstance.post(`api/file_approval`, data);

}

export function getFileLogApi(id, page, page_size, filter) {
    const params = new URLSearchParams({
        page,
        page_size,
        ...filter
    });
    return axiosInstance.get(`api/file_tracking_log/${id}?${params.toString()}`);
}

export function getOverdueFilesApi(id, page, page_size) {
    return axiosInstance.get(`api/file_overdue_log/${id}?page=${page}&page_size=${page_size}`);
}

export function searchFileTypeApi() {
    return axiosInstance.get("api/search_file_type");
}

export function searchReopenFileApi() {
    return axiosInstance.get("api/search_reopen_file");
}

export function searchApproveUserApi() {
    return axiosInstance.get("api/search_approver");
}

export function searchMoveUserApi() {
    return axiosInstance.get("api/search_move_user");
}

export function searchDarkUserNextOffficerApi(barcode) {
    return axiosInstance.get(`api/search_daak_user_next_officer/${barcode}`);
}

export function searchDaakUserApi() {
    return axiosInstance.get("api/search_daak");
}

export function recieveFileApi(barcode_number) {
    return axiosInstance.get(`api/file_receive?barcode_number=${barcode_number}`);
}

export function receiveFileDataApi(data) {
    return axiosInstance.post(`api/file_receive`, data);
}


export function dispatchFileApi(data) {
    return axiosInstance.post(`api/file_dispatch`, data);
}


export function getNotificationListApi(page, page_size,) {
    const params = new URLSearchParams({page, page_size});
    return axiosInstance.get(`api/file_notification?${params.toString()}`);
}

export function updateNotificationStatusApi(notification_id) {
    return axiosInstance.put("api/file_notification", {notification_id});
}

export function getFileOverviewList(page, page_size, filter) {
    const params = new URLSearchParams({
        page,
        page_size,
        ...filter
    });
    return axiosInstance.get(`api/admin_file_detail_list?${params.toString()}`);
}























