import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Card } from "react-bootstrap";
import { getEscalationTrends, getFileVolumeTrends } from "../../../services/DashboardService";
import FilterDropdown from "../Filter";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const EscalationTrendsChart = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true); 
  const [filter, setFilter] = useState(""); 

  useEffect(() => {
    const fetchFileVolumeTrends = async (filterOption) => {
      try {
        const response = await getEscalationTrends(filterOption);
        if (response.data.success) {
          const trendData = response.data.data;
          const labels = Object.keys(trendData);
          const values = labels.map(week => trendData[week].escalations); 
          
          setData({
            labels: labels,
            datasets: [
              {
                label: "Escalations",
                data: values,
                borderColor: "red",
                backgroundColor: "red",
                tension: 0.1,
                fill: false,
              },
            ],
          });
        }
      } catch (error) {
        console.error("Error fetching escalation trends:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFileVolumeTrends(filter);
  }, [filter]);

  const handleFilterChange = (selectedFilter) => {
    setFilter(selectedFilter);
  };

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: "Escalation Trends",
        font: { size: 18 },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Weeks",
        },
      },
      y: {
        title: {
          display: true,
          text: "Number of Escalations",
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <Card className="h-100 shadow-sm">
      <Card.Body>
          <div className="d-flex justify-content-between align-items-center mb-3">
          <h4>
          Escalation Trends
          </h4>
          <FilterDropdown filter={filter} onFilterChange={handleFilterChange} />
        </div>
          {loading ? (
            <p>Loading...</p>
          ) : (
            data && <Line data={data} options={options} />
          )}
      </Card.Body>
    </Card>
  );
};

export default EscalationTrendsChart;
