import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Card } from "react-bootstrap";
import { getAverageFileClearanceTime } from "../../../services/DashboardService";
import FilterDropdown from "../Filter";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const AverageFileClearanceTime = () => {
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(""); 

  useEffect(() => {
    const fetchAverageClearanceTime = async (filterOption) => {
      try {
        const response = await getAverageFileClearanceTime(filterOption);
        if (response.data.success) {
          const labels = response.data.data.map((item) => item.department);
          const data = response.data.data.map(
            (item) => item.average_clearance_time_days
          );

          setChartData({
            labels, 
            datasets: [
              {
                label: "Average Clearance Time (Days)",
                data, 
                backgroundColor: "#5f38f9",
                borderColor: "#1976D2",
                borderWidth: 1,
                borderRadius: 8,
                barPercentage: 0.8,
              },
            ],
          });
        } else {
          console.log("Failed to fetch data");
        }
      } catch (err) {
        console.log(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAverageClearanceTime(filter);
  }, [filter]);

  const handleFilterChange = (selectedFilter) => {
    setFilter(selectedFilter);
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: false,
        labels: {
          font: {
            size: 14,
          },
          color: "#333",
        },
      },
      tooltip: {
        backgroundColor: "#000",
        titleColor: "#fff",
        bodyColor: "#fff",
        padding: 10,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#333",
          font: {
            size: 12,
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: "#e0e0e0",
        },
        ticks: {
          color: "#333",
          font: {
            size: 12,
          },
        },
        title: {
          display: true,
          text: "Average Clearance Time (Days)",
          color: "#333",
          font: {
            size: 14,
          },
        },
      },
    },
  };

  if (loading) return <div>Loading...</div>;

  return (
    <Card className="h-100 shadow-sm">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-5">
          <h4>
          Average File Clearance Time
          </h4>
          <FilterDropdown filter={filter} onFilterChange={handleFilterChange} />
        </div>
          {chartData && <Bar data={chartData} options={options} />}
      </Card.Body>
    </Card>
  );
};

export default AverageFileClearanceTime;
