

export const HANDLE_UPDATE_NEW_FILE_LOG = '[File Action] Update New Log';
export const GET_FILE_LOG_LIST = '[File Action] GET File Log List';
export const CLEAR_FILE_LOG_LIST = '[File Action] CLEAR File Log List';
export const TOGGLE_RECEIVE_MODAL = '[File Action] Handle Toggle Receive Modal';
export const TOGGLE_DISPATCH_MODAL = '[File Action] Handle Toggle Dispatch Modal';
export const HANDLE_SET_BARCODE = '[File Action] Handle Set Barcode Number';
export const HANDLE_SET_DISPATCH_BARCODE = '[File Action] Handle Set Dispatch Barcode Number';
export const HANDLE_SET_RECEIVE_LIST = '[File Action] Handle Set Recieve List';
export const HANDLE_SET_DISPATCH_LIST= '[File Action] Handle Set Dispatch List';
export const HANDLE_RECEIVE_COUNT= '[File Action] Handle Set Recieve  Count';
export const HANDLE_DISPATCH_COUNT= '[File Action] Handle Set  Dispatch Count';
export const HANDLE_COMPLETE_RECEIVE_FILE = '[File Action] Handle Complete Recieve File';
export const HANDLE_COMPLETE_DISPATCH_FILE = '[File Action] Handle Complete Dispatch File';
export const HANDLE_ADD_NEW_RECEIVE_FILE = '[File Action] Handle Add New Recieve File';
// export const HANDLE_SET_NOTIFICATION = '[File Action] Handle Set Notification';


export function handleUpdateNewFileLogAction(trackingData) {

    return {
        type: HANDLE_UPDATE_NEW_FILE_LOG,
        payload: trackingData,
    };
}


export function handleGetFileLogListAction(payload) {
    return {
        type: GET_FILE_LOG_LIST,
        payload,
    };
}

export function handleClearFileLogList() {
    return {
        type: CLEAR_FILE_LOG_LIST,
        payload : null,
    };
}

export function toggleReceiveModalAction() {
    return {
        type: TOGGLE_RECEIVE_MODAL,
        payload: null,
    };
}

export function toggleDispatchModalAction() {
    return {
        type: TOGGLE_DISPATCH_MODAL,
        payload: null,
    };
}

export function handleSetBarcodeNumberAction(barcode) {
    return {
        type: HANDLE_SET_BARCODE,
        payload: barcode,
    };
}
export function handleSetDispatchBarcodeNumberAction(barcode_number) {
    return {
        type: HANDLE_SET_DISPATCH_BARCODE,
        payload: barcode_number,
    };
}
export function handleSetReceiveListAction(payload) {
    return {
        type: HANDLE_SET_RECEIVE_LIST,
        payload,
    };
}
// export const handleSetNotificationAction = (newNotification) => ({
//     type: HANDLE_SET_NOTIFICATION,
//     payload: newNotification,
// });

export function handleReceiveCountAction(payload) {
    return {
        type: HANDLE_RECEIVE_COUNT,
        payload,
    };
}


export function handleCompleteRecieveFileAction(data) {

    return {
        type: HANDLE_COMPLETE_RECEIVE_FILE,
        payload: data,
    };
}
export function handleAddNewRecieveFileAction(data) {
    
    return {
        type: HANDLE_ADD_NEW_RECEIVE_FILE,
        payload: data,
    };
}

export function handleSetDispatchListAction(payload) {
    return {
        type: HANDLE_SET_DISPATCH_LIST,
        payload,
    };
}
export function handleDispatchCountAction(payload) {
    return {
        type: HANDLE_DISPATCH_COUNT,
        payload,
    };
}

export function handleCompleteDispatchFileAction(data) {

    return {
        type: HANDLE_COMPLETE_DISPATCH_FILE,
        payload: data,
    };
}

// export function handleUpdateNewDispatchListAction(data) {
    
//     return {
//         type: HANDLE_UPDATE_NEW_DISPATCH_LIST,
//         payload: data,
//     };
// }




