import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Button, Card, Spinner, Alert, Badge } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import Barcode from "react-barcode";
import Block from "../../components/Block/Block";
import { getFileApi } from "../../services/FileService";
import { Icon } from "../../components";
import MoveFileModal from "./Movement/MoveFile";
import CollectFile from "./Movement/CollectFile";
import Buttons from "../Constant/Buttons";
import FileLogList from "./FileLog";
import ApproveFileModal from "./Movement/Approve";
import Clarification from "./Movement/Clarification";
import Escalate from "./Movement/Escalate";
import { toast } from "react-toastify";
import { handleUpdateNewFileLogAction } from "../../store/actions/FileActions";
import OverdueFiles from "./OverdueFiles";

function FileDetails({ user }) {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState("");
  const [isOverdueVisible, setIsOverdueVisible] = useState(false);
  const barcodeRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getFileApi(id)
      .then((resp) => {
        setFile(resp.data.results);
      })
      .catch((error) => {
        console.error(
          "Error fetching file data:",
          error.response?.data?.message || error.message
        );
        if (error.response) {
          toast.error(error.response?.data?.message);
          navigate("/files");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleOnTrackingUpdate = (updatedFile) => {
    setFile((prev) => ({ ...updatedFile }));
    dispatch(handleUpdateNewFileLogAction(updatedFile.last_tracking));
  };

  const handleToggleOverdue = () => {
    setIsOverdueVisible((prevState) => !prevState);
  };

  const BarcodeSticker = ({ barcode }) => {
    return (
      <div className="sticker" id="barcode-sticker">
        <div className="barcode-container">
          <div
            className="barcode"
            style={{
              marignTop: "10px",
              marginBottom: "10px",
              pageBreakAfter: "always",
            }}
          >
            <Barcode value={barcode} height={35} width={1.2} />
          </div>
        </div>
      </div>
    );
  };

  function handleBarcodePrint() {
    const element = document.getElementById("barcode-sticker").innerHTML;
    const printWindow = window.open("", "", "height=800,width=800");
    printWindow.document.write(element);
    printWindow.document.close();
    const style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML =
      ".department{textAlign:center} @page { size: auto;  margin: 0mm; }";
    printWindow.document.body.appendChild(style);

    printWindow.document.body.setAttribute(
      "style",
      "display:flex; justify-content: center; align-items: flex-start;  height: auto;"
    );
    printWindow.print();
  }

  return (
    <div className="container-fluid">
      <Block.Head>
        <Block.HeadBetween className="">
          <Block.HeadContent>
            <div className="d-flex flex-column flex-md-row align-items-md-center">
              <div className="mt-3 mt-md-0 ms-md-3">
                <div className="d-flex align-items-center">
                  <div className="d-flex justify-content-center align-items-center">
                    <Buttons icon="arrow-left" url={"/files"} />
                    <h2 className="title mb-1 me-3">{file?.name}</h2>
                  </div>
                </div>

                <ul className="nk-list-option pt-1 ms-2">
                  <li>
                    <Icon name="file"></Icon>
                    <span className="small">{file?.file_number}</span>
                  </li>
                  <li>
                    <Icon name="check-circle-cut"></Icon>
                    <span className="small">
                      {file?.expected_complete_date}
                    </span>
                  </li>
                  <li>
                    {isOverdueVisible ? (
                      <Badge
                        bg="info"
                        onClick={handleToggleOverdue}
                        style={{ cursor: "pointer" }}
                      >
                        <Icon name="arrow-left"></Icon> Back
                      </Badge>
                    ) : (
                      file?.last_tracking && (
                        <Badge
                          bg="danger"
                          onClick={handleToggleOverdue}
                          style={{ cursor: "pointer" }}
                        >
                          <Icon name="clock"></Icon>OverDue Files
                        </Badge>
                      )
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </Block.HeadContent>

          <Block.HeadContent className={"flex-grow"}>
            <CollectFile
              file={file}
              user={user}
              handleAfterUpdate={handleOnTrackingUpdate}
            />
            <RenderNextAction
              file={file}
              user={user}
              handleOnTrackingUpdate={handleOnTrackingUpdate}
            />
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block className="mt-4">
        <Card className="card-gutter-md">
          <div className="card-row card-row-lg col-sep col-sep-lg">
            <div className="card-aside">
              <Card.Body>
                {isLoading ? (
                  <div className="text-center my-4">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  <>
                    <div className="bio-block">
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <h4 className="bio-block-title">Details</h4>
                        <ul className="gap g-2">
                          <li className="">
                            <Button
                              onClick={() => {
                                navigate(`/edit-file/${id}`, {
                                  state: { data: file },
                                });
                              }}
                              className="btn btn-soft btn-primary btn-sm"
                            >
                              <Icon name="edit"></Icon>
                              <span>Edit</span>
                            </Button>
                          </li>
                        </ul>
                      </div>
                      <ul className="list-group list-group-borderless small">
                        <li className="list-group-item">
                          <span className="title fw-medium w-40 d-inline-block">
                            File Number:
                          </span>
                          <span className="text">{file?.file_number}</span>
                        </li>

                        <li className="list-group-item">
                          <span className="title fw-medium w-40 d-inline-block">
                            Status:
                          </span>
                          <span className="text">
                            {file?.status === "created" ? (
                              <span className={`badge bg-info`}>Created</span>
                            ) : file?.status === "in_progress" ? (
                              <span className={`badge bg-warning`}>
                                In Progress
                              </span>
                            ) : file?.status === "approved" ? (
                              <span className={`badge text-bg-success-soft`}>
                                Approved
                              </span>
                            ) : file?.status === "completed" ? (
                              <span className={`badge bg-success`}>
                                Completed
                              </span>
                            ) : file?.status === "overdue" ? (
                              <span className={`badge text-bg-danger`}>
                                OverDue
                              </span>
                            ) : (
                              <span className={`badge bg-primary`}>
                                Reopened
                              </span>
                            )}
                          </span>
                        </li>

                        <li className="list-group-item">
                          <span className="title fw-medium w-40 d-inline-block">
                            File Type:
                          </span>
                          <span className="text">{file?.file_type?.name}</span>
                        </li>
                        <li className="list-group-item">
                          <span className="title fw-medium w-40 d-inline-block">
                            Priority Level:
                          </span>
                          <span className="text">
                            {file?.priority_level === "high" ? (
                              <span className="badge text-bg-danger-soft">
                                High
                              </span>
                            ) : file?.priority_level === "medium" ? (
                              <span className="badge text-bg-warning-soft">
                                Medium
                              </span>
                            ) : (
                              <span className="badge text-bg-info-soft">
                                Low
                              </span>
                            )}
                          </span>
                        </li>
                        <li className="list-group-item">
                          <span className="title fw-medium w-40 d-inline-block">
                            File Source:
                          </span>
                          <span className="text">
                            {file?.is_outside ? (
                              <>
                                <span className="badge text-bg-danger-soft me-1">
                                  External
                                </span>{" "}
                                {file?.file_source}
                              </>
                            ) : (
                              <span className="badge text-bg-info-soft">
                                Internal
                              </span>
                            )}
                          </span>
                        </li>
                        <li className="list-group-item">
                          <span className="title fw-medium w-40 d-inline-block">
                            Description:
                          </span>
                          <span className="text">{file?.description}</span>
                        </li>
                      </ul>
                      <Alert variant={"warning"} className="mt-2">
                        <div className="">
                          <h6 className="mb-1 d-flex align-items-center">
                            {file?.parent ? "Reopened" : "Created"} By:
                          </h6>
                          {file?.parent && (
                            <div className="card">
                              <div className="p-1 d-flex align-items-center">
                                <span>
                                  <Icon name={"file"} />
                                </span>

                                <Link
                                  to={`/file/${file.parent.id}`}
                                  className="ms-2 cursor-pointer"
                                >
                                  <strong className="text-primary">
                                    {file.parent.file_number}
                                  </strong>
                                </Link>
                              </div>
                            </div>
                          )}
                          <p className="text-muted smaller mb-1">
                            <Icon
                              name="user-fill"
                              className="me-2 text-secondary"
                            />
                            {file?.created_by?.name}
                          </p>
                          <p className="text-muted smaller mb-1">
                            <Icon
                              name="call-fill"
                              className="me-2 text-secondary"
                            />
                            {file?.created_by?.phone_number}
                          </p>

                          <p className="text-muted smaller mb-1">
                            <Icon
                              name="building"
                              className="me-2 text-secondary"
                            />
                            {file?.created_by?.department}
                          </p>
                          <p className="text-muted smaller mb-1">
                            <Icon
                              name="clock"
                              className="me-2 text-secondary"
                            />
                            {file?.created_by?.created_at}
                          </p>
                        </div>
                      </Alert>
                      <Alert variant={"primary"} className="mt-2">
                        <div
                          className="d-flex justify-content-between align-items-center mb-2"
                          ref={barcodeRef}
                        >
                          <BarcodeSticker barcode={file?.barcode_number || "00000"} />
                          <button
                            className="btn btn-info btn-sm"
                            onClick={handleBarcodePrint}
                          >
                            Print
                          </button>
                        </div>
                      </Alert>
                    </div>
                  </>
                )}
              </Card.Body>
            </div>
            <div className="card-content col-sep flex-fill">
              {isOverdueVisible ? (
                <OverdueFiles />
              ) : (
                <FileLogList file={file} />
              )}
            </div>
          </div>
        </Card>
      </Block>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user || null,
});
export default connect(mapStateToProps)(FileDetails);

const RenderNextAction = ({ file, user, handleOnTrackingUpdate }) => {
  if (!file) return null;

  // Map of action components
  const actions = {
    escalation: (data) => (
      <Escalate data={data} handleAfterUpdate={handleOnTrackingUpdate} />
    ),
    approval: (data) => (
      <ApproveFileModal
        data={data}
        handleAfterUpdate={handleOnTrackingUpdate}
      />
    ),
    clarification: (data) => (
      <Clarification data={data} handleAfterUpdate={handleOnTrackingUpdate} />
    ),
    move: (data) => (
      <MoveFileModal data={data} handleAfterUpdate={handleOnTrackingUpdate} />
    ),
  };

  // Function to compute action items
  const computeActionItems = () => {
    if (file.status === "completed") return [];

    if (!file.last_tracking) {
      return user?.id === file?.created_by?.id ? ["move"] : [];
    }

    const { status, receiver, action } = file.last_tracking;

    if (status === "in" && user?.id === receiver?.id) {
      switch (action) {
        case "move":
          return ["move", "clarification", "escalation", "approval"];
        case "clarification":
          return ["move", "escalation", "approval"];
        case "escalation":
          return ["move", "clarification", "approval"];
        default:
          return ["move"];
      }
    }

    return [];
  };

  // Get action keys and map them to components
  const actionKeys = computeActionItems();
  const actionItems = actionKeys.map((key) => actions[key](file));

  return actionItems.length > 0 ? (
    <Alert variant="secondary">
      <div className="flex">
        <span className="me-1">Next Action</span>
        {actionItems.map((modal, index) => (
          <span key={index} className="mx-1">
            {modal}
          </span>
        ))}
      </div>
    </Alert>
  ) : null;
};
