import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Card } from "react-bootstrap";
import { getUserAlertFileStatus } from "../../../services/DashboardService";
import FilterDropdown from "../Filter";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const FileStatus = () => {
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState(""); 

  useEffect(() => {
    const fetchAverageClearanceTime = async (filterOption) => {
      try {
        const response = await getUserAlertFileStatus(filterOption);
        if (response.data) {
          // Extracting data
          const { pending, amber_alerts, red_alerts } = response.data.data;

          // Setting chart data dynamically
          setChartData({
            labels: ["Pending", "Amber Alerts", "Red Alerts"], // Labels for the bars
            datasets: [
              {
                label: "File Status Counts",
                data: [pending, amber_alerts, red_alerts],
                backgroundColor: ["yellow", "#f2bc16", "red"], // Colors for the bars
                borderColor: ["#CCCC00", "#FF8C00", "#B22222"], // Border colors
                borderWidth: 1,
                borderRadius: 5, // Rounded corners for bars
                barPercentage: 0.8, // Adjust the width of bars
              },
            ],
          });
        } else {
          setError("Failed to fetch data");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAverageClearanceTime(filter);
  }, [filter]);

  const handleFilterChange = (selectedFilter) => {
    setFilter(selectedFilter);
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
        labels: {
          font: {
            size: 14,
          },
          color: "#333",
        },
      },
      tooltip: {
        backgroundColor: "#000",
        titleColor: "#fff",
        bodyColor: "#fff",
        padding: 10,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#333",
          font: {
            size: 12,
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: "#e0e0e0",
        },
        ticks: {
          color: "#333",
          font: {
            size: 12,
          },
        },
        title: {
          display: true,
          text: "File Status",
          color: "#333",
          font: {
            size: 14,
          },
        },
      },
    },
  };

  if (loading) return <div>Loading...</div>;

  return (
    <Card className="h-100 shadow-sm">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h4>
          File Status Count
          </h4>
          <FilterDropdown filter={filter} onFilterChange={handleFilterChange} />
        </div>
          {chartData && <Bar data={chartData} options={options} />}
      </Card.Body>
    </Card>
  );
};

export default FileStatus;

