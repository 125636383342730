import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Card } from "react-bootstrap";
import { getFileVolumeTrends } from "../../../services/DashboardService";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const FileVolumeTrendsChart = () => {
  const [data, setData] = useState(null); 
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const fetchFileVolumeTrends = async () => {
      try {
        const response = await getFileVolumeTrends();
        if (response.data.success) {
          const trendData = response.data.data;
          const labels = Object.keys(trendData); 
          const values = labels.map(month => trendData[month].file_counts); 

          setData({
            labels: labels,
            datasets: [
              {
                label: "Files Volume",
                data: values,
                borderColor: "blue",
                backgroundColor: "blue",
                tension: 0.1,
                fill: false,
              },
            ],
          });
        }
      } catch (error) {
        console.error("Error fetching file volume trends:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFileVolumeTrends();
  }, []);

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "File Volume Trends",
        font: { size: 18 },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Months",
        },
      },
      y: {
        title: {
          display: true,
          text: "Number of Files Processed",
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <Card className="h-100 shadow-sm">
      <Card.Body>
        <div>
          {/* <h3 style={{ fontFamily: "Arial, sans-serif", color: "#333" }}>
            File Volume Trends
          </h3> */}
          {loading ? (
            <p>Loading...</p>
          ) : (
            data && <Line data={data} options={options} />
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default FileVolumeTrendsChart;
