import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { Card, Spinner, Alert } from "react-bootstrap";
import { getTopEscalatingDepartment } from "../../../services/DashboardService";
import FilterDropdown from "../Filter";

Chart.register(...registerables);

const TopEscalatingDepartmentsChart = () => {
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("");

  const fetchTopEscalatingDepartments = async (filterOption) => {
    try {
      const response = await getTopEscalatingDepartment(filterOption);
      const { data } = response.data;

      const labels = data.map((item) => item.department);
      const escalations = data.map((item) => item.escalations);

      setChartData({
        labels: labels,
        datasets: [
          {
            label: "Number of Escalations",
            data: escalations,
            backgroundColor: "#f05404",
            borderColor: "red",
            borderWidth: 1,
          },
        ],
      });
    } catch (err) {
      console.log("Failed to fetch top escalating departments.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTopEscalatingDepartments(filter);
  }, [filter]);

  const handleFilterChange = (selectedFilter) => {
    setFilter(selectedFilter);
  };

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Top Escalating Departments",
        font: {
          size: 16,
          weight: "bold",
        },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Departments",
        },
      },
      y: {
        title: {
          display: true,
          text: "Number of Escalations",
        },
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  return (
    <Card className="h-100 shadow-sm">
      <Card.Body>
        <div className="d-flex justify-content-end align-items-center mb-3">
          <FilterDropdown filter={filter} onFilterChange={handleFilterChange} />
        </div>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <Bar data={chartData} options={options} />
        )}
      </Card.Body>
    </Card>
  );
};

export default TopEscalatingDepartmentsChart;
