import React, { useState, useCallback, useEffect } from "react";
import { Colors } from "../../../utilities/index";
import { Card, Spinner } from "react-bootstrap";
import { Icon, Pureknob } from "../../../components";
import { getReceiveDispatchCount } from "../../../services/DashboardService";
import { connect, useDispatch } from "react-redux";
import { handleDispatchCountAction } from "../../../store/actions/FileActions";

const DispatchPureknob = ({ dispatchCount}) => {
  return (<Pureknob
    data={{
      size: 136,
      value: dispatchCount,
      angleOffset: -0.5,
      angleStart: 0.7,
      angleEnd: 0.7,
      colorFg: Colors.white,
      trackWidth: "0.15",
    }}
    className="nk-chart-project-done text-white"
  >
    {" "}
    <span className="knob-title small text-white">To Receive</span>
  </Pureknob>)
}


const DispatchFileCount = ({ dispatchCount }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getReceiveDispatchCount()
      .then((resp) => {
        const dispatchCount = Number(resp.data?.to_dispatch) || 0;
        dispatch(handleDispatchCountAction({ dispatchCount }));
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Card className="h-100 bg-success">
        <Card.Body>
          <div className="d-flex flex-column flex-sm-row-reverse align-items-sm-center justify-content-sm-between gx-xl-5">
            {isLoading ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <>
                <DispatchPureknob dispatchCount={dispatchCount} />
                <div className="card-title mb-0 mt-4 mt-sm-0">
                  <h5 className="title mb-3 mb-xl-5 text-white">Dispatch Files</h5>
                  <div className="amount h2 text-white">
                    <Icon name="file-fill"></Icon> {dispatchCount} files to Dispatch
                  </div>
                </div>
              </>
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  dispatchCount: state.file.dispatchCount || 0,
});
export default connect(mapStateToProps)(DispatchFileCount);
