import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MediaGroup, MediaText } from "../../components";
import DataTable from "react-data-table-component";
import DataTablePagination from "../../components/Pagination/DataTablePagination";
import { getFileToOut } from "../../services/FileActionService";

function FileToOut() {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [showItemPerPage, setShowItemPerPage] = useState(rowsPerPage);
  const [mobileView, setMobileView] = useState(false);
  const navigate = useNavigate();

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getFileToOut()
      .then((resp) => {
        setData(resp.data.data);
        setTotal(resp.data.total);
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setShowItemPerPage(newRowsPerPage);
  };

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentPageData = data.slice(startIndex, endIndex);

  const columns = [
    {
      name: "File Details",
      selector: (row) => {
        return (
          <MediaGroup>
            <MediaText>
              <Link to={`/file/${row?.id}`} className="title">
                <strong>{row?.name}</strong>{" "}
              </Link>
              <span
                className="text-primary fw-bold"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/file/${row?.id}`)}
              >
                {row?.file_number}
              </span>
            </MediaText>
          </MediaGroup>
        );
      },
    },
    {
      name: "Status",
      selector: (row) => {
        return (
          row?.status === "in" && <span className={`badge text-bg-info-soft`}>In</span>
        );
      },
    },
  ];

  const viewChange = () => {
    if (window.innerWidth < 960) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  useEffect(() => {
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
    };
  }, []);

  return (
    <DataTable
      columns={columns}
      data={currentPageData}
      noDataComponent={<div className="p-2">There are no records found.</div>}
      sortIcon={<div className="data-table-sorter"></div>}
      pagination
      progressPending={isLoading}
      progressComponent={
        <div className="text-center my-4">
          <Spinner animation="border" variant="primary" />
        </div>
      }
      paginationComponent={(props) => (
        <div className="data-table-bottom">
          <DataTablePagination
            showItemPerPage={showItemPerPage}
            itemPerPage={rowsPerPage}
            totalItems={data.length}
            paginate={handlePageChange}
            currentPage={currentPage}
            onChangeRowsPerPage={handleRowsPerPageChange}
            setShowItemPerPage={setShowItemPerPage}
          />
        </div>
      )}
    />
  );
}

const mapStateToProps = (state) => ({
  userRole: state.auth.user?.role || "user",
});

export default connect(mapStateToProps)(FileToOut);
