import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  Table,
  Spinner,
} from "react-bootstrap";
import {
  Media,
  MediaGroup,
  MediaText,
  OverlineTitle,
  Icon,
} from "../../../components";
import { getDispatchfileList } from "../../../services/DashboardService";
import { handleSetDispatchBarcodeNumberAction, handleSetDispatchListAction, toggleDispatchModalAction } from "../../../store/actions/FileActions";
import Barcode from "react-barcode";
import { connect, useDispatch } from "react-redux";

const DispatchFileList = ({ data, total }) => {

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch()

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getDispatchfileList()
      .then((resp) => {
        const { data, total } = resp.data;
        dispatch(handleSetDispatchListAction({ data, total }));
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleOpenDispatchModal = (barcode_number) => {
    dispatch(toggleDispatchModalAction())
    dispatch(handleSetDispatchBarcodeNumberAction(barcode_number))
  }
  return (
    <>
      <Card className="h-100">
        <Card.Body className="py-2 flex-grow-0">
          <div className="card-title-group">
            <div className="card-title">
              <h5 className="title">Dispatch File List</h5>
            </div>
          </div>
        </Card.Body>
        <Table responsive className="table-middle mb-0">
          <thead className="table-light table-head-sm">
            <tr>
              <th className="tb-col">
                <OverlineTitle tag="span">Files</OverlineTitle>
              </th>
              <th className="tb-col tb-col-end tb-col-sm">
                <OverlineTitle tag="span">Barcode</OverlineTitle>
              </th>
              <th className="tb-col tb-col-end">
                <OverlineTitle tag="span">Dispatch To</OverlineTitle>
              </th>
              <th className="tb-col tb-col-end">
                <OverlineTitle tag="span">Created Date</OverlineTitle>
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="4" className="text-center py-4">
                  <div>Loading...</div>
                </td>
              </tr>
            ) : data.length === 0 ? (
              <tr>
                <td colSpan="4" className="text-center py-4">
                  <div className="text-muted">
                    <Icon name="inbox" size="lg" className="mb-2" />
                    <p className="mb-0">No Dispatch files available</p>
                  </div>
                </td>
              </tr>
            ) : (
              data.map((item, index) => (
                <tr key={index}>
                  <td className="tb-col">
                    <MediaGroup>

                      <MediaText className="fw-bold truncate-text">
                        {item?.name}
                        <span className="text smaller">{item?.file_number}</span>
                      </MediaText>
                    </MediaGroup>
                  </td>
                  <td className="tb-col tb-col-end tb-col-sm">
                    <div className='barcode cursor-pointer' onClick={() => handleOpenDispatchModal(item?.barcode_number)}>
                      <Barcode value={item?.barcode_number || "000000"} height={20} width={1.2} />
                    </div>
                  </td>
                  <td className="tb-col tb-col-end">
                  <div className="title fs-6">
                  <strong>{item?.dispatch_to?.name}</strong>{" "}
                </div>
                <span className="fs-6 text-mute">
                  <Icon name="building" className="me-2" />{item?.dispatch_to?.department}
                </span>
                  </td>
                  <td className="tb-col tb-col-end">
                    <span className="small">{item?.created_at}</span>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.file.dispatchFileList || [],
  total: state.file.dispatchTotal || [],
});
export default connect(mapStateToProps)(DispatchFileList);
