import { useState } from "react";
import classNames from "classnames";
import { Dropdown } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { toInitials } from "../../../utilities";
import {
  Image,
  Icon,
  MediaGroup,
  MediaText,
  Media,
  LinkList,
  LinkListItem,
  CustomDropdownToggle,
  CustomDropdownMenu,
  Schedule,
} from "../../../components";
import ToggleSidebar from "../Toggle/Sidebar";
import LogoutPage from "./Logout";
import Notification from "./Notification";
import { useLayout, useLayoutUpdate } from "./../LayoutProvider";
import ReceiveFile from "../../../pages/File/Movement/Global/ReceiveFile/ReceiveFile";
import DispatchFile from "../../../pages/File/Movement/Global/DispatchFile/DispatchFile";
import { enableDisableWPEmailNotification } from "../../../services/AuthService";
import { toggleEmailNotification, toggleWhatsAppNotification } from "../../../store/actions/AuthActions";

function QuickNav({ className, ...props }) {
  const compClass = classNames({
    "nk-quick-nav": true,
    [className]: className,
  });
  return <ul className={compClass}>{props.children}</ul>;
}

function QuickNavItem({ className, ...props }) {
  const compClass = classNames({
    "d-inline-flex": true,
    [className]: className,
  });
  return <li className={compClass}>{props.children}</li>;
}

function Header({ user, userRole, allowEmail, allowWhatsApp }) {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const layout = useLayout();
  const layoutUpdate = useLayoutUpdate();

  const handleNotificationChange = async (type, value) => {
    const payload = {[type]:value};

    try {
      await enableDisableWPEmailNotification(payload);
      if (type === "is_allow_email_notification") {
        dispatch(toggleEmailNotification(value));
      }
      if (type === "is_allow_whatsapp_notification") {
        dispatch(toggleWhatsAppNotification(value));
      }
    } catch (error) {
      console.error(error.response?.data?.message);
      toast.error(error.response?.data?.message);
    }
  };

  const compClass = classNames({
    "nk-header nk-header-fixed": true,
    [`is-${layout.headerVariant}`]: layout.headerVariant,
  });
  const handleClick = () => {
    setShow(false);
  };

  return (
    <>
      <div className={compClass}>
        <div className="container-fluid">
          <div className="nk-header-wrap">
            <div className="nk-header-logo">
              <ToggleSidebar variant="zoom" icon="menu" />
            </div>
            {layout.headerActive && (
              <div
                className="navbar-overlay"
                onClick={layoutUpdate.headerMobile}
              ></div>
            )}

            <div></div>
            <div>
              {user?.role === "daak" && (
                <div>
                  <ReceiveFile />
                  <span className="mx-3"></span>
                  <DispatchFile />
                </div>
              )}
            </div>
            <div className="nk-header-tools">
              <QuickNav>
                <QuickNavItem>
                  <Notification userRole={user?.role} />
                </QuickNavItem>

                <Dropdown
                  as={QuickNavItem}
                  show={show}
                  onToggle={(isOpen) => setShow(isOpen)}
                >
                  <Dropdown.Toggle bsPrefix as={CustomDropdownToggle}>
                    <div className="d-inline-flex">
                      <MediaGroup>
                        <Media size="md" shape="circle" variant="dark-soft">
                          {user?.profile_photo ? (
                            <Image
                              src={`${process.env.REACT_APP_BASE_URL}${user?.profile_photo}`}
                              staticImage
                              alt="user"
                            />
                          ) : (
                            <span className="fw-medium">
                              {toInitials(user?.name)}
                            </span>
                          )}
                        </Media>
                        <MediaText>
                          <div className="lead-text text-uppercase">
                            {user?.name}
                          </div>
                          <span className="sub-text text-mute">
                            {user?.email}
                          </span>
                        </MediaText>
                      </MediaGroup>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="dropdown-menu-md"
                    as={CustomDropdownMenu}
                  >
                    <div className="dropdown-content dropdown-content-x-md py-1 border-bottom border-light">
                      <MediaGroup>
                        <Media size="lg" shape="circle" variant="dark-soft">
                          {user?.profile_photo ? (
                            <Image
                              src={`${process.env.REACT_APP_BASE_URL}${user?.profile_photo}`}
                              staticImage
                              alt="user"
                            />
                          ) : (
                            <span className="fw-medium">
                              {toInitials(user?.name)}
                            </span>
                          )}
                        </Media>

                        <MediaText>
                          <div className="lead-text text-uppercase">
                            {user?.name}
                          </div>
                          <span className="sub-text text-uppercase">
                            {user?.role}
                          </span>
                        </MediaText>
                      </MediaGroup>
                    </div>
                    <div
                      className="dropdown-content dropdown-content-x-lg py-1 border-bottom border-light"
                      onClick={handleClick}
                    >
                      <LinkList>
                        <LinkListItem to="/profile">
                          <Icon name="user"></Icon>
                          <span>My Profile</span>
                        </LinkListItem>
                        <LinkListItem to="/account-settings">
                          <Icon name="setting-alt"></Icon>
                          <span>Account Settings</span>
                        </LinkListItem>
                        {userRole === "admin" && (
                          <>
                            <li className="d-flex align-items-center justify-content-between">
                              <a href={"#"}>
                                <div className="d-flex align-items-center">
                                  <Icon name="mail"></Icon>
                                  <span>{allowEmail ? "Disable" : "Enable"} Mail</span>
                                </div>
                              </a>
                              <div className="form-check form-switch d-flex justify-content-end">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="emailNotification"
                                  checked={allowEmail}
                                  onChange={(e) =>
                                    handleNotificationChange(
                                      "is_allow_email_notification",
                                      e.target.checked
                                    )
                                  }
                                />
                              </div>
                            </li>
                            <li className="d-flex align-items-center justify-content-between">
                              <a href={"#"}>
                                <div className="d-flex align-items-center">
                                  <Icon name="whatsapp"></Icon>
                                  <span>{allowWhatsApp ? "Disable" : "Enable"} WhatsApp</span>
                                </div>
                              </a>
                              <div className="form-check form-switch d-flex justify-content-end">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="whatsappNotification"
                                  checked={allowWhatsApp}
                                  onChange={(e) =>
                                    handleNotificationChange(
                                      "is_allow_whatsapp_notification",
                                      e.target.checked
                                    )
                                  }
                                />
                              </div>
                            </li>
                          </>
                        )}
                      </LinkList>
                    </div>
                    <div
                      className="dropdown-content dropdown-content-x-lg py-0"
                      onClick={handleClick}
                    >
                      <LogoutPage />
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </QuickNav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user || null,
  userRole: state.auth.user?.role || "user",
  allowEmail: state.auth.user?.is_allow_email_notification || false,
  allowWhatsApp:state.auth.user?.is_allow_whatsapp_notification || false,
});

export default connect(mapStateToProps)(Header);
