import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Card } from "react-bootstrap";
import { getDelayedFilesByDept } from "../../../services/DashboardService";
import FilterDropdown from "../Filter";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const FileDelayedTime = () => {
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(""); 

  useEffect(() => {
    const fetchAverageClearanceTime = async (filterOption) => {
      try {
        const response = await getDelayedFilesByDept(filterOption);
        if (response.data.success) {
          const labels = response.data.data.map((item) => item.department);
          const data = response.data.data.map(
            (item) => item.delayed_files_count
          );

          setChartData({
            labels,
            datasets: [
              {
                label: "Delayed Time By Department",
                data, 
                backgroundColor: "#f2bc16",
                borderColor: "#f2bc16",
                borderWidth: 1,
                borderRadius: 8,
                barPercentage: 0.8,
              },
            ],
          });
        } else {
          console.log("Failed to fetch data");
        }
      } catch (err) {
        console.log(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAverageClearanceTime(filter);
  }, [filter]);

  const handleFilterChange = (selectedFilter) => {
    setFilter(selectedFilter);
  };

  // Chart options for styling
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: false,
        labels: {
          font: {
            size: 14,
          },
          color: "#333", // Legend text color
        },
      },
      tooltip: {
        backgroundColor: "#000",
        titleColor: "#fff",
        bodyColor: "#fff",
        padding: 10,
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide grid lines on x-axis
        },
        ticks: {
          color: "#333", // X-axis label color
          font: {
            size: 12,
          },
        },
      },
      y: {
        beginAtZero: true, // Start y-axis at zero
        grid: {
          color: "#e0e0e0", // Grid line color
        },
        ticks: {
          color: "#333", // Y-axis label color
          font: {
            size: 12,
          },
        },
        title: {
          display: true,
          text: "Average Delayed Time (Days)", // Y-axis title
          color: "#333",
          font: {
            size: 14,
          },
        },
      },
    },
  };

  if (loading) return <div>Loading...</div>;

  return (
    <Card className="h-100 shadow-sm">
        <Card.Body>
    <div className="d-flex justify-content-between align-items-center mb-3">
          <h4>
          File Delayed Time by Department
          </h4>
          <FilterDropdown filter={filter} onFilterChange={handleFilterChange} />
        </div>
      <Bar data={chartData} options={options} />
    </Card.Body>
    </Card>
  );
};

export default FileDelayedTime;
