import {
  LOADING_TOGGLE_ACTION,
  LOGIN_CONFIRMED_ACTION,
  LOGIN_FAILED_ACTION,
  LOGOUT_ACTION,
  UPDATE_PROFILE,
  AUTH_CHECK_CONFIRMED_ACTION,
  NAVTOGGLE,
  TOGGLE_EMAIL_NOTIFICATION,
  TOGGLE_WHATSAPP_NOTIFICATION,
} from "../actions/AuthActions";
import { toast } from "react-toastify";

const initialState = {
  user: null,
  isCheckingAuth: true,
  errorMessage: "",
  successMessage: "",
  showLoading: false,
};

export function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_CONFIRMED_ACTION:
      return {
        ...state,
        user: action.payload,
        isCheckingAuth: false,
        showLoading: false,
      };
    case AUTH_CHECK_CONFIRMED_ACTION:
      return {
        ...state,
        isCheckingAuth: action.payload,
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
        showLoading: false,
      };
    case LOGOUT_ACTION:
      return {
        ...state,
        isCheckingAuth: false,
        user: null,
      };
    case LOGIN_FAILED_ACTION:
      toast.error(action.payload, { position: "top-left" });
      return {
        ...state,
        showLoading: false,
      };
    case LOADING_TOGGLE_ACTION:
      return {
        ...state,
        showLoading: action.payload,
      };
    case TOGGLE_EMAIL_NOTIFICATION:
      return {
        ...state,
        user: {
          ...state.user,
          is_allow_email_notification: action.payload,
        },
      };
    case TOGGLE_WHATSAPP_NOTIFICATION:
      return {
        ...state,
        user: {
          ...state.user,
          is_allow_whatsapp_notification: action.payload,
        },
      };
    default:
      return state;
  }
}

const initial = false;
export function toggleMenu(state = initial, action) {
  if (action.type === NAVTOGGLE) {
    return !state;
  }
  return state;
}
