import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Card, Spinner } from "react-bootstrap";
import { getFileActivityDistribution } from "../../../services/DashboardService";
import FilterDropdown from "../Filter";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const FileActivityChart = () => {
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState(""); 

  useEffect(() => {
    const fetchData = async (filterOption) => {
      try {
        setLoading(true);
        const response = await getFileActivityDistribution(filterOption);
        if (response.data.success) {
          const fetchedData = response.data.data;

          const labels = Object.keys(fetchedData); 
          const values = Object.values(fetchedData);

          setChartData({
            labels,
            datasets: [
              {
                label: "Activity Count",
                data: values,
                backgroundColor: "rgba(0, 123, 255, 0.8)",
                borderColor: "rgba(0, 123, 255, 1)",
                borderWidth: 1,
              },
            ],
          });
        } else {
          throw new Error(response.data.message);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData(filter);
  }, [filter]);

  const handleFilterChange = (selectedFilter) => {
    setFilter(selectedFilter);
  };

  const options = {
    indexAxis: "y", 
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "File Activity Distribution",
        font: {
          size: 16,
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Activity Count",
          font: {
            size: 12,
          },
        },
      },
      y: {
        title: {
          display: true,
          text: "Departments",
          font: {
            size: 12,
          },
        },
      },
    },
  };

  return (
    <Card className="h-100 shadow-sm">
      <Card.Body>
      <div className="d-flex justify-content-end align-items-center mb-3">
          <FilterDropdown filter={filter} onFilterChange={handleFilterChange} />
        </div>
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" variant="primary" />
            <p>Loading...</p>
          </div>
        ) : error ? (
          <div className="text-center text-danger">
            <p>Error: {error}</p>
          </div>
        ) : (
          <Bar data={chartData} options={options} />
        )}
      </Card.Body>
    </Card>
  );
};

export default FileActivityChart;
