import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import {
  createFileApi,
  searchReopenFileApi,
} from "../../services/FileService";
import { Card, Form, Row, Col, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Block from "../../components/Block/Block";
import DynamicSelectInput, {
  StaticSelectInput,
} from "../Constant/SelectInput";
import Barcode from "react-barcode";

const priorityLevelOption = [
  { label: "High", value: "high" },
  { label: "Medium", value: "medium" },
  { label: "Low", value: "low" },
]

function AddFilePage() {
  const [formData, setFormData] = useState({
    name: "",
    file_number: "",
    description: "",
    file_type_id: "",
    expected_complete_date: "",
    external_file_source: "",
    is_internal: true,
    is_new_file: true,
    priority_level: "",
    reopen_file_id: "",
  });
  const navigate = useNavigate();
  const barcodeRef = useRef()

  const [isBarcodeModalShow, setIsBarcodeModalShow] = useState(false);
  const [barcodeData, setBarcodeData] = useState(null);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [reopenFileLoading, setReopenFileLoading] = useState(false);
  const [reopenfileList, setReopenFileList] = useState([]);
  const [defalutValuePLFT, setDefalutValuePLFT] = useState({ priority_level: null, file_type: null });

  useEffect(() => {
    if (!formData.is_new_file && reopenfileList.length === 0) {
      const fetchData = async () => {
        setReopenFileLoading(true);
        try {
          const resp = await searchReopenFileApi();
          setReopenFileList(resp.data.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setReopenFileLoading(false);
        }
      };
      fetchData();
    }
  }, [formData.is_new_file]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleReopenFileChange = (name, value, fullData) => {
    const result = reopenfileList.find((obj) => obj.id === value);
    if (result) {
      const priority_level = priorityLevelOption.find(op => op.value === result.priority_level)
      const file_type = { label: result.file_type.name, value: result.file_type.id }
      setDefalutValuePLFT((prev) => ({ ...prev, priority_level, file_type }))
      setFormData((prev) => ({
        ...prev,
        [name]: value,
        is_internal: !result.is_outside,
        external_file_source: result.is_outside ? result.file_source : "",
        name: result.name,
        description: result.description,
        priority_level: result.priority_level,
        file_type_id: result.file_type.id
      }));
    }
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSelectChange = (name, value, fullData) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };


  const handleRadioChange = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "File Name is required";
    }
    if (!formData.file_number) {
      newErrors.file_number = "File Number is required";
    }
    if (!formData.priority_level) {
      newErrors.priority_level = "Priority is required";
    }
    if (!formData.description) {
      newErrors.description = "Description is required";
    }
    if (!formData.file_type_id) {
      newErrors.file_type_id = "File Type is required";
    }
    if (!formData.expected_complete_date) {
      newErrors.expected_complete_date = "Expected Complete Date is required";
    }
    if (!formData.is_new_file && !formData.reopen_file_id) {
      newErrors.reopen_file_id = "Please Select Reopen File";
    } if (!formData.is_internal && formData.external_file_source.trim().length === 0) {
      newErrors.external_file_source = "External file source is required";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = validateForm();
    if (!isValid) return;

    try {
      setIsSubmitLoading(true);
      const response = await createFileApi(formData);
      setErrors({});
      setBarcodeData(response.data.results)
      toggleBarcodeModal()
    } catch (error) {
      toast.error(error.response?.data?.message);
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const toggleBarcodeModal = () => {
    if (isBarcodeModalShow) {
      setBarcodeData(null)
    }
    setIsBarcodeModalShow(!isBarcodeModalShow)
  }

  // const handleBarcodePrint = () => {
  //   const printArea = barcodeRef.current; // Get the barcode content
  //   const originalContent = document.body.innerHTML; // Store original page content
  //   document.body.innerHTML = printArea.outerHTML;
  //   window.print(); // Trigger print dialog
  //   document.body.innerHTML = originalContent;
  //   navigate(`/file/${barcodeData.id}`)
  //   toggleBarcodeModal()
  // };

  const BarcodeSticker = ({ barcode }) => {
    return (
      <div className="sticker" id='barcode-sticker'>
        <div className="barcode-container">
          <div className='barcode'
            style={{ marignTop: '10px', marginBottom: '10px', pageBreakAfter: 'always' }}>
            <Barcode value={barcode} height={35} width={1.2} />
          </div>
        </div>
      </div>
    )
  }

  function handleBarcodePrint() {
    const element = document.getElementById("barcode-sticker").innerHTML;
    const printWindow = window.open('', '', 'height=800,width=800');
    printWindow.document.write(element);
    printWindow.document.close();
    // Here is the style you can add to hide the date and title in the print screen.
    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = '.department{textAlign:center} @page { size: auto;  margin: 0mm; }';
    // Append the style to the window's body
    printWindow.document.body.appendChild(style);

    printWindow.document.body.setAttribute('style', 'display:flex; justify-content: center; align-items: flex-start;  height: auto;');
    printWindow.print();

    // Add an event listener to the new window
    printWindow.addEventListener('beforeunload', function (event) {
      navigate(`/file/${barcodeData.id}`)
      toggleBarcodeModal()
    });
  }


  return (
    <div>
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Add File</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashbaord</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Add File
                </li>
              </ol>
            </nav>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Form action="#" onSubmit={handleSubmit}>
          <Row className="g-gs">
            <Col xxl="12">
              <Card className="card-gutter-md">
                <Card.Body>
                  <Row className="g-gs mb-5">
                    {/* File Type: New or Reopen */}
                    <Col lg="6">
                      <Form.Group>
                        <Form.Label as="legend" className="mb-3">
                          File Status
                        </Form.Label>
                        <div className="d-flex align-items-center">
                          <Form.Check
                            type="radio"
                            label="New File"
                            name="is_new_file"
                            id="new_file"
                            checked={formData.is_new_file}
                            onChange={() =>
                              handleRadioChange("is_new_file", true)
                            }
                            className="me-5"
                          />
                          <Form.Check
                            type="radio"
                            label="Reopen File"
                            name="is_new_file"
                            id="reopen_file"
                            checked={!formData.is_new_file}
                            onChange={() =>
                              handleRadioChange("is_new_file", false)
                            }
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    {/* File Source */}
                    <Col lg="6">
                      <Form.Group>
                        <Form.Label as="legend" className="mb-3">
                          File Source
                        </Form.Label>
                        <div className="d-flex align-items-center">
                          <Form.Check
                            type="radio"
                            label="Internal"
                            name="is_internal"
                            id="internal_source"
                            disabled={!formData.is_new_file}
                            checked={formData.is_internal}
                            onChange={() =>
                              handleRadioChange("is_internal", true)
                            }
                            className="me-5"
                          />
                          <Form.Check
                            type="radio"
                            label="External"
                            name="is_internal"
                            id="external_source"
                            disabled={!formData.is_new_file}
                            checked={!formData.is_internal}
                            onChange={() =>
                              handleRadioChange("is_internal", false)
                            }
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    {!formData.is_new_file && (
                      <>
                        <Col lg="6">
                          <StaticSelectInput
                            setParentInputValue={handleReopenFileChange}
                            optionsList={reopenfileList?.map((item) => ({
                              value: item.id,
                              label: `${item.file_number} (${item.name})`,
                            }))}
                            name="reopen_file_id"
                            id="reopen_file_id"
                            isClearable
                            isLoading={reopenFileLoading}
                            placeholder="Select Reopen File"
                            label="Reopen File"
                            labelHint={false}
                          />
                          {(errors.reopen_file_id) && (
                            <div className="text-danger fs-6">
                              {errors.reopen_file_id}
                            </div>
                          )}
                        </Col>
                      </>
                    )}

                    {!formData.is_internal && (
                      <Col lg="6">
                        <Form.Group>
                          <Form.Label htmlFor="source">
                            External File Source
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="external_file_source"
                            name="external_file_source"
                            disabled={!formData.is_new_file}
                            placeholder="External File Source"
                            value={formData.external_file_source}
                            onChange={handleChange}
                          />
                          {errors.external_file_source && (
                            <div className="text-danger fs-6">
                              {errors.external_file_source}
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                    )}

                    {/* file number */}
                    <Col lg="6">
                      <Form.Group>
                        <Form.Label htmlFor="file_number">File Number</Form.Label>
                        <Form.Control
                          type="text"
                          id="file_number"
                          name="file_number"
                          placeholder="File Number"
                          value={formData.file_number}
                          onChange={handleChange}
                        />
                        {errors.file_number && (
                          <div className="text-danger fs-6">
                            {errors.file_number}
                          </div>
                        )}
                      </Form.Group>
                    </Col>

                    {/* file name */}
                    <Col lg="6">
                      <Form.Group>
                        <Form.Label htmlFor="name">File Name</Form.Label>
                        <Form.Control
                          type="text"
                          id="name"
                          name="name"
                          disabled={!formData.is_new_file}
                          placeholder="File Name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                        {errors.name && (
                          <div className="text-danger fs-6">
                            {errors.name}
                          </div>
                        )}
                      </Form.Group>
                    </Col>



                    {/* file type */}
                    <Col lg="6">
                      <Form.Group className="form-group">
                        <DynamicSelectInput
                          endpoint_name="search_file_type"
                          setParentInputValue={handleSelectChange}
                          name="file_type_id"
                          id="file_type_id"
                          isDisabled={!formData.is_new_file}
                          setClickValue={defalutValuePLFT.file_type}
                          placeholder="Select File Type"
                          label="File Type"
                          labelHint={false}
                        />
                        {errors.file_type_id && (
                          <div className="text-danger fs-6">
                            {errors.file_type_id}
                          </div>
                        )}
                      </Form.Group>
                    </Col>



                    {/* fiel description */}
                    <Col lg="6">
                      <Form.Group>
                        <Form.Label htmlFor="name">Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          id="description"
                          rows={1}
                          name="description"
                          disabled={!formData.is_new_file}
                          placeholder="Enter your description"
                          value={formData.description}
                          onChange={handleChange}
                        />
                        {errors.description && (
                          <div className="text-danger fs-6">
                            {errors.description}
                          </div>
                        )}
                      </Form.Group>
                    </Col>

                    <Col lg="6">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="discount-price">
                          Expected Complete Date
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="date"
                            name="expected_complete_date"
                            value={formData.expected_complete_date}
                            onChange={handleChange}
                          />
                          {errors.expected_complete_date && (
                            <div className="text-danger fs-6">
                              {errors.expected_complete_date}
                            </div>
                          )}
                        </div>
                      </Form.Group>
                    </Col>

                    <Col lg="6">
                      <Form.Group className="form-group">
                        <StaticSelectInput
                          setParentInputValue={handleSelectChange}
                          optionsList={priorityLevelOption}
                          name="priority_level"
                          id="priority_level"
                          setClickValue={defalutValuePLFT.priority_level}
                          isDisabled={!formData.is_new_file}
                          placeholder="Select Priority Level"
                          label="Priority Level"
                          labelHint={false}
                        />
                        {errors.priority_level && (
                          <div className="text-danger fs-6">
                            {errors.priority_level}
                          </div>
                        )}
                      </Form.Group>
                    </Col>

                    <Col lg="12" className="">
                      <div className="d-flex gap-3 align-items-start">
                        <Button
                          type="submit"
                          variant="primary"
                          disabled={isSubmitLoading}
                        >
                          {isSubmitLoading ? "Submitting..." : "Submit"}
                        </Button>
                        <Button
                          type="button"
                          variant="secondary"
                          onClick={() => navigate("/files")}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Form>
      </Block>

      {isBarcodeModalShow && <div className="fade modal-backdrop show"></div>}
      <Modal
        show={isBarcodeModalShow}
        onHide={toggleBarcodeModal}
        backdrop={false}
        size="md"
      >
        {/* <Modal.Header>
          <Modal.Title>Approve File</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <h4 className="text-success text-center">File Created successfully</h4>

          {barcodeData &&
            <div className="d-flex align-item-center flex-column justify-content-center">
              <div className="mb-3 d-flex justify-content-center align-item-center">
                <BarcodeSticker
                  barcode={barcodeData?.barcode_number || "000000"}
                />
              </div>

              <div className="d-flex gap-3 justify-content-center align-item-center">
                <div>
                  <Button
                    type="submit"
                    variant="success"
                    onClick={handleBarcodePrint}
                  >
                    Print Barcode
                  </Button>
                </div>
                <div>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => navigate(`/file/${barcodeData.id}`)}
                  >
                    Not Now
                  </Button>
                </div>
              </div>
            </div>
          }
        </Modal.Body>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userDepartment: state.auth.user?.department,
});
export default connect(mapStateToProps)(AddFilePage);
