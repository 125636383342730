import React, { useState, useEffect } from "react";
import { Card, Form } from "react-bootstrap";
import { Pie } from "react-chartjs-2";
import "chart.js/auto";
import { getFileDistributionStatus } from "../../../services/DashboardService";
import FilterDropdown from "../Filter";

const FileStatusDistribution = () => {
  const [chartData, setChartData] = useState(null);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    async function fetchData(filterOption) {
      try {
        const response = await getFileDistributionStatus(filterOption);
        if (response.data.success) {
          const data = response.data.data;

          setChartData({
            labels: ["Approved", "InProgress", "Overdue"],
            datasets: [
              {
                data: [data.approved, data.in_progress, data.overdue],
                backgroundColor: ["#32CD32", "#FFA500", "#FF0000"],
                hoverBackgroundColor: ["#32CD32", "#FFA500", "#FF0000"],
                borderWidth: 1,
                hoverOffset: 8,
              },
            ],
          });
        }
      } catch (error) {
        console.error("Error fetching file distribution status:", error);
      }
    }

    fetchData(filter);
  }, [filter]);

  const handleFilterChange = (selectedFilter) => {
    setFilter(selectedFilter);
  };

  return (
    <Card className="h-100 shadow-sm">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h4>File Status Distribution</h4>
          <FilterDropdown filter={filter} onFilterChange={handleFilterChange} />
        </div>
        {chartData ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "300px" }}
          >
            <Pie
              data={chartData}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: true,
                    position: "bottom",
                    labels: {
                      boxWidth: 20,
                      padding: 15,
                      font: {
                        size: 14,
                        family: "Arial",
                        weight: "bold",
                      },
                      color: "#333",
                    },
                  },
                  tooltip: {
                    callbacks: {
                      label: function (tooltipItem) {
                        const value = tooltipItem.raw;
                        return ` ${tooltipItem.label}: ${value}`;
                      },
                    },
                  },
                  datalabels: {
                    display: true,
                    color: "#fff",
                    font: {
                      size: 16,
                      weight: "bold",
                    },
                    formatter: (value, context) => {
                      return `${value}`;
                    },
                  },
                },
                animation: {
                  animateScale: true,
                  animateRotate: true,
                },
              }}
            />
          </div>
        ) : (
          <div className="text-center">Loading Chart...</div>
        )}
      </Card.Body>
    </Card>
  );
};

export default FileStatusDistribution;
