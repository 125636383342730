import React, { useState, useEffect, useCallback } from "react";
import { CancelRequest } from "../../services/AxiosInstance";
import { useNavigate } from "react-router-dom";
import { Badge, Card, Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import Block from "../../components/Block/Block";
import { Icon, MediaGroup, Media, MediaText, Image } from "../../components";
import DataTable from "react-data-table-component";
import { getUserListApi } from "../../services/UserService";
import { toInitials, deptOptions, roleOptions } from "../../utilities";
import { connect } from "react-redux";
import { debounce } from "lodash";
import DebounceSearchInput from "../Constant/DebounceSearchInput";
import { StaticSelectInput } from "../Constant/SelectInput";
import Pagination from "../Constant/Pagination";

function UserListPage({ userRole }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showItemPerPage, setShowItemPerPage] = useState(25);
  const [mobileView, setMobileView] = useState(false);
  const [filter, setFilter] = useState({
    query: "",
    department: "",
    designation: "",
    role: "",
  });
  const navigate = useNavigate();

  const handleShowModal = () => {
    navigate("/add-user");
  };

  const handleSearchChange = debounce((value) => {
    CancelRequest();
    setSearchText(value);
    setFilter((prev) => ({
      ...prev,
      query: value.toLowerCase(),
    }));
    setCurrentPage(1);
  }, 100);

  const handleSelectChange = (name, value) => {
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
    setCurrentPage(1);
  };

  const fetchData = useCallback(() => {
    CancelRequest();
    setIsLoading(true);
    getUserListApi(
      currentPage,
      showItemPerPage,
      { query: filter.query },
      { department: filter.department },
      { role: filter.role }
    )
      .then((resp) => {
        const allUsers = resp.data.results;
        setData(allUsers);
        setTotal(resp.data.count);
      })
      .catch((error) => {
        console.error(error.response?.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    showItemPerPage,
    currentPage,
    filter.query,
    filter.department,
    filter.role,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePagination = (page) => {
    setCurrentPage(page.selected + 1);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setShowItemPerPage(newRowsPerPage);
    setCurrentPage(1);
  };  

  const columns = [
    {
      name: "Users",
      grow: 2,
      selector: (row) => row.name,
      cell: (row) => (
        <MediaGroup>
          <Media size="md" shape="circle" variant="dark-soft">
            {row?.profile_photo ? (
              <Image
                src={`${process.env.REACT_APP_BASE_URL}${row?.profile_photo}`}
                staticImage
                alt="user"
              />
            ) : (
              <span className="fw-medium">{toInitials(row?.name)}</span>
            )}
          </Media>

          <MediaText>
            <Link
              to={`/user/${row?.id}`}
              className="title text-uppercase text-primary"
            >
              <strong>{row?.name}</strong>{" "}
            </Link>
            <Link to={`/user/${row?.id}`}>
              <span className="small text">{row?.email}</span>
            </Link>
          </MediaText>
        </MediaGroup>
      ),
      sortable: true,
    },
    {
      name: "Designation",
      selector: (row) => row?.designation,
      cell: (row) => <span> {row?.designation}</span>,
      sortable: true,
    },
    {
      name: "Department",
      selector: (row) => row?.department,
      cell: (row) =>
        row?.department ? (
          <span>
            {" "}
            {deptOptions.find((option) => option.value === row?.department)
              ?.label || "NA"}
          </span>
        ) : (
          <span> General</span>
        ),
      sortable: true,
    },
    {
      name: "Reports To",
      selector: (row) => row?.reports_to?.name,
      cell: (row) =>
        row?.reports_to ? (
          <span>
            <MediaText>
              <Link to={`/user/${row?.id}`} className="title">
                <strong>{row.reports_to?.name}</strong>
              </Link>
            </MediaText>
          </span>
        ) : (
          "N/A"
        ),
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row?.role,
      cell: (row) => (
        <span className={`badge text-bg-info-soft`}>
          {" "}
          {row?.role === "admin"
            ? "Admin"
            : row?.role === "management"
            ? "Management"
            : row?.role === "user"
            ? "User"
            : "Daak"}
        </span>
      ),
      sortable: false,
    },
    {
      name: "status",
      selector: (row) => row.status,
      cell: (row) => (
        <span
          className={`badge text-bg-${
            row.status === "active"
              ? "success-soft"
              : row.status === "blocked"
              ? "danger-soft"
              : row.status === "inactive"
              ? "secondary-soft"
              : "primary-soft"
          }`}
        >
          {row?.status
            ? row?.status.charAt(0).toUpperCase() +
              row?.status.slice(1).toLowerCase()
            : "General"}
        </span>
      ),
      sortable: false,
    },
  ];

  const viewChange = () => {
    if (window.innerWidth < 960) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  useEffect(() => {
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
    };
  }, []);

  return (
    <div>
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Users Management</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  User Management
                </li>
              </ol>
            </nav>
          </Block.HeadContent>
          <Block.HeadContent>
            <ul className="d-flex">
              <li>
                {userRole === "admin" && (
                  <Button
                    className="d-none d-md-inline-flex"
                    variant="primary"
                    onClick={handleShowModal}
                  >
                    <Icon name="plus" />
                    <span>Add User</span>
                  </Button>
                )}
              </li>
            </ul>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>
      <Block>
        <Card>
          <div className="data-table-wrapper">
            <div className="data-table-top">
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* Search */}
                <div className="me-3">
                  <DebounceSearchInput
                    handleSearchChange={handleSearchChange}
                  />
                </div>
                {userRole === "admin" && (
                  <StaticSelectInput
                    setParentInputValue={handleSelectChange}
                    optionsList={deptOptions}
                    name="department"
                    id="department"
                    parentClassName="mt-0 me-2"
                    isClearable
                    placeholder="Select Department"
                  />
                )}
                <StaticSelectInput
                  setParentInputValue={handleSelectChange}
                  optionsList={roleOptions}
                  name="role"
                  id="role"
                  parentClassName="mt-0"
                  isClearable
                  placeholder="Select Role"
                />
              </div>

              <div className="data-table-action-wrap">
                <div className="data-table-select">
                  <select
                    className="form-select"
                    onChange={(e) =>
                      handleRowsPerPageChange(Number(e.target.value))
                    }
                    value={showItemPerPage}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </select>
                  <span className="text">Per page</span>
                </div>
              </div>
            </div>
            <DataTable
              columns={columns}
              data={data}
              noDataComponent={
                <div className="p-2">There are no records found.</div>
              }
              sortIcon={<div className="data-table-sorter"></div>}
              pagination
              paginationPerPage={showItemPerPage}
              progressPending={isLoading}
              progressComponent={
                <div className="text-center my-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              }
              paginationComponent={(props) => (
                <div className="data-table-bottom">
                  <Pagination
                    currentPage={currentPage}
                    recordsPage={showItemPerPage}
                    dataLength={total}
                    handlePagination={handlePagination}
                  />
                </div>
              )}
            />
          </div>
        </Card>
      </Block>
    </div>
  );
}
const mapStateToProps = (state) => ({
  userRole: state.auth.user?.role || "user",
});

export default connect(mapStateToProps)(UserListPage);
