import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Icon, Media } from "../../../components";
import { getPendingApprovalFileCount } from "../../../services/DashboardService";

const KPIData = () => {
  const [data, setData] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getPendingApprovalFileCount();
          const data = response.data.data;
          setData(response.data.data);
      } catch (error) {
        console.error("Error fetching file distribution status:", error);
      }
    }

    fetchData();
  }, []);

  const value = [
    {
      title: "Total Approved",
      value: (
        <>
          {data?.approved} <Icon name="thumbs-up" className="text-success ms-4" />
        </>
      ),
      icon: (
        <Media shape="circle" variant="success" className="mb-3 me-3">
          <Icon name="file" />
        </Media>
      ),
    },
    {
      title: "Pending for Approval",
      value: (
        <>
          {data?.approval} <Icon name="info-fill" className="text-danger ms-4" />
        </>
      ),
      icon: (
        <Media shape="circle" variant="danger" className="mb-3 me-3">
          <Icon name="clock" />
        </Media>
      ),
    },
    {
      title: "Intra vs Outside Files",
      value: (
        <>
          {data?.intra_vs_outside}<Icon name="activity" className="text-info ms-3" />
        </>
      ),
      icon: (
        <Media shape="circle" variant="info" className="mb-3 me-3">
        <Icon name="line-chart" />
        </Media>
      ),
    },
  ];

  return (
    <div className="d-flex flex-md-column flex-row gap-2">
      {value.map((item, index) => (
        <Card key={index} className="shadow-sm mb-2">
          <Card.Body className="d-flex align-items-center">
            {item.icon}
            <div>
              <Card.Title className="fs-6">{item.title}</Card.Title>
              <Card.Text className="fs-6">{item.value}</Card.Text>
            </div>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};

export default KPIData;
