import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { getFileHistory } from "../../services/FileActionService";
import {
  Icon,
  Schedule,
} from "../../components";
import SimpleBar from "simplebar-react";
import { Badge, Spinner } from "react-bootstrap";
import Pagination from "../Constant/Pagination";

function FileHistory() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const recordsPage = 10;

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getFileHistory(currentPage, recordsPage)
      .then((resp) => {
        setData(resp.data.data);
        setTotal(resp.data.total);
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentPage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePagination = (page) => {
    setCurrentPage(page.selected + 1); 
  };

  const getBadgeColor = (status) => {
    switch (status) {
      case "in_progress":
        return "warning";
      case "completed":
        return "success";
      case "received":
        return "info";
      case "dispatched":
        return "primary";
      case "out":
        return "danger";
      default:
        return "secondary";
    }
  };

  return (
    <>
      <div>
        <h4>File History</h4>
        <SimpleBar style={{ height: "650px" }} className="mb-2 mt-4">
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <Schedule>
              {data.map((item, index) => (
                <Schedule.Item
                  symbol="active"
                  contentClass="nk-schedule-content-no-border flex-grow-1 px-4"
                  key={index}
                >

                  <div className="list-group-dotted mt-3 p-3" key={index}>

                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <div>
                        <span className="smaller me-2">
                          <Icon name="clock"  />{" "}
                          {item?.created_at} ({item?.created_by}){" "}
                        </span>
                      </div>

                      <Badge bg={getBadgeColor(item?.status)}>
                        {item?.status?.toUpperCase()}
                      </Badge>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <h3>{item?.name}</h3>
                        <span className="fs-6">
                          <Icon name="file-fill" />{" "}
                          <strong>
                            {item?.file_number}{" "}
                            <span className="mb-1 ms-2">
                              <Icon name="building" /> {item?.department}
                            </span>
                          </strong>
                        </span>

                      </div>
                      <div></div>
                    </div>

                  </div>
                </Schedule.Item>
              ))}
            </Schedule>
          )}
        </SimpleBar>
        <div>
        <Pagination
          currentPage={currentPage}
          recordsPage={recordsPage}
          dataLength={total}
          handlePagination={handlePagination}
        />
      </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  userRole: state.auth.user?.role || "user",
});

export default connect(mapStateToProps)(FileHistory);
