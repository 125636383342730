import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Tab, Card, Spinner } from "react-bootstrap";
import Block from "../../components/Block/Block";
import { connect } from "react-redux";
import { Image, Icon, Media } from "../../components";
import { toInitials } from "../../utilities";
import { getUserApi } from "../../services/UserService";
import { deptOptions, roleOptions } from "../../utilities";
import FileHistory from "../Profile/FileHistory";

function UserProfilePage({ userRole }) {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await getUserApi(id);
        setUser(response?.data?.results);
      } catch (err) {
        setError(err.message || "An error occurred while fetching user data.");
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [id]);

  if (loading)
    return (
        <div className="text-center my-4">
        <Spinner animation="border" variant="primary" />
      </div>
    );

  return (
    <div>
      <Block.Head>
        <Block.HeadBetween className="align-items-start">
          <Block.HeadContent>
            <div className="d-flex flex-column flex-md-row align-items-md-center">
              <Media size="huge" shape="circle" variant="dark-soft">
                {user?.profile_photo ? (
                  <Image
                    src={`${process.env.REACT_APP_BASE_URL}${user?.profile_photo}`}
                    staticImage
                    alt="user"
                  />
                ) : (
                  <span className="fw-medium">{toInitials(user?.name)}</span>
                )}
              </Media>

              <div className="mt-1 ms-md-3">
                <h3 className="title text-uppercase mb-1">{user?.name}</h3>
                <h5 className="text-uppercase">{user?.designation}</h5>
                <ul className="nk-list-option">
                  <li>
                    <Icon name="building" />
                    <span className="small">
                      {deptOptions.find(
                        (option) => option.value === user?.department
                      )?.label}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </Block.HeadContent>
          <Block.HeadContent>
            <div className="d-flex gap g-3">
              <div className="gap-col">
                {userRole === "admin" && (
                  <ul className="d-flex gap g-2">
                    <li className="d-none d-md-block">
                      <Link
                        to={`/edit-user/${user?.id}`}
                        className="btn btn-soft btn-primary"
                      >
                        <Icon name="edit"></Icon>
                        <span>Edit Profile</span>
                      </Link>
                    </li>
                    <li className="d-md-none">
                      <Link
                        to={`/edit-user/${user?.id}`}
                        className="btn btn-soft btn-primary btn-icon"
                      >
                        <Icon name="edit"></Icon>
                      </Link>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Tab.Container defaultActiveKey="tabOne">
        <Block className="mt-2">
          <Tab.Content>
            <Tab.Pane eventKey="tabOne">
              <Card className="card-gutter-md">
                <div className="card-row card-row-lg col-sep col-sep-lg">
                  <div className="card-aside">
                    <Card.Body>
                      <div className="bio-block">
                        <div className="d-flex align-items-center">
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-sm btn-circle"
                            onClick={() => navigate("/users")}
                          >
                            <Icon name="arrow-left"></Icon>
                          </button>
                          <h4 className="bio-block-title mx-3">Details</h4>
                        </div>

                        <ul className="list-group list-group-borderless small">
                          <li className="list-group-item">
                            <span className="title fw-medium w-40 d-inline-block">
                              Personnel Number:
                            </span>
                            <span className="text">
                              {user?.personnel_number}
                            </span>
                          </li>
                          <li className="list-group-item">
                            <span className="title fw-medium w-40 d-inline-block">
                              Full Name:
                            </span>
                            <span className="text">{user?.name}</span>
                          </li>
                          <li className="list-group-item">
                            <span className="title fw-medium w-40 d-inline-block">
                              Email:
                            </span>
                            <span className="text">{user?.email}</span>
                          </li>

                          <li className="list-group-item">
                            <span className="title fw-medium w-40 d-inline-block">
                              Phone No.:
                            </span>
                            <span className="text">{user?.phone_number}</span>
                          </li>
                          <li className="list-group-item">
                            <span className="title fw-medium w-40 d-inline-block">
                              Extension No.:
                            </span>
                            <span className="text">
                              {user?.extension_number|| "NA"}
                            </span>
                          </li>
                          <li className="list-group-item">
                            <span className="title fw-medium w-40 d-inline-block">
                              Role:
                            </span>
                            <span className={`badge small text-bg-dark-soft`}>
                              {roleOptions.find(
                                (option) => option.value === user?.role
                              )?.label || "NA"}
                            </span>
                          </li>

                          <li className="list-group-item">
                            <span className="title fw-medium w-40 d-inline-block">
                              Date Of Joining:
                            </span>
                            <span className="text">
                              {user?.created_at || "NA"}
                            </span>
                          </li>
                          <li className="list-group-item">
                            <span className="title fw-medium w-40 d-inline-block">
                              Status:
                            </span>
                            <span
                              className={`badge text-bg-${
                                user?.status === "active"
                                  ? "success-soft"
                                  : user?.status === "blocked"
                                  ? "danger-soft"
                                  : user?.status === "inactive"
                                  ? "secondary-soft"
                                  : "primary-soft"
                              }`}
                            >
                              {user?.status
                                ? user?.status.charAt(0).toUpperCase() +
                                  user?.status.slice(1).toLowerCase()
                                : "General"}
                            </span>
                          </li>
                          <li className="list-group-item">
                            <span className="title fw-medium w-40 d-inline-block">
                              Reports To:
                            </span>
                            <span className="text">
                              <Link
                                to={`/user/${user?.reports_to?.id}`}
                                className="title"
                              >
                                <strong>{user?.reports_to?.name}</strong>
                              </Link>
                            </span>
                          </li>
                          <li className="list-group-item">
                            <span className="title fw-medium w-40 d-inline-block">
                              Last Login:
                            </span>
                            <span className="text">{user?.last_login}</span>
                          </li>
                        </ul>
                      </div>

                      <div className="bio-block">
                        <h4 className="bio-block-title mb-2">
                          File Permission
                        </h4>
                        {user?.file_permission &&
                        Object.keys(user.file_permission).length > 0 ? (
                          <ul
                            className="d-flex flex-wrap gap gx-1"
                            style={{ listStyle: "none", padding: 0, margin: 0 }}
                          >
                            {Object.entries(user.file_permission).map(
                              ([permission, isEnabled]) => (
                                <li
                                  key={permission}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "0.25rem 0.4rem",
                                    margin: "0.25rem",
                                    fontSize: "0.75rem",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    checked={isEnabled}
                                    disabled
                                    style={{
                                      cursor: "not-allowed",
                                      marginRight: "0.2rem",
                                    }}
                                  />
                                  <label
                                    style={{
                                      margin: 0,
                                      fontSize: "0.85rem",
                                      color: "#495057",
                                    }}
                                  >
                                    {permission.charAt(0).toUpperCase() +
                                      permission.slice(1)}
                                  </label>
                                </li>
                              )
                            )}
                          </ul>
                        ) : (
                          <p style={{ fontSize: "0.85rem", color: "#6c757d" }}>
                            File permission not granted
                          </p>
                        )}
                      </div>
                    </Card.Body>
                  </div>
                  <div className="card-content col-sep flex-fill">
                    <Card.Body>
                      <FileHistory />
                    </Card.Body>
                  </div>
                </div>
              </Card>
            </Tab.Pane>
          </Tab.Content>
        </Block>
      </Tab.Container>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userRole: state.auth.user?.role || "user",
});

export default connect(mapStateToProps)(UserProfilePage);
