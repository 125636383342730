import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { dispatchFileApi } from "../../../../../services/FileService";
import { Modal, Form, Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Icon } from "../../../../../components";
import Swal from 'sweetalert2/src/sweetalert2.js';
import { handleCompleteDispatchFileAction, handleSetDispatchBarcodeNumberAction, toggleDispatchModalAction } from "../../../../../store/actions/FileActions";
import { useDispatch } from "react-redux";

function DispatchFile({ isModalVisible, barcode_number }) {
  const [formData, setFormData] = useState({
    barcode_number: "",
  });
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    if (barcode_number) {
      setFormData({ barcode_number: barcode_number });
    }
  }, [barcode_number])

  const toggleModal = () => {
    dispatch(toggleDispatchModalAction());
    if (isModalVisible) {
      dispatch(handleSetDispatchBarcodeNumberAction(""))
    }
    setFormData({ barcode_number: "" });
    setErrors({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const alertSuccess = () => {
    Swal.fire({
      icon: "success",
      text: "The file has been successfully dispatched !",
      timer: 3000,
      timerProgressBar: true,
    }).then(() => {
      console.log("SweetAlert closed");
    });
    toggleModal();
  };



  const verifyBarcodeNumber = async (barcode_number) => {

    try {
      setIsSubmitLoading(true);
      const payload = {
        ...formData, barcode_number
      };
      const response = await dispatchFileApi(payload);
      dispatch(handleCompleteDispatchFileAction(response.data?.data));
      setErrors({});
      alertSuccess();
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      toast.error(errorMessage);
      setErrors({ api: errorMessage });
    } finally {
      setIsSubmitLoading(false);
    }
  };
  const handleVerifyFile = (e) => {
    e.preventDefault();
    const barcode = formData.barcode_number.trim()
    if (!barcode) {
      setErrors({ barcode_number: "Barcode number is required" });
      return;
    } else {
      verifyBarcodeNumber(barcode)
    }

  };

  return (
    <>
      <Button variant="outline-success" onClick={toggleModal} size="md">
        Dispatch File <Icon name="curve-up-right" className={"ms-2"}></Icon>
      </Button>
      {isModalVisible && <div className="fade modal-backdrop show"></div>}
      <Modal
        show={isModalVisible}
        onHide={toggleModal}
        size="md"
        backdrop={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Dispatch File</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleVerifyFile}>
            <Row className="g-gs mb-4">
              <Col lg="12">
                <Form.Group>
                  <Form.Label htmlFor="barcode_number">
                    Barcode Number
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="barcode_number"
                    id="barcode_number"
                    placeholder="Scan the File Barcode"
                    value={formData.barcode_number}
                    onChange={handleChange}
                    isInvalid={!!errors.barcode_number}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.barcode_number}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <div className="d-flex gap-3">
              <Button
                type="submit"
                variant="success"
                size="sm"
                disabled={isSubmitLoading}
              >
                {isSubmitLoading ? "Verifying..." : "Verify"}
              </Button>
              <Button
                type="button"
                variant="secondary"
                size="sm"
                onClick={() => {
                  if (formData.barcode_number) {
                    setFormData({ barcode_number: "" }); 
                  } else {
                    toggleModal();
                  }
                }}
              >
                {formData.barcode_number ? "Reset" : "Cancel"}
              </Button>

            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  userDepartment: state.auth.user?.department,
  isModalVisible: state.file.showDispatchModal,
  barcode_number: state.file.barcode_number
});
export default connect(mapStateToProps)(DispatchFile);
