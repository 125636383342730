import React from "react";
import ReactPaginate from "react-paginate";
import { Icon } from "../../components";

const Pagination = (props) => {

  const {
    currentPage,
    recordsPage,
    dataLength,
    handlePagination
  } = props;
  const lastIndex = currentPage * recordsPage;
  const count = Number(Math.ceil(dataLength / recordsPage))
  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="pagination_info">
        Showing {lastIndex - recordsPage + 1} to{" "}
        {dataLength < lastIndex ? dataLength : lastIndex} of {dataLength}{" "}
        entries
      </div>
      <div
        className="dataTables_paginate paging_simple_numbers justify-content-center"
        id="example2_paginate"
      >
        <ReactPaginate
          previousLabel={<Icon name="chevron-left"></Icon>}
          nextLabel={<Icon name="chevron-right"></Icon>}
          pageCount={count || 1}
          activeClassName="active"
          forcePage={currentPage !== 0 ? currentPage - 1 : 0}
          onPageChange={(page) => handlePagination(page)}
          pageClassName={"page-item"}
          pageRangeDisplayed={3}
          nextLinkClassName={"page-link"}
          nextClassName={"page-item next"}
          previousClassName={"page-item prev me-1"}
          previousLinkClassName={"page-link"}
          pageLinkClassName={"page-link paginate-page-link me-1"}
          containerClassName={
            "pagination pagination-sm react-paginate justify-content-end my-2 pe-1"
          }
        />
      </div>
    </div>
  );
};
export default Pagination;
