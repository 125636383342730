import React, { useState, useEffect } from 'react';
import { Offcanvas, Alert, Spinner } from 'react-bootstrap';
import { Icon, Media, MediaGroup, MediaText, Image } from '../../../components';
import { useNavigate } from 'react-router-dom';
import { getNotificationListApi, updateNotificationStatusApi } from '../../../services/FileService';
import { toInitials } from '../../../utilities';
import { connect, useDispatch } from 'react-redux';
import { handleSetBarcodeNumberAction, handleSetNotificationAction, toggleReceiveModalAction } from '../../../store/actions/FileActions';

const Notification = ({ userRole}) => {
    const [notificationList,setNotificationList] = useState([]);
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [socket, setSocket] = useState(null);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();

    const pageSize = 10;
    const navigate = useNavigate()


    // Update the notification list with unique IDs
    const validateNotification = (newNotification, prevList) => {
        if (!prevList) return true
        return !prevList.some((notif) => notif.id === newNotification.id);
    };

    // Fetch initial notifications
    const fetchNotifications = async (pageNumber = 1) => {
        try {
            setLoading(true);
            const response = await getNotificationListApi(pageNumber, pageSize);
            const { results, count } = response.data;
            // dispatch(handleSetNotificationAction({ results, count }))
            const prevNotif = notificationList
            results.forEach((notification) => {
                if (validateNotification(notification, prevNotif)) {
                    prevNotif.push(notification)
                }
            }
            );

            setNotificationList((prevList) => [...prevNotif]);
            const isMore = prevNotif.length !== count

            setHasMore(isMore);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching notifications:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchNotifications();
    }, []);



    // Establish WebSocket connection
    useEffect(() => {
        const ws = new WebSocket(`${process.env.REACT_APP_WS_BASE_URL}/ws/file_notifications`);

        ws.onopen = () => {
            console.log('WebSocket connection established');
        };

        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.type === "notification_sent") {
                const message = data.message
                if (validateNotification(message)) {
                    setNotificationList((prev) => ([message, ...prev]))
                }
            }
        };

        ws.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        ws.onclose = (event) => {
            console.log('WebSocket connection closed:', event);
        };

        setSocket(ws);

        // Clean up on unmount
        return () => {
            ws.close();
        };
    }, []);

    // Offcanvas handlers
    const handleOffcanvasClose = () => setShowOffcanvas(false);
    const handleOffcanvasShow = () => setShowOffcanvas(true);


    // Mark notification as read
    // Mark notification as read
    const markAsRead = async (notificationId) => {
        try {
            // Update the status on the server
            await updateNotificationStatusApi(notificationId);
            setNotificationList((prevList) =>
                prevList.map((notif) => notif.id === notificationId ? { ...notif, status: "read" } : notif)
            );
        } catch (error) {
            console.error('Error updating notification status:', error);
        }
    };


    const handleOnClickNotification = (notif) => {
        if (notif.status !== "read") {
            markAsRead(notif.id);
        }

        if (userRole === "daak") {
            if (notif.status === "read") {
                handleOffcanvasClose(); 
            } else {
                handleOpenReceiveModal(notif?.file?.barcode);
                handleOffcanvasClose(); 
            }
            return; 
        }
        

        navigate(`/file/${notif.file.id}`);
        handleOffcanvasClose();
    };


    const handleOpenReceiveModal = (barcode) => {
        dispatch(toggleReceiveModalAction())
        dispatch(handleSetBarcodeNumberAction(barcode))
    }


    // Infinite scrolling handler
    const handleScroll = (event) => {
        const { scrollTop, scrollHeight, clientHeight } = event.target;
        if (scrollHeight - scrollTop <= clientHeight + 50 && !loading && hasMore) {
            setPage((prevPage) => {
                const nextPage = prevPage + 1;
                fetchNotifications(nextPage);
                return nextPage;
            });
        }
    };

    const timeAgo = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();

        const secondsAgo = Math.floor((now - date) / 1000);
        const minutesAgo = Math.floor(secondsAgo / 60);
        const hoursAgo = Math.floor(minutesAgo / 60);
        const daysAgo = Math.floor(hoursAgo / 24);
        const weeksAgo = Math.floor(daysAgo / 7);
        const monthsAgo = Math.floor(daysAgo / 30);
        const yearsAgo = Math.floor(daysAgo / 365);

        if (secondsAgo < 60) {
            return `${secondsAgo} sec ago`;
        } else if (minutesAgo < 60) {
            return `${minutesAgo} min ago`;
        } else if (hoursAgo < 24) {
            return `${hoursAgo} hr ago`;
        } else if (daysAgo < 7) {
            return `${daysAgo} days ago`;
        } else if (weeksAgo < 4) {
            return `${weeksAgo} weeks ago`;
        } else if (monthsAgo < 12) {
            return `${monthsAgo} months ago`;
        } else {
            return `${yearsAgo} years ago`;
        }
    };


    const unreadCount = notificationList.filter((notif) => notif.status !== "read").length;

    return (
        <>
            <div className='me-2'>
                <button className="btn-icon btn btn-zoom btn-md position-relative" onClick={handleOffcanvasShow}>
                    <Icon size="lg" name="bell"></Icon>
                    {unreadCount > 0 && (
                        <span
                            style={{ left: "80%", top: "7px", fontWeight: '300' }}
                            className="position-absolute translate-middle badge m-0 rounded-pill bg-danger light badge-sm"
                        >
                            {unreadCount > 9 ? '9+' : unreadCount}
                        </span>
                    )}
                </button>
            </div>

            <Offcanvas className="offcanvas-size-lg" placement="end" show={showOffcanvas} onHide={handleOffcanvasClose}>
                <Offcanvas.Header closeButton className="border-bottom border-light">
                    <Offcanvas.Title>Recent Notifications</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body onScroll={handleScroll}>
                    {notificationList.length === 0 ? (
                        <Alert variant="info">Notifications not found.</Alert>
                    ) : (
                        <ul className="list-unstyled">
                            {notificationList.map((notification, index) => (
                                <li key={index} className="mb-2">
                                    <div className={`card ${notification.status === "read" ? "bg-body" : "bg-light-subtle"} cursor-pointer`} onClick={() => handleOnClickNotification(notification)}>
                                        <div className="card-body">
                                            <div className='d-flex justify-content-between align-items-center'>
                                                {notification.sender ?
                                                    <MediaGroup>
                                                        <Media size="sm" shape="circle" variant="dark-soft">
                                                            {notification.sender.profile_photo ? (
                                                                <Image
                                                                    src={`${process.env.REACT_APP_BASE_URL}${notification.sender.profile_photo}`}
                                                                    staticImage
                                                                    alt="user"
                                                                />
                                                            ) : (
                                                                <span className="fw-medium">{toInitials(notification.sender.name)}</span>
                                                            )}
                                                        </Media>
                                                        <MediaText>
                                                            <strong>{notification.sender.name}</strong>{" "}
                                                            <span className="small text">{notification.sender.department || ""}</span>
                                                        </MediaText>
                                                    </MediaGroup>
                                                    : <MediaGroup>
                                                        <Media size="sm" shape="circle" variant="dark-soft">
                                                            <img src="/logo192.png" alt={'logo'} />
                                                        </Media>
                                                        <MediaText>
                                                            <strong>
                                                                <span style={{ color: "#29469a" }}>inteli</span><span style={{ color: "#05aca5" }}>Track</span>
                                                            </strong>
                                                        </MediaText>
                                                    </MediaGroup>}
                                                <small className="text-muted">{timeAgo(notification.created_at)}</small>
                                            </div>
                                            <div className='my-2'>
                                                <p>{notification.message}</p>
                                            </div>
                                            <div>
                                                <span className='badge text-bg-info-soft'>File No: {notification.file.file_number}</span>
                                            </div>
                                        </div>
                                        <div className='position-absolute top-0 end-0 mx-3 my-1'>
                                            {notification.status === "sent" && <span className="badge text-bg-danger">New</span>}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                    {
                        loading &&
                        <div className="text-center my-4">
                            <Spinner animation="border" variant="primary" />
                        </div>
                    }

                    {!hasMore && page > 1 && <p className="text-center text-muted">No more notifications.</p>}
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

const mapStateToProps = (state) => ({
    notificationList: state.file.notificationList || [],
    notificationCount: state.file.notificationCount || 0,
});
export default connect(mapStateToProps)(Notification);
