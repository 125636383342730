import React from "react";
import logo from "../../Images/InteliTrack-Logo.png";

const Loading = () => {
  return (
    <div className="preloader">
      <div className="flash-screen">
        <div className="flash-body bg-dark">
          <img
            src={logo}
            alt="Logo"
            width="220px"
            style={{ marginRight: '0.5rem' }}
          />
          <div className="text-container">
            <h1 className="club-name text-white text-2xl">InteliTrack</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
