import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { recieveFileApi } from "../../../../../services/FileService";
import { Modal, Form, Row, Col, Button } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import ReceiveFileInForm from "./ReceiveFileInForm";
import { Icon } from "../../../../../components";
import { toggleReceiveModalAction, handleSetBarcodeNumberAction } from "../../../../../store/actions/FileActions";

function ReceiveFile({isModalVisible, barcodeNumber}) {
  const [formData, setFormData] = useState({
    barcode_number: "",
  });
  const [data, setData] = useState([]);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isValidBarcode, setIsValidBarcode] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if(barcodeNumber){
      setFormData({ barcode_number: barcodeNumber });
      verifyBarcodeNumber(barcodeNumber)
    }
  }, [barcodeNumber])

  const toggleModal = () => {
    dispatch(toggleReceiveModalAction());
    if(isModalVisible){
      dispatch(handleSetBarcodeNumberAction(""))
    }
    setIsValidBarcode(false);
    setFormData({ barcode_number: "" });
    setErrors({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" })); 
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };


  const verifyBarcodeNumber = async (barcode) => {
    try {
      setIsSubmitLoading(true);
      const response = await recieveFileApi(barcode);
      setData(response.data.results);
      setErrors({});
      toast.success(response.data?.message);
      setIsValidBarcode(true);
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      toast.error(errorMessage);
      setErrors({ api: errorMessage });
    } finally {
      setIsSubmitLoading(false);
    }
  }

  const handleVerifyFile = (e) => {
    e.preventDefault();
    const barcode = formData.barcode_number.trim()
    if (!barcode) {
      setErrors({ barcode_number: "Barcode number is required" });
      return;
    } else {
      verifyBarcodeNumber(barcode)
    }

  };

  return (
    <>
      <Button variant="outline-info" onClick={toggleModal} size="md">
        Receive File <Icon name="curve-right-down" className={'ms-2'} />
      </Button>
      {isModalVisible && <div className="fade modal-backdrop show"></div>}
      <Modal
        show={isModalVisible}
        onHide={toggleModal}
        backdrop={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Receive File
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!isValidBarcode ? (
            <Form onSubmit={handleVerifyFile}>
              <Row className="g-gs mb-4">
                <Col lg="12">
                  <Form.Group>
                    <Form.Label htmlFor="barcode_number">
                      Barcode Number
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="barcode_number"
                      id="barcode_number"
                      placeholder="Scan the File Barcode"
                      value={formData.barcode_number}
                      onChange={handleChange}
                      isInvalid={!!errors.barcode_number}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.barcode_number}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <div className="d-flex gap-3">
                <Button
                  type="submit"
                  variant="primary"
                  size="sm"
                  disabled={isSubmitLoading}
                >
                  {isSubmitLoading ? "Verifying..." : "Verify"}
                </Button>
                <Button
                  type="button"
                  variant="secondary"
                  size="sm"
                  onClick={toggleModal}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          ) : <ReceiveFileInForm
            data={data}
            barcodeNumber={formData.barcode_number}
            onResetStep={() => {
              setIsValidBarcode(false);
              setFormData({ barcode_number: "" });
              setErrors({});
            }}
            toggleModal={toggleModal}
          />}

          {/* {currentStep === 2 && (
            <div>
              {["out", "in"].includes(data?.status) ? (
                <ReceiveFileInForm
                  data={data}
                  barcodeNumber={formData.barcode_number}
                  onResetStep={() => {
                    setIsValidBarcode(false);
                    setFormData({ barcode_number: "" });
                    setErrors({});
                  }}
                  toggleModal={toggleModal}
                />
              ) : (
                <ReceiveFileDispatchForm barcodeNumber={formData.barcode_number} />
              )}
            </div>
          )} */}
        </Modal.Body>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  userDepartment: state.auth.user?.department,
  isModalVisible:state.file.showReceiveModal,
  barcodeNumber:state.file.barcodeNumber,
});
export default connect(mapStateToProps)(ReceiveFile);
