import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Card } from "react-bootstrap";
import { getMonthlyOverdueFiles } from "../../../services/DashboardService";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const MonthlyOverdueFilesChart = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Number of Overdue Files",
        data: [],
        borderColor: "red",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        tension: 0.4,
      },
    ],
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getMonthlyOverdueFiles()
      .then((response) => {
        const { data } = response.data;

        const labels = data.map((item) => item.month);
        const overdueCounts = data.map((item) => item.overdue_files_count);

        setChartData({
          labels,
          datasets: [
            {
              label: "Number of Overdue Files",
              data: overdueCounts,
              borderColor: "red",
              backgroundColor: "rgba(255, 99, 132, 0.2)",
              tension: 0.4,
            },
          ],
        });

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, 
      },
      title: {
        display: true,
        text: "Monthly Overdue File Trends",
        font: { size: 16 },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Months",
          font: { size: 12 },
        },
      },
      y: {
        title: {
          display: true,
          text: "Number of Overdue Files",
          font: { size: 12 },
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <Card className="h-100 shadow-sm">
      <Card.Body>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <Line data={chartData} options={options} />
        )}
      </Card.Body>
    </Card>
  );
};

export default MonthlyOverdueFilesChart;
