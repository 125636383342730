import axios from 'axios'
import axiosInstance from "./AxiosInstance"


const axiosAuthInstance = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_BASE_URL,
});

export function loginApi(data) {
    return axiosAuthInstance.post(`api/auth/login`, data);
}

export function logoutApi() {
    return axiosAuthInstance.get(`api/auth/logout`);
}

export function whoami() {
    return axiosInstance.get('api/auth/authorized');
}


export function signupApi(data) {
    return axiosAuthInstance.post(`api/auth/register`, data);
}

export function resendOTP(data) {
    return axiosAuthInstance.post(`api/auth/resend_otp`, data);
}

export function verifySignupOtpApi(data) {
    return axiosAuthInstance.put(`api/auth/register`, data);
}

export function updateUserProfilePassword(data) {
    return axiosInstance.put("api/auth/profile",data);
  }
  
  export function updateUserProfile(data) {
    return axiosInstance.patch("api/auth/profile",data);
  }
  
  export function updateUserProfilePhoto(data) {
    return axiosInstance.post("api/auth/profile",data);
  }

  export function forgotPassword(email) {
    return axiosInstance.post('/api/auth/forgot_password', {email});
  }
  export function forgotPasswordReset(data) {
    return axiosInstance.post('/api/auth/forgot_password_confirm', data);
  }
  
  export function enableDisableWPEmailNotification(data) {
    return axiosInstance.post('/api/auth/update_config', data);
  }