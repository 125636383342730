import React from "react";
import { Form } from "react-bootstrap";

const FilterDropdown = ({ filter, onFilterChange }) => {
  return (
    <Form.Select
      className="width px-1 py-1"
      value={filter}
      onChange={(event) => onFilterChange(event.target.value)}
    >
      <option value="">All</option>
      <option value="weekly">7Days</option>
      <option value="monthly">30Days</option>
      <option value="yearly">Year</option>
    </Form.Select>
  );
};

export default FilterDropdown;
