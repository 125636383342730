import React, { useState, useCallback, useEffect } from "react";
import { Card, Spinner } from "react-bootstrap";
import { Icon, Pureknob } from "../../../components";
import { Colors } from "../../../utilities/index";
import { getReceiveDispatchCount } from "../../../services/DashboardService";
import { handleReceiveCountAction } from "../../../store/actions/FileActions";
import { connect, useDispatch } from "react-redux";

const ReceivePureknob = ({ receiveCount}) => {
  return (<Pureknob
    data={{
      size: 136,
      value: receiveCount,
      angleOffset: -0.5,
      angleStart: 0.7,
      angleEnd: 0.7,
      colorFg: Colors.white,
      trackWidth: "0.15",
    }}
    className="nk-chart-project-done text-white"
  >
    {" "}
    <span className="knob-title small text-white">To Receive</span>
  </Pureknob>)
}

const ReceiveFileCount = ({ receiveCount }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getReceiveDispatchCount()
      .then((resp) => {
        const receiveCount = Number(resp?.data?.to_receive) || 0;
        dispatch(handleReceiveCountAction({ receiveCount }));
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Card className="h-100 bg-info">
      <Card.Body>
        <div className="d-flex flex-column flex-sm-row-reverse align-items-sm-center justify-content-sm-between">
          {isLoading ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <>
              <ReceivePureknob receiveCount={receiveCount} />
              <div className="card-title mb-0 mt-4 mt-sm-0">
                <h5 className="title mb-3 mb-xl-5 text-white">Receive Files</h5>
                <div className="amount h2 text-white">
                  <Icon name="file-fill" /> {receiveCount || 0} files to Receive
                </div>
              </div>
            </>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  receiveCount: state.file.receiveCount || 0,
});
export default connect(mapStateToProps)(ReceiveFileCount);
