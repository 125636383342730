import React, { useState, useEffect, useCallback } from "react";
import { CancelRequest } from "../../services/AxiosInstance";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Badge, Card, Modal, Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import Block from "../../components/Block/Block";
import { Icon, MediaGroup, MediaText } from "../../components";
import { getFileOverviewList } from "../../services/FileService";
import DataTable from "react-data-table-component";
import DataTablePagination from "../../components/Pagination/DataTablePagination";
import { deptOptions , statusOptions } from "../../utilities";
import DatePicker from "../../components/Form/DateRangePicker";
import DebounceSearchInput from "../Constant/DebounceSearchInput";


  const headers = [
    {
      key: "status",
      label: "Status",
      options: statusOptions,
    },
    {
      key: "department",
      label: "Department",
      options: deptOptions,
    },
  ];

function FileOverview() {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showItemPerPage, setShowItemPerPage] = useState(rowsPerPage);
  const [mobileView, setMobileView] = useState(false);
  const recordsPage = 10;
  const navigate = useNavigate();
  const [filter, setFilter] = useState({
    query: "",
    status: "",
    priorityLevel: "",
    fileSources: "",
    department: "",
    startDate: "",
    endDate: "",
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [tempfilter, setTempfilter] = useState({ ...filter });

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getFileOverviewList(currentPage, recordsPage, filter)
      .then((resp) => {
        setData(resp.data.data);
        setTotal(resp.data.total);
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentPage, filter]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setShowItemPerPage(newRowsPerPage);
  };

  const handleDateChange = ({ startDate, endDate }) => {
    console.log('Start Date:', startDate);
    console.log('End Date:', endDate);
    setFilter((prev) => ({
      ...prev,
      startDate: startDate,
      endDate: endDate,
    }));
  };

  const handleSearchChange = (value) => {
    if (value.length > 0) CancelRequest();
    setFilter((prev) => ({
      ...prev,
      query: value,
    }));
    setCurrentPage(1);
  };

  const handleToggleModal = () => {
    if (modalVisible) {
      setTempfilter({ ...filter });
    }
    setModalVisible((prev) => !prev);
  };

  const handleSelectChange = (filterKey, value) => {
    setTempfilter((prev) => ({
      ...prev,
      [filterKey]: value,
    }));
  };

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const currentPageData = data.slice(startIndex, endIndex);

  const handleApplyfilter = () => {
    setFilter(tempfilter);
    setModalVisible(false);
  };

  const handleCancelfilter = () => {
    const defaultfilter = {
      status: "",
      priorityLevel: "",
      fileSources: "",
      department: "",
    };
    setTempfilter(defaultfilter);
    setFilter(defaultfilter);
    setModalVisible(false);
  };

  const removeBadge = (filterKey) => {
    setTempfilter((prev) => ({
      ...prev,
      [filterKey]: "",
    }));
  };

  const columns = [
    {
      name: "File Details",
      selector: (row) => {

        return (
          <MediaGroup>
            <MediaText >
              <Link to={`/file/${row?.id}`} className="title">
                <strong>{row?.subject}</strong>{" "}
              </Link>
              <span
                className="text-primary fw-bold"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/file/${row?.id}`)}
              >
                {row?.file_number}
              </span>
            </MediaText>
          </MediaGroup>
        );
      },
    },
    {
      name: "Source Department",
      selector: (row) => {
        return (
          <span className=" fw-bold">
            {row?.owner_department ? (<span> {deptOptions.find((option) => option.value === row?.owner_department)?.label || "NA"}</span>) : (
              <span > General</span>
            )}
          </span>
        );
      },
    },
    {
      name: "Current Owner",
      selector: (row) => {
        return (
         <span>{row?.current_owner}</span>
        );
      },
    },


    {
      name: "Receive Date",
      selector: (row) => row?.received_date
    },
    {
      name: "Pending Duration",
      selector: (row) => row?.pending_duration_with_officer
    }, {
      name: "Action",
      selector: (row) => {
        return row?.status === "clarification" ? (
          <span className={`badge bg-info`}>Clarification</span>
        ): row?.status === "escalation" ? (
          <span className={`badge bg-danger-soft`}>Escalation</span>
        ) : row?.status === "approval" ? (
          <span className={`badge text-bg-success`}>Approval</span>
        ) : row?.status === "completed" ? (
          <span className={`badge text-bg-success-soft`}>Completed</span>
        ) : (
          <span className={`badge bg-primary`}>Move</span>
        );
      },
    },{
      name: "Status",
      selector: (row) => {
        return  row?.status === "in_progress" ? (
          <span className={`badge bg-warning`}>In Progress</span>
        ): row?.status === "overdue" ? (
          <span className={`badge bg-danger`}>Overdue</span>
        ): row?.status === "escalated" ? (
          <span className={`badge bg-secondary`}>Escalated</span>
        ) : row?.status === "approved" ? (
          <span className={`badge text-bg-success`}>Approved</span>
        ) : row?.status === "completed" ? (
          <span className={`badge text-bg-success-soft`}>Completed</span>
        ) : (
          <span className={`badge bg-primary`}>Reopened</span>
        );
      },
    },
  ];

  const viewChange = () => {
    if (window.innerWidth < 960) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  useEffect(() => {
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
    };
  }, []);

  return (
    <div>
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">File Overview</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  File Overview
                </li>
              </ol>
            </nav>
          </Block.HeadContent>

        </Block.HeadBetween>
      </Block.Head>
      <Block>
        <Card>
          <div className="data-table-wrapper">
            <div className="data-table-top">
              <div style={{ position: "relative", display: "flex", alignItems: "center" }}>

                {/* Search */}
                <DebounceSearchInput
                  handleSearchChange={handleSearchChange}
                />

                {/* Filter */}
                <div className="mx-4">
                <div className="d-flex align-items-center">
                  <button className="btn btn-outline-light d-flex align-items-center mx-1 px-1 shadow-sm" onClick={handleToggleModal}>
                    <Icon name="filter" className="fs-3 text-light"></Icon>
                    <span className="fw-bold text-light">Filters</span>
                  </button>
                  {Object.keys(tempfilter).some((key) => tempfilter[key]) && (
                      <button
                        className="btn btn-outline-danger ms-2 p-1"
                        onClick={handleCancelfilter}
                      >
                        Clear
                      </button>
                    )}
                    </div>

                  <Modal
                    show={modalVisible}
                    onHide={handleToggleModal}
                    centered
                    className="custom-modal"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Filter Options</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="py-4">
                      <div className="filter-sections">
                        {headers.map(({ key, label, options }) => (
                          <div key={key} className="mb-1">
                            <h5 className="text-uppercase text-dark mb-1">{label}</h5>
                            <div className="row">
                              {options.map((option) => (
                                <div key={option.value} className="col-6 col-md-5 mb-1">
                                  <div
                                    className="form-check form-check-sm"
                                    style={{ fontSize: "0.8rem" }}
                                  >
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name={key}
                                      id={`${key}-${option.value}`}
                                      value={option.value}
                                      checked={tempfilter[key] === option.value}
                                      onChange={() => handleSelectChange(key, option.value)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`${key}-${option.value}`}
                                      style={{ fontSize: "0.8rem" }}
                                    >
                                      {option.label}
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>

                      {/* Display Applied Filters */}
                      {Object.keys(tempfilter).some((key) => tempfilter[key]) && (
                        <div className="applied-filter mt-1">
                          <span className="fw-bold">
                            <hr />
                            Applied filter:
                            <div className="d-flex flex-wrap mt-2">
                              {headers.map(({ key, options }) =>
                                tempfilter[key] ? (
                                  <span
                                    key={key}
                                    className="badge badge-pill badge-info mr-2 mb-2 d-inline-flex align-items-center"
                                    style={{ marginRight: "8px", marginBottom: "8px" }}
                                  >
                                    <span className="text-dark">
                                      {
                                        options.find(
                                          (option) => option.value === tempfilter[key]
                                        )?.label
                                      }
                                    </span>
                                  
                                    <button
                                      type="button"
                                      className="close text-danger bg-transparent border-0 rounded-circle"
                                      aria-label="Close"
                                      onClick={() => removeBadge(key)}
                                      style={{
                                        fontSize: "16px"
                                      }}
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </span>
                                ) : null
                              )}
                            </div>
                          </span>
                        </div>
                      )}
                    </Modal.Body>

                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={handleCancelfilter}
                      >
                        {Object.values(tempfilter).some((value) => value) ? "Clear All" : "Cancel"}
                      </Button>

                      <Button variant="primary" onClick={handleApplyfilter}>
                        Apply filter
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>

                {/* //handleDateRange] */}
                <div className="container mt-1">
                  <DatePicker
                    onChange={handleDateChange}
                    autoHide={true}
                    todayBtn={true}
                    clearBtn={true}
                    format="yyyy/mm/dd"
                  />
                </div>
              </div>


              {/* <div className="data-table-action-wrap">
                <div className="data-table-select">
                  <select
                    className="form-select"
                    onChange={(e) => setShowItemPerPage(e.target.value)}
                    value={showItemPerPage}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </select>
                  <span className="text">Per page</span>
                </div>
              </div> */}
            </div>
            <DataTable
              columns={columns}
              data={currentPageData}
              noDataComponent={
                <div className="p-2">There are no records found.</div>
              }
              sortIcon={<div className="data-table-sorter"></div>}
              pagination
              progressPending={isLoading}
              progressComponent={
                <div className="text-center my-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              }
              paginationComponent={(props) => (
                <div className="data-table-bottom">
                  <DataTablePagination
                    showItemPerPage={showItemPerPage}
                    itemPerPage={rowsPerPage}
                    totalItems={data.length}
                    paginate={handlePageChange}
                    currentPage={currentPage}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    setShowItemPerPage={setShowItemPerPage}
                  />
                </div>
              )}
            />
          </div>
        </Card>
      </Block>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userRole: state.auth.user?.role || "user",
});

export default connect(mapStateToProps)(FileOverview);
