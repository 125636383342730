import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { searchMoveUserApi, escalationFileApi } from "../../../services/FileService";
import { Badge, Modal, Form, Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { StaticSelectInput } from "../../Constant/SelectInput";
import { Icon } from "../../../components";

function EscalateFileModal({ data, handleAfterUpdate }) {
  const [formData, setFormData] = useState({
    approver_id: "",
    escalation_reason: "",
    comment: "",
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [approveUserList, setApproveUserList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (!isModalVisible) return;

      try {
        const approveResponse = await searchMoveUserApi();
        setApproveUserList(approveResponse.data?.data || []);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to fetch approver list.");
      }
    };

    fetchData();
  }, [isModalVisible]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleRadioChange = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.approver_id) {
      newErrors.approver_id = "Please select a next officer.";
    }
    if (!formData.escalation_reason.trim()) {
      newErrors.escalation_reason = "Escalation reason is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    const payload = {
      ...formData,
      id: data?.id || "",
    };

    try {
      setIsSubmitLoading(true);
      const response = await escalationFileApi(payload);
      const {message, results} = response.data
      toast.success(message);
      handleAfterUpdate(results)
      setIsModalVisible(false);
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to escalate file.");
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const resetForm = () => {
    setFormData({
      approver_id: "",
      escalation_reason: "",
      comment: "",
    });
    setErrors({});
  };

  return (
    <>
      <Badge
        bg="info"
        onClick={() => setIsModalVisible(true)}
        style={{ cursor: "pointer" }}
      >
        Escalate File <Icon name="arrow-up-right" />
      </Badge>

      <Modal
        show={isModalVisible}
        onHide={() => {
          setIsModalVisible(false);
          resetForm();
        }}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Escalate File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="g-3 mb-4">
              <Col lg="12">
                <StaticSelectInput
                  setParentInputValue={handleRadioChange}
                  optionsList={approveUserList?.map((item) => ({
                    value: item.value,
                    label: item.label,
                  }))}
                  name="approver_id"
                  id="approver_id"
                  isRequired
                  placeholder="Select Next Officer"
                  label="Next Officer"
                  error={errors.approver_id}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.approver_id}
                  </Form.Control.Feedback>
              </Col>

              <Col lg="12">
                <Form.Group>
                  <Form.Label>Escalation Reason</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="escalation_reason"
                    placeholder="Reason for escalation"
                    value={formData.escalation_reason}
                    onChange={handleChange}
                    isInvalid={!!errors.escalation_reason}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.escalation_reason}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col lg="12">
                <Form.Group>
                  <Form.Label>Comments (Optional)</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="comment"
                    placeholder="Leave your comments"
                    value={formData.comment}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="d-flex gap-3">
              <Button
                type="submit"
                variant="success"
                disabled={isSubmitLoading}
              >
                {isSubmitLoading ? "Escalating..." : "Escalate"}
              </Button>
              <Button
                type="button"
                variant="secondary"
                onClick={() => {
                  setIsModalVisible(false);
                  resetForm();
                }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  userDepartment: state.auth.user?.department,
});
export default connect(mapStateToProps)(EscalateFileModal);
