import axiosInstance from './AxiosInstance';
const userUrl = "api/user"
const searchDivUrl = "api/search_division"
const searchUserUrl = "api/search_user"

// user
export function getUserListApi(page, page_size, filters = {},department={}, role={}) {
    const params = new URLSearchParams({
        page,
        page_size,
        ...filters,  
        ...department,
        ...role,
    });
    return axiosInstance.get(`${userUrl}?${params.toString()}`);
}



export function getUserApi(id) {
    return axiosInstance.get(`${userUrl}/${id}`);
}

export function createUserApi(data) {
    return axiosInstance.post(userUrl, data);
}

export function updateUserApi(id,data) {
    return axiosInstance.put(`${userUrl}/${id}`, data);
}
export function blockUserApi(id,data) {
    return axiosInstance.patch(`${userUrl}/${id}`, data);
}



  export function getDivisionListApi() {
    return axiosInstance.get(`${searchDivUrl}`);
}

  export function searchUserApi() {
    return axiosInstance.get(`${searchUserUrl}`);
}
























