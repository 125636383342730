import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Button, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loginApi } from "../../services/AuthService";
import { loginConfirmedAction } from "../../store/actions/AuthActions";
import { Link } from "react-router-dom";

import Layout from "../../layout/fullpage";
import image from "../../assets/images/bg-image/bg-image.jpg"
import { Media, MediaGroup, Image, OverlineTitle, Logo } from "../../components";

const AuthLoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [otpVisible, setOtpVisible] = useState(false);
  const [otpUserData, setOtpUserData] = useState(null);
  const [verificationField, setVerificationField] = useState([]);
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  // Prevent page reload during loading or OTP process
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (loading || otpVisible) {
        event.preventDefault();
        event.returnValue = ""; // Chrome requires returnValue to be set
      }
    };

    if (loading || otpVisible) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    } else {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [loading, otpVisible]);

  const toggleOtpVisible = () => {
    setOtpVisible(!otpVisible);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") setEmail(value);
    if (name === "password") setPassword(value);
    setErrors({ ...errors, [name]: "" });
  };

  const onLogin = async (e) => {
    e.preventDefault();
    if (!loading) {
      let error = false;
      const errorObj = { ...errors };

      if (!email) {
        errorObj.email = "Email is required";
        error = true;
      }
      if (!password) {
        errorObj.password = "Password is required";
        error = true;
      }

      setErrors(errorObj);

      if (error) return;

      try {
        setLoading(true);
        const formData = { email, password };
        const resp = await loginApi(formData);
        const { data, token } = resp.data;

        dispatch(loginConfirmedAction(data));
        navigate("/dashboard");

        if (process.env.REACT_APP_HEADER_TOKEN === "LOCALSTORAGE") {
          localStorage.setItem("TOKEN", token);
        }
      } catch (error) {

        if (error?.response?.data) {
          toast.error(error.response.data.message);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Layout title="Login" className="w-100" style={{
      backgroundImage: `url(${image})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }} centered>
      <div className="p-2 p-sm-4">
        <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
          <Row className="g-0 flex-lg-row-reverse">
            {/* Login Form Section */}
            <Col lg="5">
              <Card.Body className="h-100 d-flex flex-column justify-content-center">
                <div className="d-flex align-item-center justify-content-center flex-grow mb-3">
                  <img src="/images/ITFullLogoWhite.png" alt="" width={'200px'} />
                </div>
                <div className="nk-block-head text-center">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title mb-1">Login to Account</h3>
                    <p className="small">
                      Please sign-in to your account and start the adventure.
                    </p>
                  </div>
                </div>
                <Form onSubmit={onLogin}>
                  <Row className="gy-3">
                    <Col className="col-12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="email">Email</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="text"
                            id="email"
                            name="email"
                            value={email}
                            onChange={handleInputChange}
                            placeholder="Enter email"
                            isInvalid={!!errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col className="col-12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="password">Password</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="password"
                            id="password"
                            name="password"
                            value={password}
                            onChange={handleInputChange}
                            placeholder="Enter password"
                            isInvalid={!!errors.password}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.password}
                          </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col className="col-12">
                      <div className="d-flex flex-wrap justify-content-between">
                        <Form.Check
                          className="form-check-sm"
                          type="checkbox"
                          id="rememberMe"
                          label="Remember Me"
                        />
                        <Link to="/reset" className="small">
                          Forgot Password?
                        </Link>
                      </div>
                    </Col>
                    <Col className="col-12">
                      <div className="d-grid">
                        <Button type="submit" disabled={loading}>
                          {loading ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            "Login to account"
                          )}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Col>
            {/* Welcome Section */}
            <Col lg="7">
              <Card.Body className="bg-darker is-theme has-mask has-mask-1 h-100 d-flex flex-column justify-content-end">
                <div className="mask mask-1"></div>
                {/* <div className="brand-logo">
                  <Logo />
                </div> */}
                <div className="row">
                  <div className="col-sm-11">
                    <div className="mt-4">
                      <div className="h1 title mb-3">
                        Welcome to <br /> InteliTrack
                      </div>
                      <p>
                        Streamline your workflow with <strong>InteliTrack</strong>, the ultimate solution for monitoring file movement across departments.
                        Experience smarter file management and seamless operations to enhance productivity and efficiency.
                      </p>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Col>
          </Row>
        </Card>
      </div>
    </Layout>
  );
};

export default AuthLoginPage;
