import React, { useState } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import { Media, Image } from '../../components';
import { toInitials } from '../../utilities';

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getProfilePhotoApi } from '../../services/ProfileService';
import "./profile.css";

function ProfilePhoto({ user }) {
    const [showModal, setShowModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setSelectedFile(e.dataTransfer.files[0]);
    };

    const handleUpload = async () => {
        if (!selectedFile) return;

        setIsSubmitLoading(true);

        try {
            const formData = new FormData();
            formData.append("profile_photo", selectedFile);
            const response = await getProfilePhotoApi(formData);

            toast.success(response?.data?.message);
            setShowModal(false);
        } catch (error) {
            toast.error(error.response?.data?.message || "An error occurred");
        } finally {
            setIsSubmitLoading(false);
        }
    };

    return (
        <>
            <div
                className="profile-photo-wrapper position-relative"
                onClick={() => setShowModal(true)}
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleDrop}
            >

                <Media size="huge" shape="circle" variant="dark-soft">
                    {selectedFile ? (
                        <Image
                            src={URL.createObjectURL(selectedFile)}
                            staticImage
                            alt="user"
                            onError={() => console.error("Error loading selected file image")}


                        />
                    ) : user?.profile_photo ? (
                        <Image
                            src={`${process.env.REACT_APP_BASE_URL}${user?.profile_photo}`}
                            staticImage
                            alt="user"
                        />
                    ) : (
                        <span className="fw-medium">{toInitials(user?.name)}</span>
                    )}
                </Media>
                <div className="hover-text">Change Photo</div>
            </div>


            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Change Profile Photo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {!selectedFile ? (
                            <div
                                className="dropzone p-4 text-center border rounded"
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={handleDrop}
                            >
                                <p>Drag and drop files here, or click to select file. JPEG, JPG & PNG files are allowed.</p>
                                <Form.Control
                                    type="file"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                    className="d-none"
                                    id="file-input"
                                />
                                <label htmlFor="file-input" className="btn btn-primary btn-md">
                                    Choose a File
                                </label>
                            </div>
                        ) : (
                            <div
                                className="d-flex flex-column align-items-center justify-content-center position-relative"
                                style={{ height: "100%", textAlign: "center" }}
                            >
                                <div className="mt-3 mx-5 text-center position-relative" style={{ display: 'inline-block' }}>
                                    <Media size="huge" shape="circle" variant="dark-soft">
                                        <Image
                                            src={URL.createObjectURL(selectedFile)}
                                            staticImage
                                            alt="user"
                                            onError={() => console.error("Error loading selected file image")}
                                            style={{ maxWidth: "150px", borderRadius: "50%" }}
                                        />
                                    </Media>
                                    <span
                                        className="position-absolute "
                                        style={{ top: "-10px", right: "-15px", cursor: "pointer", fontSize: "20px", color: "red" }}
                                        onClick={() => setSelectedFile(null)}
                                    >
                                        &times;
                                    </span>
                                </div>
                                <div className="d-flex align-items-center">
                                    <p className="mt-2 me-3"><strong>Selected File: {selectedFile?.name || 'No file selected'}</strong></p>
                                    <Form.Control
                                        type="file"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                        className="d-none"
                                        id="file-input"
                                    />
                                    <label htmlFor="file-input" className="btn btn-primary btn-md">
                                        Choose a File
                                    </label>
                                </div>



                            </div>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleUpload}
                        disabled={isSubmitLoading || !selectedFile}
                    >
                        {isSubmitLoading ? (
                            <>
                                <Spinner animation="border" size="sm" /> Uploading...
                            </>
                        ) : (
                            "Save Changes"
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ProfilePhoto;
