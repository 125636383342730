import React from "react";
import { Card } from "react-bootstrap";
import Block from "../../components/Block/Block";
import { Link } from "react-router-dom";
import FileToCollect from "./FileToCollect";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { Icon } from "../../components";
import FileToOut from "./FileToOut";

const FileAction = () => {

  return (
    <div>
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">File Action</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  File Action
                </li>
              </ol>
            </nav>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>
      <Block>
        <div className="row">
          <div className="col-md-8">
            <Card>
                <CardHeader><h3><Icon name="file-fill"></Icon> File To Collect</h3></CardHeader>
            <FileToCollect />
            </Card>
          </div>
          <div className="col-md-4">
            <Card>
            <CardHeader><h3><Icon name="file-fill"></Icon> File To Out</h3></CardHeader>
                <FileToOut />
            </Card>
          </div>
        </div>
      </Block>
    </div>
  );
};

export default FileAction;
